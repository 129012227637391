import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as BuildingIco } from '../../../../assets/building.svg';
import { ButtonAccent, ButtonPrimary } from '../../../../components/button';
import DataTable, { DataTableRow } from '../../../../components/data-table/data-table';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import { FormCard, FormCardBody } from '../../../../components/form-card';
import Page, { ListingCardPlaceholder } from '../../../../components/page';
import Paging from '../../../../components/paging';
import SearchInput from '../../../../components/search-input';
import { useApi } from '../../../../hooks/useApi';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { ClientListEntryDto } from '../../../../services/api-client/csp-api';
import { pagingValidator } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import AdminTabPanel from '../../shared/admin-tab-panel';
import ClientCreatePopup from '../client-create-popup/client-create-popup';
import styles from './client-list-page.module.scss';

export default function ClientListPage() {
  const { _t } = useBetterTranslate('client-list-page');
  const navigate = useNavigate();

  type FilterParameters = {
    affiliateCode?: string;
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
  };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator });
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const [clientToDelete, setClientToDelete] = useState<ClientListEntryDto | undefined>();
  const [clientToAssignResources, setClientToAssignResources] = useState<ClientListEntryDto | undefined>();
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [clientsRes, clientsFetching, clientsErr, refreshResult] = useApi(
    {
      call: async (affiliateCode?: string, skip?: number | null, limit?: number | null, query?: string | null) => {
        if (!affiliateCode) return undefined;
        const res = await api.client.list({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          search: query || undefined,
          affiliateCode,
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;

        return data;
      },
    },
    filterParams.affiliateCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.search
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectedClientCode, setSelectedClientCode] = useState<string>();
  const onAddClick = () => {
    navigate(`/administration/clients/${filterParams.affiliateCode}/manage`);
    // setSelectedClientCode(undefined);
    // setShowAddPopup(true);
  };
  const onEditClick = (clientCode: string) => {
    setSelectedClientCode(clientCode);
    setShowAddPopup(true);
  };

  const goToResourceImport = (clientCode: string) => {
    navigate(`/administration/clients/${filterParams.affiliateCode}/import-resources/${clientCode}`);
  };

  const getPermissionsOfRole = async (roleId: string) => {
    const res = await api.client.getPermissionsOfRole(filterParams.affiliateCode || '', roleId);
    return res.data.permissions;
  };

  return (
    <Page
      breadCrumb={[{ title: _t('Administration'), href: '/administration/clients', active: true }]}
      fetching={clientsFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={clientsErr}
      outOfPlaceHolder={
        <>
          {clientsRes && (
            <AdminTabPanel
              selectedTab='clients'
              affiliate={clientsRes.affiliateContext}
              can={{ viewClientList: true, viewRoleList: clientsRes.can.viewRoleList, viewUserList: clientsRes.can.viewUserList }}
            />
          )}

          <FormCard tabletSize='full'>
            <FormCardBody className={styles.filterCard}>
              <SearchInput maxLength={120} onChange={setSearchTerm} value={searchTerm} placeholder={_t('Kunden suchen')} dataCy='srcClient' />

              {clientsRes?.can?.writeClient && (
                <ButtonPrimary ralign={true} onClick={onAddClick} dataCy={'btnCreateClient'}>
                  {_t('Kunde erstellen')}
                </ButtonPrimary>
              )}
            </FormCardBody>
          </FormCard>
        </>
      }
    >
      <FormCard tabletSize='full' className=''>
        <FormCardBody>
          <div className={styles.tableArea}>
            <DataTable
              sticky={true}
              records={clientsRes?.entries || []}
              dataCy={'tblClients'}
              // hideHeader={true}
              renderer={{
                row: (record, cols) => (
                  <DataTableRow classNames={styles.row} key={record.code}>
                    {cols}
                  </DataTableRow>
                ),
                cols: [
                  {
                    headerCol: () => <div>{_t('Name')}</div>,
                    name: 'title',
                    width: '60%',
                    col: (record) => {
                      return (
                        <div className={styles.fakeRow}>
                          <div className={classNames(styles.clientLbls)}>
                            <b>{record.title}</b>
                            <i>
                              {record.code} {record.cpmsId ? `/ ${record.cpmsId}` : ''}
                            </i>
                          </div>
                        </div>
                      );
                    },
                    dataCy: 'tblHeaderName',
                  },
                  {
                    name: 'actions',
                    ralign: true,
                    col: (record) => {
                      return (
                        <div className={classNames(styles.actions)}>
                          {clientsRes?.can?.deleteClient && (
                            <ButtonAccent className={styles.btn} onClick={() => setClientToDelete(record)} dataCy={'btnDeleteClient'}>
                              {_t('Löschen')}
                            </ButtonAccent>
                          )}
                          {clientsRes?.can?.writeClient && (
                            <>
                              <ButtonAccent
                                className={styles.btn}
                                onClick={() => {
                                  setClientToAssignResources(record);
                                }}
                              >
                                {_t('Ressourcen zuordnen')}
                              </ButtonAccent>
                              <ButtonPrimary className={styles.btn} onClick={() => onEditClick(record.code || '')} dataCy={'btnEditClient'}>
                                {_t('Editieren')}
                              </ButtonPrimary>
                            </>
                          )}
                          <button
                            type='button'
                            className={classNames(styles.btn, styles.icoBtn, !clientsRes?.can?.viewClientOrgStructure && styles.disabled)}
                            onClick={() => {
                              if (clientsRes?.can?.viewClientOrgStructure) {
                                navigate(`/organisation/structure/${record.code}`);
                              }
                            }}
                            title={_t('Kundenseite')}
                            data-cy={'btnClientList'}
                          >
                            <BuildingIco />
                          </button>
                        </div>
                      );
                    },
                    width: '40%',
                  },
                ],
              }}
            />
          </div>
        </FormCardBody>
      </FormCard>

      {clientsRes && (
        <Paging
          skip={clientsRes.skip}
          limit={clientsRes.limit}
          total={clientsRes.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )}

      <ClientCreatePopup
        open={showAddPopup}
        affiliateCode={filterParams.affiliateCode || ''}
        clientCode={selectedClientCode}
        canWriteIndividual={!!clientsRes?.can.writeIndividualRole}
        close={() => setShowAddPopup(false)}
        onSubmit={() => refreshResult()}
        dataCy={{ root: selectedClientCode ? 'popEditClient' : 'popCreateClient', buttonSave: selectedClientCode ? 'btnSave' : 'btnCreate' }}
        getPermissionsOfRole={getPermissionsOfRole}
      />

      <EditPoup
        open={!!clientToDelete}
        onClose={() => setClientToDelete(undefined)}
        title={_t('Kunden Löschen')}
        saveText={_t('Löschen')}
        onSave={async () => {
          if (!clientToDelete || !filterParams.affiliateCode) return;
          await api.client.delete(filterParams.affiliateCode, clientToDelete.code);
          setClientToDelete(undefined);
          refreshResult();
        }}
        dataCy={{ root: 'popDeleteClient', title: 'popTitle', buttonClose: 'btnClose', buttonCancel: 'btnCancel', buttonSave: 'btnDelete' }}
      >
        {clientToDelete && (
          <>
            <EditPopupRow className={classNames(styles.deletePopupRow)} dataCy={{ content: 'txaDeleteClient' }}>
              {_t('Sind Sie sicher, dass Sie Kunden {{title}} löschen möchten?', { title: clientToDelete.title })}
            </EditPopupRow>
            <EditPopupRow className={classNames(styles.deletePopupRow, styles.alert)} dataCy={{ content: 'wamDelete' }}>
              ⚠️ {_t('Bei der Löschung des Kunden werden alle Daten des Kunden gelöscht.')}
            </EditPopupRow>
            {!clientsRes?.can.deleteUser && (
              <EditPopupRow className={classNames(styles.deletePopupRow, styles.warn)}>
                ⚠️ {_t('Sie besitzen nicht das Recht Nutzer zu löschen.')} <br />
                {_t('Nutzer die dem Kunden zugeordnet sind bleiben erhalten.')}
              </EditPopupRow>
            )}
          </>
        )}
      </EditPoup>

      <EditPoup
        open={!!clientToAssignResources}
        onClose={() => setClientToAssignResources(undefined)}
        title={_t('Resource Assignment')}
        cancelText={_t('Cancel')}
        saveText={_t('Confirm')}
        onSave={async () => {
          if (!clientToAssignResources) {
            return;
          }
          goToResourceImport(clientToAssignResources?.code);
          setClientToAssignResources(undefined);
        }}
      >
        <>
          <EditPopupRow className={classNames(styles.deletePopupRow)}>{_t('Please make sure that the clients hierarchy is correct before assigning the resources.')}</EditPopupRow>
        </>
      </EditPoup>
    </Page>
  );
}
