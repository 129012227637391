import classNames from 'classnames';
import React, { Fragment, PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as HeartIco } from '../assets/heart.svg';
import { ReactComponent as NotFoundIco } from '../assets/not-found.svg';
import { ReactComponent as UserLockIco } from '../assets/user-lock.svg';
import { ApiError } from '../services/api-client/csp-api';
import useBetterTranslate from '../utils/translation-utils';
import { BreadCrumbEntry } from './breadcrumb';
import CardGrid, { Card, CardBody, CardHeader } from './card-grid';
import styles from './page.module.scss';

export function ListingCardPlaceholder(props: { fullWidth?: boolean; cardSize?: number; cardNumber?: number }) {
  const cardNumber = props.cardNumber || 9;
  const cardArr = Array.from(Array(cardNumber).keys());
  return (
    <CardGrid className={styles.spinnerGrid}>
      {cardArr.map((item, i) => {
        let cardSize = props.cardSize || 1;
        return (
          <Card key={i} className={classNames(styles.card, props.fullWidth ? styles.fullWidthCard : '')}>
            {Array.from(Array(cardSize).keys()).map((s) => {
              return (
                <Fragment key={s}>
                  <CardHeader className={styles.skeletonShimmer}> </CardHeader>
                  <CardBody>
                    <div key={'1'} className={styles.skeletonShimmer}>
                      {' '}
                    </div>
                    <div key={'2'} className={styles.skeletonShimmer}>
                      {' '}
                    </div>
                    <div key={'3'} className={styles.skeletonShimmer}>
                      {' '}
                    </div>
                  </CardBody>
                </Fragment>
              );
            })}
          </Card>
        );
      })}
    </CardGrid>
  );
}

export function SimplePlaceholder(props: { text?: string }) {
  return (
    <div className={styles.simplePlaceholder}>
      <div className={classNames(styles.rippleSpinnerWrapper)}>
        <div className={classNames(styles.rippleSpinner)}>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className={styles.loaderTxt}>{props.text || 'Loading'}</div>
    </div>
  );
}

function ErrorLayout(props: { code: string; text: string; ico?: React.ReactNode }) {
  return (
    <div className={styles.errorPage}>
      {props.ico && <div className={styles.ico}>{props.ico}</div>}
      <div className={styles.code}>{props.code}</div>
      <div className={styles.txt}>{props.text}</div>
    </div>
  );
}

export function Error(props: { err: ApiError }) {
  const { _t } = useBetterTranslate('page');
  if (props.err.statusCode === 403) {
    return <ErrorLayout text={_t('Sie haben kein Zugriff auf diese Seite')} code='CODE 403' ico={<UserLockIco />} />;
  } else if (props.err.statusCode === 404) {
    return <ErrorLayout text={_t('Diese Seite konnte nicht gefunden werden')} code='CODE 404' ico={<NotFoundIco />} />;
  } else {
    return <ErrorLayout text={_t('Es ist ein unerwarteter Fehler aufgetreten')} code={`CODE: ${props.err?.statusCode || 'UNBEKANNT'}`} ico={<HeartIco />} />;
  }
}

export default function Page(
  props: PropsWithChildren<{
    className?: string;
    breadCrumb?: BreadCrumbEntry[];
    // toolbar?: ReactNode,
    placeHolder?: React.ReactNode;
    fetching?: boolean;
    error?: ApiError | undefined;
    outOfPlaceHolder?: ReactNode;
  }>
) {
  const placeHolder = props.placeHolder || <div>loading ...</div>;
  return (
    <div data-cy={'page_root'} className={classNames(styles.root, props.className, props.fetching ? styles.fetching : '')}>
      {/* {!props.error && (props.breadCrumb || props.toolbar) &&
        <div className={classNames(styles.topbar)}>
          {props.breadCrumb && <BreadCrumb items={props.breadCrumb} />}
          {props.toolbar && props.toolbar}
        </div>
      } */}

      {!props.error && props.outOfPlaceHolder && props.outOfPlaceHolder}

      {props.fetching && placeHolder}
      {props.error && <Error err={props.error} />}
      {!props.fetching && !props.error && props.children}
    </div>
  );
}

export function NotFoundPage() {
  const { _t } = useBetterTranslate('page');
  return (
    <Page>
      <Error
        err={{
          error: _t('Diese Seite konnte nicht gefunden werden'),
          statusCode: 404,
          message: _t('Diese Seite konnte nicht gefunden werden'),
        }}
      />
    </Page>
  );
}
