import classNames from 'classnames';
import { ReactComponent as SpinnerIco } from '../../assets/spinner3.svg';
import { DataTableColumn } from '../../components/data-table/data-table';
import { RateTypeTag } from '../../components/tag';
import api from '../../services/api';
import { FmChargingSessionListItemDto, FmChargingSessionListResultDto, FmChargingSessionSumsResultDto, RateServiceType, SessionStatus } from '../../services/api-client/csp-api';
import { useAuth } from '../../utils/AuthProvider';
import { endOfDay, getTimezone, startOfDay } from '../../utils/date';
import { formatDuration, formatEnergy, formatPrice } from '../../utils/format';
import { formatter } from '../../utils/localized-types';
import useBetterTranslate from '../../utils/translation-utils';
import uniqueLineIdentifier from '../../utils/unique-line-ident';
import { ChargingSessionsListPage, ExportSessionsArgs, FilterParameters, StatusSessionColumn } from './charging-sessions-list-page';
import styles from './charging-sessions-list-page.module.scss';

const SERVICE_TYPE_ALLOWED_VALUES = [RateServiceType.WORK, RateServiceType.EMPLOYEE, RateServiceType.HOME, RateServiceType.UNDEFINED];

export default function FmChargingSessionsListPage() {
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode;

  const SORT_BY_VALUES = ['cardNumber', 'cardComment', 'totalCost'];
  const { _t } = useBetterTranslate('fm-charging-sessions-list-page');

  const sessionsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.fmChargingSessions.list(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const sumsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.fmChargingSessions.sums(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const exportApiCall = async (filterParams: FilterParameters, args: ExportSessionsArgs, cancelToken: string) => {
    const apiFilter = toSearchApiFilter(filterParams);
    if (!apiFilter) return undefined;

    return await api.fmChargingSessions.export(
      {
        filter: apiFilter,
        sessionFields: args.sessionFields,
        stationFields: [],
        cardFields: args.cardFields,
        fileType: args.fileType,
        aggregateByStationId: false,
        aggregateByStationLocation: false,
        aggregateByCardId: args.aggregateByCardId,
        aggregateByCardLocation: args.aggregateByCardLocation,
        serviceTypeLabels: args.serviceTypeLabels,
        aggregationLabels: args.aggregationLabels,
        timezone: args.timezone,
      },
      { cancelToken }
    );
  };

  const toSearchApiFilter = (filter: FilterParameters) => {
    if (!filter.clientCode) return undefined;
    let consumedEnergyFrom = filter.energyFrom;
    if (consumedEnergyFrom !== undefined) {
      consumedEnergyFrom = consumedEnergyFrom * 1000;
    }
    let consumedEnergyTo = filter.energyTo;
    if (consumedEnergyTo !== undefined) {
      consumedEnergyTo = consumedEnergyTo * 1000;
    }
    return {
      client: filter.clientCode,
      hierarchyNodeCodes: filter.nodes || [filter.clientCode],
      limit: Number(filter.limit || 20),
      skip: Number(filter.skip || 0),
      from: startOfDay(filter.from),
      to: endOfDay(filter.to),
      entityFilter: filter.entity || [],
      serviceTypes: filter.serviceType as RateServiceType[],
      sortBy: filter.sortBy as any,
      sortDesc: filter.sortDesc === '1',
      energy: { from: consumedEnergyFrom, to: consumedEnergyTo },
      totalCost: { from: filter.totalCostFrom, to: filter.totalCostTo },
      status: filter.status as SessionStatus[],
    };
  };

  const energyApiCall = async (clientCode?: string, from?: string, to?: string, nodes?: string[]) => {
    if (!clientCode) return undefined;
    const resp = await api.fmChargingSessions.energyConsumption(
      {
        client: clientCode,
        hierarchyNodeCodes: nodes || [clientCode],
        from: startOfDay(from),
        to: endOfDay(to),
        timezone: getTimezone(),
      },
      { cancelToken: uniqueLineIdentifier() }
    );
    return resp;
  };

  const searchEntityTags = async (clientCode: string | undefined, txt: string) => {
    if (!clientCode) return [];
    const entityFilterRes = await api.fmChargingSessions.searchFilters({ q: txt, clientCode }, { cancelToken: uniqueLineIdentifier() });
    return entityFilterRes.data;
  };

  const dataTableCols = (sumFetching: boolean, sessionsResp?: FmChargingSessionListResultDto, sumResp?: FmChargingSessionSumsResultDto) => {
    const cols: DataTableColumn<FmChargingSessionListItemDto>[] = [
      {
        headerCol: () => (
          <div>
            {_t('Kartennummer')}
            <br />
            <span className={styles.subLabel}>{_t('Kartenbezeichnung')}</span>
          </div>
        ),
        name: 'cardNumber',
        sortable: true,
        col: (record) => (
          <div>
            <div data-cy={`el_cardNumber`}>{record.cardNumber || '-'}</div>
            <div data-cy={`el_cardLabel`}>{record.cardLabel || '-'}</div>
          </div>
        ),
        width: '25%',
      },
      {
        headerCol: () => <div>{_t('Kostenstelle')}</div>,
        name: 'cardComment',
        sortable: true,
        col: (record) => <div>{record.cardComment}</div>,
        width: '20%',
      },
      {
        headerCol: () => <div>{_t('Start und Dauer')}</div>,
        name: 'startTime',
        sortable: true,
        col: (record) => {
          return (
            <div>
              {formatter.formatDateTimeFromIsoString(record.startTime, languageCode)}
              <br />
              {formatDuration(record.startTime, record.endTime)}
            </div>
          );
        },
        width: '15%',
      },
      {
        headerCol: () => <div>{_t('Genutzter Service')}</div>,
        name: 'rateServiceType',
        sortable: true,
        col: (record) => <RateTypeTag selected={true} rateType={record.rateServiceType} classNames={styles.serviceTypeCol} />,
        width: '10%',
      },
      {
        headerCol: () => <div>{_t('Geladene Energie (kWh)')}</div>,
        name: 'energy',
        sortable: true,
        col: (record) => <div>{formatEnergy(record.energy, languageCode, 2)}</div>,
        sumCol: () => (sumFetching ? <SpinnerIco /> : <div>{formatEnergy(sumResp?.totalEnergy, user?.preferences.languageCode, 2)}</div>),
        ralign: true,
        width: '10%',
      },
      {
        headerCol: () => <div>{_t('Ladekosten (€)')}</div>,
        name: 'totalCost',
        sortable: true,
        sumCol: () => (sumFetching ? <SpinnerIco /> : <div>{formatPrice(sumResp?.totalCost, user?.preferences.languageCode)}</div>),
        colClass: (record) => classNames(styles.onGoingCol, styles.header),
        col: (record) => (
          <div className={classNames(styles.onGoingCol, styles.content)}>
            <div className={classNames(styles.data)}>{formatPrice(record?.totalCost, user?.preferences.languageCode, 2)}</div>
            <StatusSessionColumn status={record.status} />
          </div>
        ),
        ralign: true,
        width: '20%',
      },
    ];
    return cols;
  };

  return ChargingSessionsListPage<FmChargingSessionListItemDto, FmChargingSessionListResultDto, FmChargingSessionSumsResultDto>({
    pagePrefix: 'fm',
    sortValues: SORT_BY_VALUES,
    totalCostFilter: true,
    purchaseCostFilter: false,
    isStationPublicFilter: false,
    serviceTypes: SERVICE_TYPE_ALLOWED_VALUES,
    sessionsApiCall,
    sumsApiCall,
    energyApiCall,
    searchEntityTags,
    exportFieldsConfig: { showStations: false, showCards: true },
    exportApiCall,
    dataTableCols,
    rowAttributes: (record) => {
      return {
        'data-cy': `charging_records_element`,
        'data-cy-id': record.cardNumber?.toString(),
      };
    },
  });
}
