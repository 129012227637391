import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as StationIco } from '../../assets/charging-station-blue.svg';
import { ReactComponent as PlugIco } from '../../assets/plug-blue.svg';
import { FormCard, FormCardBody } from '../../components/form-card';
import IconExplanationSection from '../../components/icon-explanation-section';
import ChargepointAppliedTags from '../../components/infrastructure/chargepoint-applied-tags';
import ChargepointFilterArea from '../../components/infrastructure/chargepoint-filter-area';
import ChargepointListItem from '../../components/infrastructure/chargepoint-list-item';
import { ChargePointFilterParameters } from '../../components/infrastructure/filter-area-parameters.dto';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import { getPlugTypeOptions } from '../../components/outlet-type-icon';
import Page, { ListingCardPlaceholder } from '../../components/page';
import Paging from '../../components/paging';
import StationStatusSummaryPanel from '../../components/station-status-summary-panel/station-status-summary-panel';
import TabPanel, { Tab, TabContent, Tabs } from '../../components/tab-panel';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { createQueryString, usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { ChargePointItemDto } from '../../services/api-client/csp-api';
import { formatter } from '../../utils/localized-types';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { hierarchyValidator, pagingValidator } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import { CreateServiceTicketAction } from '../support/create-ticket.page';
import ChargePointRemoteActions, { useChargePointRemoteActions } from './chargepoint-remote-actions';
import styles from './chargepoints-list-page.module.scss';
import StationCustomNamePopup from './custom-name-popup';

type FilterParameters = {
  skip?: number | null;
  limit?: number | null;
  clientCode?: string;
  nodes?: string[];
} & ChargePointFilterParameters;

export default function ChargepointsListPage() {
  const { _t } = useBetterTranslate('chargepoints-list-page');

  const navigate = useBetterNavigate();
  const { pathname } = useLocation();

  const validators = { ...pagingValidator, ...hierarchyValidator };
  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, validators);

  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };
  const setNewFilterParams = (filter: FilterParameters) => {
    // Set all filter params to undefined except the passed ones
    let property: keyof typeof filterParams;
    const undefinedFilterParams: FilterParameters = {};
    for (property in filterParams) {
      undefinedFilterParams[property] = undefined;
    }
    _setInnerFilterParams({
      ...undefinedFilterParams,
      ...filter,
    });
  };
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const updateSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };
  const [hasExternalHardware, setHasExternalHardware] = useState(false);
  const [hasPublicStations, setHasPublicStations] = useState(false);

  const [customNameChangeChargePoint, setCustomNameChangeChargePoint] = useState<ChargePointItemDto | undefined>(undefined);
  let customNamePopupTitle = _t('Edit station custom name');
  if (customNameChangeChargePoint && customNameChangeChargePoint?.customName === customNameChangeChargePoint?.evseId) {
    customNamePopupTitle = _t('Create chargepoint custom name');
  } else if (customNameChangeChargePoint && customNameChangeChargePoint?.customName !== customNameChangeChargePoint?.evseId) {
    customNamePopupTitle = _t('Edit chargepoint custom name');
  }

  const remoteActions = useChargePointRemoteActions({
    refreshRequested: () => chargePointsRefresh(),
    remoteStop: {
      do: async (cp) => (await api.station.remoteStopSession({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
    },
    remoteStart: {
      do: async (cp, card) => (await api.station.remoteStartSession({ cardExtId: card.extId, cardNumber: card.id, chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
      searchCards: async (q) => (await api.station.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteReserve: {
      do: async (cp, card, minutes) =>
        (await api.station.remoteReserve({ cardExtId: card.extId, cardNumber: card.id, chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, minutes: minutes })).data,
      searchCards: async (q) => (await api.station.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteCancelReserve: {
      do: async (cp) => (await api.station.remoteReserveCancel({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
      searchCards: async (q) => (await api.station.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteUnlock: {
      do: async (cp) => (await api.station.remoteUnlock({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
    },
    remoteChangeAvailability: {
      do: async (cp, av) => (await api.station.remoteChangeChargePointAvailability({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, type: av })).data,
    },
  });

  const [chargePointsResp, chargePointsFetching, chargePointsErr, chargePointsRefresh] = useApi(
    {
      call: async (
        clientCode?: string,
        hierarchyNodeCodes?: string[],
        skip?: number | null,
        limit?: number | null,
        plugTypes?: string[],
        ac?: string,
        dc?: string,
        available?: string,
        charging?: string,
        occupied?: string,
        failure?: string,
        unavailable?: string,
        unknown?: string,
        query?: string,
        isPublic?: string
      ) => {
        if (!clientCode) return undefined;

        const res = await api.station.listChargePoints({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          client: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
          outletTypes:
            plugTypes?.filter((type) =>
              getPlugTypeOptions()
                .map((option) => option.name)
                .includes(type)
            ) || [],
          ac: ac === '1',
          dc: dc === '1',
          available: available === '1',
          charging: charging === '1',
          occupied: occupied === '1',
          failure: failure === '1',
          unavailable: unavailable === '1',
          unknown: unknown === '1',
          searchText: query || undefined,
          hideNotInOperation: true,
          isPublic: isPublic === '1' ? true : undefined,
        });
        setHasExternalHardware(!!res.data.chargePoints.find((s) => s.isExternalHardware));
        setHasPublicStations(!!res.data.chargePoints.find((s) => s.isPublic));
        return res;
      },
      map: (data) => {
        if (!data) return data;
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.skip,
    filterParams.limit,
    filterParams.plugTypes,
    filterParams.ac,
    filterParams.dc,
    filterParams.available,
    filterParams.charging,
    filterParams.occupied,
    filterParams.failure,
    filterParams.unavailable,
    filterParams.unknown,
    filterParams.search,
    filterParams.public
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const navigateToStationListPage = (filter: { online?: number; offline?: number; failure?: number; notInOperation?: number; nodes?: string[] }) => {
    const link = `/infrastructure/stations/${filterParams.clientCode}`;
    navigate(link, filter);
  };

  const [mainModel, fetchingMainModel, errMainModel] = useApi(
    {
      call: async (clientCode?: string, hierarchyNodeCodes?: string[]) => {
        if (!clientCode) return undefined;

        return await api.station.getSummary({
          code: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
        });
      },
      map: (data) => {
        if (!data) return data;

        const stationsTotal = data.stationsOnline + data.stationsOffline + data.stationsFailure + data.stationsNotInOperation;
        const connectorsTotal =
          data.connectorsAvailable + data.connectorsCharging + data.connectorsUnavailable + data.connectorsUnknown + data.connectorsFailure + data.connectorsOccupied;
        return {
          clientContext: data.clientContext,
          totalEnergyConsumption: data.stationsAssumedEnergy,
          stationsSeries: {
            online: data.stationsOnline,
            offline: data.stationsOffline,
            failures: data.stationsFailure,
            notInOperation: data.stationsNotInOperation,
            total: stationsTotal,
          },
          connectorsSeries: {
            available: data.connectorsAvailable,
            charging: data.connectorsCharging,
            unavailable: data.connectorsUnavailable,
            occupied: data.connectorsOccupied,
            unknown: data.connectorsUnknown,
            failure: data.connectorsFailure,
            total: connectorsTotal,
          },
        };
      },
    },
    filterParams.clientCode,
    filterParams.nodes
  );

  return (
    <Page
      breadCrumb={[{ title: _t('CHARGEPOINTS'), href: '/chargepoints', active: true }]}
      fetching={chargePointsFetching || fetchingMainModel}
      error={chargePointsErr || errMainModel}
      placeHolder={<ListingCardPlaceholder />}
      outOfPlaceHolder={
        <>
          <OrgHierarchyPicker
            selectMode='node'
            clientContext={mainModel?.clientContext}
            onNodeSelected={(clientCode, selectedCodes) => {
              if (clientCode !== mainModel?.clientContext?.code) {
                navigate(`/infrastructure/chargepoints/${clientCode}`, {
                  nodes: selectedCodes,
                });
              } else {
                setFilterParams({ nodes: selectedCodes });
              }
            }}
            {...createClientSearchProps(mainModel?.clientContext)}
            selectedNodes={filterParams.nodes}
          />
          {/* Context popups */}
          <ChargePointRemoteActions {...remoteActions.chargePointRemoteActionsProps} />
          {mainModel && (
            <FormCard phoneSize='full'>
              <FormCardBody>
                <TabPanel>
                  <TabContent active={true} className={styles.stationsOverview}>
                    <StationStatusSummaryPanel
                      connectors={{
                        overallTotal: mainModel.connectorsSeries.total,
                        total: mainModel.connectorsSeries.total,
                        available: mainModel.connectorsSeries.available,
                        charging: mainModel.connectorsSeries.charging,
                        occupied: mainModel.connectorsSeries.occupied,
                        failure: mainModel.connectorsSeries.failure,
                        unavailable: mainModel.connectorsSeries.unavailable,
                        unknown: mainModel.connectorsSeries.unknown,
                        onAvailableClicked: () => {
                          setNewFilterParams({ available: '1', nodes: filterParams.nodes });
                        },
                        onChargingClicked: () => {
                          setNewFilterParams({ charging: '1', nodes: filterParams.nodes });
                        },
                        onOccupiedClicked: () => {
                          setNewFilterParams({ occupied: '1', nodes: filterParams.nodes });
                        },
                        onFailureClicked: () => {
                          setNewFilterParams({ failure: '1', nodes: filterParams.nodes });
                        },
                        onUnavailableClicked: () => {
                          setNewFilterParams({ unavailable: '1', nodes: filterParams.nodes });
                        },
                        onUnknownClicked: () => {
                          setNewFilterParams({ unknown: '1', nodes: filterParams.nodes });
                        },
                      }}
                      stations={{
                        overallTotal: mainModel.stationsSeries.total,
                        total: mainModel.stationsSeries.total,
                        failures: mainModel.stationsSeries.failures,
                        offline: mainModel.stationsSeries.offline,
                        online: mainModel.stationsSeries.online,
                        notInOperation: mainModel.stationsSeries.notInOperation,
                        onOnlineClicked: () => navigateToStationListPage({ online: 1, nodes: filterParams.nodes }),
                        onOfflineClicked: () => navigateToStationListPage({ offline: 1, nodes: filterParams.nodes }),
                        onFailureClicked: () => navigateToStationListPage({ failure: 1, nodes: filterParams.nodes }),
                        onNotInOperationClicked: () => navigateToStationListPage({ notInOperation: 1, nodes: filterParams.nodes }),
                      }}
                    />
                    <div data-cy='totalEnergyConsumption' className={styles.totalEnergyConsumptionPill}>
                      {_t('Aktuelle Ladeleistung')}: {formatter.formatNumber(Math.round(mainModel.totalEnergyConsumption))} kW
                    </div>
                  </TabContent>
                  <TabContent>...</TabContent>
                </TabPanel>
              </FormCardBody>
            </FormCard>
          )}

          {(hasExternalHardware || hasPublicStations) && (
            <FormCard phoneSize='full'>
              <FormCardBody>
                <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />
              </FormCardBody>
            </FormCard>
          )}

          <FormCard phoneSize='full'>
            <TabPanel>
              <Tabs>
                <Tab dataCy='tab-station-list' txt={_t('Ladestationen')} onClick={() => navigateToStationListPage({ nodes: filterParams.nodes })} ico={<StationIco />}></Tab>
                <Tab dataCy='tab-chargepoint-list' active={true} txt={_t('Ladepunkte')} ico={<PlugIco />}></Tab>
              </Tabs>
            </TabPanel>
          </FormCard>
          <FormCard phoneSize='full' className={styles.tabContent}>
            <ChargepointFilterArea
              filterParams={filterParams}
              searchTerm={searchTerm}
              setFilterParams={setFilterParams}
              updateSearchTerm={updateSearchTerm}
            ></ChargepointFilterArea>
          </FormCard>
          <FormCard phoneSize='full' className={styles.tabContent}>
            <ChargepointAppliedTags plugTypes={filterParams.plugTypes || []} setFilterParams={setFilterParams} show={{ ac: !!filterParams.ac, dc: !!filterParams.dc }} />
          </FormCard>
        </>
      }
      className={styles.root}
    >
      <div className={classNames(styles.cpListContainer, styles.tabContent)}>
        {chargePointsResp &&
          chargePointsResp.chargePoints.map((cp, i) => {
            const sessionsQuery = createQueryString({ entity: [{ id: cp.evseId, type: 'chargepoint' }] });
            const sessionsLink = `/${chargePointsResp?.sessionsPagePrefix}-charging-sessions/${filterParams.clientCode}?${sessionsQuery}`;
            const stationsPath = `/infrastructure/stations/${filterParams.clientCode}`;
            const hasRemoteActions = remoteActions.hasChargePointRemoteActions(cp);
            return (
              <ChargepointListItem
                key={i}
                item={cp}
                allNodes={[...(chargePointsResp?.clientContext.accessableNodes || []), ...(chargePointsResp?.clientContext.forbiddenParents || [])]}
                sessionsLink={sessionsLink}
                stationsPath={stationsPath}
                clientCode={filterParams.clientCode}
                loadManagementLinkActive={true}
                hasContextIcons={hasRemoteActions}
                onClickRemoteActionButton={(ev) => remoteActions.showChargePointRemoteActionContextMenu(ev, cp)}
                hasChangeCustomNamePopup={cp.can.setCustomName}
                onClickChangeCustomName={() => setCustomNameChangeChargePoint(cp)}
              ></ChargepointListItem>
            );
          })}
      </div>

      <StationCustomNamePopup
        close={() => setCustomNameChangeChargePoint(undefined)}
        onSubmit={async (customName) => {
          if (customNameChangeChargePoint) {
            api.station.setCustomNameForChargepoint({
              chargeBoxId: customNameChangeChargePoint.chargeBoxId,
              connectorId: customNameChangeChargePoint.connectorId,
              customName: customName,
              clientCode: filterParams.clientCode ?? '',
              connectorEvseId: customNameChangeChargePoint.evseId,
            });

            customNameChangeChargePoint.customName = customName;
          }

          setCustomNameChangeChargePoint(undefined);
        }}
        open={!!customNameChangeChargePoint}
        customName={customNameChangeChargePoint?.customName || ''}
        title={customNamePopupTitle}
      />

      {chargePointsResp && (
        <Paging
          className={classNames(styles.pagination, styles.tabContent)}
          skip={chargePointsResp.skip}
          limit={chargePointsResp.limit}
          total={chargePointsResp.total}
          onChange={(arg) => {
            setFilterParams({
              skip: arg.skip <= 0 ? null : arg.skip,
              limit: arg.limit,
            });
          }}
        />
      )}
      {chargePointsResp?.can?.createSupportTicket && <CreateServiceTicketAction caller={{ callerPath: pathname, callerTitle: _t('Charging Infrastructure') }} />}
    </Page>
  );
}
