import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as StationIco } from '../../assets/charging-station-blue.svg';
import { ReactComponent as PlugIco } from '../../assets/plug-blue.svg';
import { FormCard, FormCardBody } from '../../components/form-card';
import IconExplanationSection from '../../components/icon-explanation-section';
import { StationFilterParameters } from '../../components/infrastructure/filter-area-parameters.dto';
import StationAppliedTags from '../../components/infrastructure/station-applied-tags';
import StationFilterArea from '../../components/infrastructure/station-filter-area';
import StationListItem, { StationListItemModel } from '../../components/infrastructure/station-list-item';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import Page, { ListingCardPlaceholder } from '../../components/page';
import Paging from '../../components/paging';
import StationStatusSummaryPanel from '../../components/station-status-summary-panel/station-status-summary-panel';
import TabPanel, { Tab, TabContent, Tabs } from '../../components/tab-panel';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { createQueryString, usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { ChargePointItemDto } from '../../services/api-client/csp-api';
import { formatter } from '../../utils/localized-types';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { pagingValidator } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import { CreateServiceTicketAction } from '../support/create-ticket.page';
import ChargePointRemoteActions, { ChargePoint, useChargePointRemoteActions } from './chargepoint-remote-actions';
import StationCustomNamePopup from './custom-name-popup';
import StationRemoteActions, { useStationRemoteActions } from './station-remote-actions';
import styles from './stations-list-page.module.scss';

export default function StationsListPage() {
  const { _t } = useBetterTranslate('stations-list-page');
  const navigate = useBetterNavigate();

  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    clientCode?: string;
    nodes?: string[];
  } & StationFilterParameters;

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator });
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const { pathname } = useLocation();

  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const updateSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  const [hasExternalHardware, setHasExternalHardware] = useState(false);
  const [hasPublicStations, setHasPublicStations] = useState(false);

  const [customNameChangeStation, setCustomNameChangeStation] = useState<StationListItemModel | undefined>(undefined);
  const [customNameChangeChargePoint, setCustomNameChangeChargePoint] = useState<ChargePointItemDto | undefined>(undefined);

  const [stationsResp, stationsFetching, stationsApiErr, stationsRefresh] = useApi(
    {
      call: async (
        clientCode?: string,
        hierarchyNodeCodes?: string[],
        skip?: number | null,
        limit?: number | null,
        query?: string,
        online?: string,
        offline?: string,
        failure?: string,
        notInOperation?: string,
        ac?: string,
        dc?: string,
        acdc?: string,
        isPublic?: string
      ) => {
        if (!clientCode) return undefined;
        const res = await api.station.listStations({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          client: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
          search: query || undefined,
          online: online === '1',
          offline: offline === '1',
          failure: failure === '1',
          notInOperation: notInOperation === '1',
          ac: ac === '1',
          dc: dc === '1',
          acdc: acdc === '1',
          isPublic: isPublic === '1' ? true : undefined,
        });
        setHasExternalHardware(!!res.data.stations.find((s) => s.isExternalHardware === true));
        setHasPublicStations(!!res.data.stations.find((s) => s.isPublic === true));
        return res;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.online,
    filterParams.offline,
    filterParams.failure,
    filterParams.notInOperation,
    filterParams.ac,
    filterParams.dc,
    filterParams.acdc,
    filterParams.public
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const navigateToChargepointListPage = (filter: {
    available?: number;
    charging?: number;
    occupied?: number;
    failure?: number;
    unavailable?: number;
    unknown?: number;
    nodes?: string[];
  }) => {
    const link = `/infrastructure/chargepoints/${filterParams.clientCode}`;
    navigate(link, filter);
  };

  const [mainModel, fetchingMainModel, errMainModel] = useApi(
    {
      call: async (clientCode?: string, hierarchyNodeCodes?: string[]) => {
        if (!clientCode) return undefined;
        return await api.station.getSummary({
          code: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
        });
      },
      map: (data) => {
        if (!data) return data;
        const stationsTotal = data.stationsOnline + data.stationsOffline + data.stationsFailure + data.stationsNotInOperation;
        const connectorsTotal =
          data.connectorsAvailable + data.connectorsCharging + data.connectorsUnavailable + data.connectorsUnknown + data.connectorsFailure + data.connectorsOccupied;

        return {
          clientContext: data.clientContext,
          totalEnergyConsumption: data.stationsAssumedEnergy,
          stationsSeries: {
            online: data.stationsOnline,
            offline: data.stationsOffline,
            failures: data.stationsFailure,
            notInOperation: data.stationsNotInOperation,
            total: stationsTotal,
          },
          connectorsSeries: {
            available: data.connectorsAvailable,
            charging: data.connectorsCharging,
            unavailable: data.connectorsUnavailable,
            occupied: data.connectorsOccupied,
            unknown: data.connectorsUnknown,
            failure: data.connectorsFailure,
            total: connectorsTotal,
          },
        };
      },
    },
    filterParams.clientCode,
    filterParams.nodes
  );

  const fetchStationDetails = async (chargeBoxId: string) => {
    const res = await api.station.getChargepointsOfStation({
      hierarchyNodeCodes: [filterParams.clientCode!],
      client: filterParams.clientCode!,
      chargeBoxId: chargeBoxId,
    });
    return res.data;
  };

  const remoteActionsStation = useStationRemoteActions({
    refreshRequested: async () => {
      stationsRefresh();
    },
    changeAvailability: {
      do: async (station, availaility) => (await api.station.remoteChangeStationAvailability({ chargeBoxId: station.chargeBoxId, type: availaility })).data,
    },
    reset: {
      do: async (station) => (await api.station.resetStation(station.chargeBoxId)).data,
    },
  });

  const remoteActionsChargePoint = useChargePointRemoteActions({
    refreshRequested: async () => {
      stationsRefresh();
    },
    remoteStop: {
      do: async (cp) => (await api.station.remoteStopSession({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, connectorEvseId: cp.evseId })).data,
    },
    remoteStart: {
      do: async (cp, card) =>
        (await api.station.remoteStartSession({ cardExtId: card.extId, cardNumber: card.id, chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, connectorEvseId: cp.evseId }))
          .data,
      searchCards: async (q) => (await api.station.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteReserve: {
      do: async (cp, card, minutes) =>
        (
          await api.station.remoteReserve({
            cardExtId: card.extId,
            cardNumber: card.id,
            chargeBoxId: cp.chargeBoxId,
            connectorId: cp.connectorId,
            minutes: minutes,
            connectorEvseId: cp.evseId,
          })
        ).data,
      searchCards: async (q) => (await api.station.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteCancelReserve: {
      do: async (cp) => (await api.station.remoteReserveCancel({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, connectorEvseId: cp.evseId })).data,
      searchCards: async (q) => (await api.station.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteUnlock: {
      do: async (cp) => (await api.station.remoteUnlock({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, connectorEvseId: cp.evseId })).data,
    },
    remoteChangeAvailability: {
      do: async (cp, av) =>
        (await api.station.remoteChangeChargePointAvailability({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, type: av, connectorEvseId: cp.evseId })).data,
    },
  });

  let customNamePopupTitle = _t('Edit station custom name');
  if (customNameChangeStation && customNameChangeStation?.customName === customNameChangeStation?.chargeBoxId) {
    customNamePopupTitle = _t('Create station custom name');
  } else if (customNameChangeStation && customNameChangeStation?.customName !== customNameChangeStation?.chargeBoxId) {
    customNamePopupTitle = _t('Edit station custom name');
  } else if (customNameChangeChargePoint && customNameChangeChargePoint?.customName === customNameChangeChargePoint?.evseId) {
    customNamePopupTitle = _t('Create chargepoint custom name');
  } else if (customNameChangeChargePoint && customNameChangeChargePoint?.customName !== customNameChangeChargePoint?.evseId) {
    customNamePopupTitle = _t('Edit chargepoint custom name');
  }

  return (
    <Page
      breadCrumb={[{ title: _t('CHARGEPOINTS'), href: '/chargepoints', active: true }]}
      fetching={stationsFetching || fetchingMainModel}
      error={stationsApiErr || errMainModel}
      placeHolder={<ListingCardPlaceholder />}
      outOfPlaceHolder={
        <>
          <OrgHierarchyPicker
            selectMode='node'
            clientContext={mainModel?.clientContext}
            onNodeSelected={(clientCode, selectedCodes) => {
              if (clientCode !== mainModel?.clientContext?.code) {
                navigate(`/infrastructure/stations/${clientCode}`, {
                  nodes: selectedCodes,
                });
              } else {
                setFilterParams({ nodes: selectedCodes });
              }
            }}
            {...createClientSearchProps(mainModel?.clientContext)}
            selectedNodes={filterParams.nodes}
          />
          {/* Context popups */}
          <StationRemoteActions {...remoteActionsStation.stationRemoteActionsProps} />
          <ChargePointRemoteActions {...remoteActionsChargePoint.chargePointRemoteActionsProps} />

          {mainModel && (
            <>
              <FormCard phoneSize='full'>
                <FormCardBody>
                  <TabPanel>
                    <TabContent active={true} className={styles.stationsOverview}>
                      <StationStatusSummaryPanel
                        connectors={{
                          overallTotal: mainModel.connectorsSeries.total,
                          total: mainModel.connectorsSeries.total,
                          available: mainModel.connectorsSeries.available,
                          charging: mainModel.connectorsSeries.charging,
                          failure: mainModel.connectorsSeries.failure,
                          occupied: mainModel.connectorsSeries.occupied,
                          unavailable: mainModel.connectorsSeries.unavailable,
                          unknown: mainModel.connectorsSeries.unknown,
                          onAvailableClicked: () => navigateToChargepointListPage({ available: 1, nodes: filterParams.nodes }),
                          onChargingClicked: () => navigateToChargepointListPage({ charging: 1, nodes: filterParams.nodes }),
                          onOccupiedClicked: () => navigateToChargepointListPage({ occupied: 1, nodes: filterParams.nodes }),
                          onUnavailableClicked: () => navigateToChargepointListPage({ unavailable: 1, nodes: filterParams.nodes }),
                          onUnknownClicked: () => navigateToChargepointListPage({ unknown: 1, nodes: filterParams.nodes }),
                          onFailureClicked: () => navigateToChargepointListPage({ failure: 1, nodes: filterParams.nodes }),
                        }}
                        stations={{
                          overallTotal: mainModel.stationsSeries.total,
                          total: mainModel.stationsSeries.total,
                          failures: mainModel.stationsSeries.failures,
                          offline: mainModel.stationsSeries.offline,
                          online: mainModel.stationsSeries.online,
                          notInOperation: mainModel.stationsSeries.notInOperation,
                          onOnlineClicked: () => setFilterParams({ online: '1', offline: undefined, failure: undefined, notInOperation: undefined }),
                          onOfflineClicked: () => setFilterParams({ online: undefined, offline: '1', failure: undefined, notInOperation: undefined }),
                          onFailureClicked: () => setFilterParams({ online: undefined, offline: undefined, failure: '1', notInOperation: undefined }),
                          onNotInOperationClicked: () => setFilterParams({ online: undefined, offline: undefined, failure: undefined, notInOperation: '1' }),
                        }}
                      />
                      <div data-cy='totalEnergyConsumption' className={styles.totalEnergyConsumptionPill}>
                        {_t('Aktuelle Ladeleistung')}: {formatter.formatNumber(Math.round(mainModel.totalEnergyConsumption))} kW
                      </div>
                    </TabContent>
                    <TabContent>...</TabContent>
                  </TabPanel>
                </FormCardBody>
              </FormCard>

              {(hasExternalHardware || hasPublicStations) && (
                <FormCard phoneSize='full'>
                  <FormCardBody>
                    <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />
                  </FormCardBody>
                </FormCard>
              )}

              <FormCard phoneSize='full'>
                <TabPanel>
                  <Tabs>
                    <Tab dataCy='tab-station-list' active={true} txt={_t('Ladestationen')} ico={<StationIco />}></Tab>
                    <Tab dataCy='tab-chargepoint-list' txt={_t('Ladepunkte')} onClick={() => navigateToChargepointListPage({ nodes: filterParams.nodes })} ico={<PlugIco />}></Tab>
                  </Tabs>
                </TabPanel>
              </FormCard>

              <FormCard phoneSize='full' className={classNames(styles.tabContent)}>
                <StationFilterArea filterParams={filterParams} searchTerm={searchTerm} setFilterParams={setFilterParams} updateSearchTerm={updateSearchTerm}></StationFilterArea>
              </FormCard>
            </>
          )}
        </>
      }
      className={styles.root}
    >
      <FormCard phoneSize='full' className={classNames(styles.tabContent)}>
        <StationAppliedTags setFilterParams={setFilterParams} show={{ ac: !!filterParams.ac, dc: !!filterParams.dc, acdc: !!filterParams.acdc }}></StationAppliedTags>
      </FormCard>

      <div className={classNames(styles.stationListContainer, styles.tabContent)}>
        {stationsResp?.stations.map((station) => {
          const sessionsQuery = createQueryString({ entity: [{ id: station.chargeBoxId, type: 'station' }] });
          const sessionsLink = `/${stationsResp?.sessionsPagePrefix}-charging-sessions/${filterParams.clientCode}?${sessionsQuery}`;
          const chargepointsPath = `/infrastructure/chargepoints/${filterParams.clientCode}`;
          const hasStationContextItems = remoteActionsStation.hasStationRemoteActions({
            can: station.can,
            chargeBoxId: station.chargeBoxId,
            domainStatus: station.domainStatus! as any,
            // hasChargingConnectors: hasAnyChargingConn(station),
          });

          const hasChargePointContextItems = (cp: ChargePoint) => remoteActionsChargePoint.hasChargePointRemoteActions(cp);

          return (
            <StationListItem
              item={station}
              allNodes={[...(stationsResp?.clientContext.accessableNodes || []), ...(stationsResp?.clientContext.forbiddenParents || [])]}
              sessionsLink={sessionsLink}
              chargepointsPath={chargepointsPath}
              clientCode={filterParams.clientCode}
              loadManagementLinkActive={true}
              key={station.chargeBoxId}
              hasStationContextItems={hasStationContextItems}
              hasChargePointContextItems={hasChargePointContextItems}
              onClickStationRemoteActionButton={(ev: React.MouseEvent<Element, MouseEvent>) =>
                remoteActionsStation.showStationRemoteActionContextMenu(ev, {
                  can: station.can,
                  chargeBoxId: station.chargeBoxId,
                  domainStatus: station.domainStatus! as any,
                  // hasChargingConnectors: hasAnyChargingConn(station),
                })
              }
              onClickChargePointRemoteActionButton={remoteActionsChargePoint.showChargePointRemoteActionContextMenu}
              fetchStationDetails={() => fetchStationDetails(station.chargeBoxId)}
              hasChangeCustomNamePopup={station.can.setCustomName}
              onClickChangeCustomNameStation={() => setCustomNameChangeStation(station)}
              onClickChangeCustomNameChargePoint={(cp) => setCustomNameChangeChargePoint(cp)}
            />
          );
        })}
      </div>
      {stationsResp && (
        <Paging
          className={classNames(styles.pagination, styles.tabContent)}
          skip={stationsResp.skip}
          limit={stationsResp.limit}
          total={stationsResp.total}
          onChange={(arg) => {
            setFilterParams({
              skip: arg.skip <= 0 ? null : arg.skip,
              limit: arg.limit,
            });
          }}
        />
      )}
      {stationsResp?.can?.createSupportTicket && <CreateServiceTicketAction caller={{ callerPath: pathname, callerTitle: _t('Charging Infrastructure') }} />}
      <StationCustomNamePopup
        close={() => {
          setCustomNameChangeStation(undefined);
          setCustomNameChangeChargePoint(undefined);
        }}
        onSubmit={async (customName) => {
          if (customNameChangeStation) {
            await api.station.setCustomNameForStation({ chargeBoxId: customNameChangeStation.chargeBoxId, customName: customName, clientCode: filterParams.clientCode ?? '' });
            customNameChangeStation.customName = customName;
          } else if (customNameChangeChargePoint) {
            api.station.setCustomNameForChargepoint({
              chargeBoxId: customNameChangeChargePoint.chargeBoxId,
              connectorId: customNameChangeChargePoint.connectorId,
              customName: customName,
              clientCode: filterParams.clientCode ?? '',
              connectorEvseId: customNameChangeChargePoint.evseId,
            });

            customNameChangeChargePoint.customName = customName;
          }

          setCustomNameChangeStation(undefined);
          setCustomNameChangeChargePoint(undefined);
        }}
        open={!!(customNameChangeStation || customNameChangeChargePoint)}
        customName={customNameChangeStation?.customName || customNameChangeChargePoint?.customName || ''}
        title={customNamePopupTitle}
      />
    </Page>
  );
}
