import { useCallback, useState } from 'react';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { HighlightBox } from '../../components-v2/highlight-box';
import InputV2 from '../../components-v2/input';
import EditPoup from '../../components/edit-popup';
import { FormContainer } from '../../components/input';
import { FormRow } from '../../components/page-layout';
import useFormState from '../../hooks/useFormState';
import useBetterTranslate from '../../utils/translation-utils';
import useEffectAsync from '../../utils/useEffectAsync';
import styles from './custom-name-popup.module.scss';

interface SetCustomNamePopupProps {
  open: boolean;
  close: () => void;
  onSubmit: (newCustomName: string) => void;
  customName?: string;
  title?: string;
}

export default function SetCustomNamePopup({ close, onSubmit, open, customName, title }: Readonly<SetCustomNamePopupProps>) {
  const { _t } = useBetterTranslate('custom-name-popup');
  const formState = useFormState();
  const resetForm = formState.reset;
  const [pending, setPending] = useState(false);

  const onOpen = useCallback(async () => {
    resetForm();
  }, [resetForm]);

  useEffectAsync(async () => {
    setPending(false);
    if (open) {
      formState.reset();
      setPending(false);
      await onOpen();
    }
  }, [open]);

  //submission
  formState.handleSubmit(async () => {
    if (pending) return;
    setPending(true);
    try {
      await onSubmit(newCustomName.value);
    } finally {
      setPending(false);
    }
  });

  const [newCustomName] = formState.useInputState({
    initialValue: customName ?? '',
    label: _t('Type here the custom name to help identify this device'),
    maxLength: 50,
    validate: async (v) => (v.length > 50 ? _t('Maximum length is 50') : undefined),
  });

  return (
    <EditPoup
      open={open}
      className={styles.popup}
      bodyClassName={styles.body}
      skipAutoFocusOnOpen={false}
      onClose={close}
      onSave={async () => await formState.submit()}
      additionalFooterContentClassName={styles.submitError}
      additionalFooterContent={formState.submitError}
      title={title}
      closeIcon={<></>}
      saveButtonDisabled={pending}
    >
      <FormRow>
        <FormContainer label={newCustomName.label} validationErr={newCustomName.validationErr}>
          <InputV2 {...newCustomName} />
        </FormContainer>
      </FormRow>
      <FormRow>
        <HighlightBox icoClassName={styles.infoIco} apperance='mute' ico={<InfoIcon />}>
          <span>
            {_t("The custom label must be appropriate, relevant and not excessive. Labels must therefore remain objective, not be insulting or undermine a person's dignity.")}
          </span>
        </HighlightBox>
      </FormRow>
      {/* <FormRow>
        <FormContainer>

        </FormContainer>
      </FormRow> */}
      {/* <div className={styles.helper}>
        <div>
          <InfoIcon />
        </div>
        <span>
          {_t("The custom label must be appropriate, relevant and not excessive. Labels must therefore remain objective, not be insulting or undermine a person's dignity.")}
        </span>
      </div> */}
      {/* <div className={styles.popupFooter}>
        <ButtonV2 apperance='accent' className={styles.button} onClick={close}>
          <ArrowLeft />
          <span>{_t('Cancel')}</span>
        </ButtonV2>
        <div className={styles.divider}></div>
        <ButtonV2 className={styles.button} disabled={saveButtonDisabled} onClick={formState.submit}>
          <Check />
          <span>{_t('Save')}</span>
        </ButtonV2>
      </div> */}
    </EditPoup>
  );
}
