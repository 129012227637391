import classNames from 'classnames';
import { LegacyRef, MouseEvent, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styles from './button.module.scss';

export type ButtonAppereance = 'primary' | 'accent' | 'warn' | 'danger' | 'icon';
export type ButtonV2Props = PropsWithChildren<{
  apperance?: ButtonAppereance;
  disabled?: boolean;
  href?: string;
  size?: 'l' | 'm' | 's';
  className?: string;
  ralign?: boolean;
  tooltip?: string;
  tabindex?: number;
  ref?: LegacyRef<HTMLButtonElement> | undefined;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: (event: MouseEvent) => void;
  autoFocus?: boolean;
  submitSelector?: boolean;
  dataCy?: string;
  block?: boolean;
}>;
export default function ButtonV2(props: ButtonV2Props) {
  const kind = props.apperance || 'primary';
  const block = props.block ? 'block' : '';
  const size = `size_${props.size || 'm'}`;
  const alignCls = props.ralign ? styles.allignRight : '';
  let dataAttributes: Record<string, any> = {};
  if (props.autoFocus) {
    dataAttributes['data-auto-focus'] = true;
  }
  if (props.submitSelector) {
    dataAttributes['data-submit-selector'] = true;
  }

  if (props.href) {
    return (
      <Link
        {...dataAttributes}
        title={props.tooltip}
        to={props.href}
        className={classNames(styles.root, props.className, styles[size], styles[block], styles[kind], alignCls, props.disabled === true ? styles.disabled : '')}
        onClick={props.disabled ? (e) => e.preventDefault() : props.onClick}
      >
        {props.children}
      </Link>
    );
  } else {
    return (
      <button
        {...dataAttributes}
        ref={props.ref}
        title={props.tooltip}
        tabIndex={props.tabindex}
        type={props.type || 'button'}
        disabled={props.disabled}
        className={classNames(styles.root, styles[size], styles[block], props.className, styles[kind], alignCls, props.disabled === true ? styles.disabled : '')}
        onClick={props.onClick}
        data-cy={props.dataCy}
      >
        {props.children}
      </button>
    );
  }
}

export function ButtonV2Primary(props: Omit<ButtonV2Props, 'apperance'>) {
  return <ButtonV2 {...props} apperance={'primary'}></ButtonV2>;
}

export function ButtonAccent(
  props: PropsWithChildren<{
    autoFocus?: boolean;
    submitSelector?: boolean;
    disabled?: boolean;
    href?: string;
    className?: string;
    tooltip?: string;
    ralign?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: () => void;
    dataCy?: string;
  }>
) {
  return <ButtonV2 {...props} apperance={'accent'}></ButtonV2>;
}
