import styles from './progress.module.scss';

import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export type ProgressStyle = 'success' | 'accent' | 'danger' | 'warn' | 'primary' | 'info' | 'off' | 'custom';

export function ProgressBar(props: PropsWithChildren<{ className?: string; colorStyle: ProgressStyle; innerBarClassName?: string; value: number }>) {
  const style: ProgressStyle = props.colorStyle || 'accent';
  return (
    <div style={{ minWidth: `${props.value}%` }} className={classNames(styles.progressContainer, props.className)}>
      <div style={{ width: `${props.value}%` }} className={classNames(styles.progress, styles[style], props.innerBarClassName)}>
        {props.children}
      </div>
    </div>
  );
}

export function ProgressBarStack(props: PropsWithChildren<{ className?: string }>) {
  return <div className={classNames(styles.stack, props.className)}>{props.children}</div>;
}
