import classNames from 'classnames';
import { ReactComponent as SpinnerIco } from '../../assets/spinner3.svg';
import { DataTableColumn } from '../../components/data-table/data-table';
import { ExternalHardwareIcon, PublicStationIcon } from '../../components/evse-linfo-icons';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import NodeLabel from '../../components/org-hierarchy/node-label';
import { RateTypeTag } from '../../components/tag';
import api from '../../services/api';
import {
  LmFmChargingSessionListItemDto,
  LmFmChargingSessionListResultDto,
  LmFmChargingSessionSumsResultDto,
  RateServiceType,
  SessionStatus,
} from '../../services/api-client/csp-api';
import { useAuth } from '../../utils/AuthProvider';
import { endOfDay, getTimezone, startOfDay } from '../../utils/date';
import { formatDuration, formatEnergy, formatPrice } from '../../utils/format';
import { formatter } from '../../utils/localized-types';
import useBetterTranslate from '../../utils/translation-utils';
import uniqueLineIdentifier from '../../utils/unique-line-ident';
import { ChargingSessionsListPage, ExportSessionsArgs, FilterParameters, StatusSessionColumn } from './charging-sessions-list-page';
import styles from './charging-sessions-list-page.module.scss';

const SERVICE_TYPE_ALLOWED_VALUES = [RateServiceType.WORK, RateServiceType.EMPLOYEE, RateServiceType.HOME, RateServiceType.PUBLIC, RateServiceType.UNDEFINED];

export default function LmFmChargingSessionsListPage() {
  const SORT_BY_VALUES = ['evseId', 'cardNumber', 'purchaseCost', 'totalCost'];
  const { _t } = useBetterTranslate('lmfm-charging-sessions-list-page');
  const { user } = useAuth();

  const sessionsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.lmfmChargingSessions.list(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const sumsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.lmfmChargingSessions.sums(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const exportApiCall = async (filterParams: FilterParameters, args: ExportSessionsArgs, cancelToken: string) => {
    const apiFilter = toSearchApiFilter(filterParams);
    if (!apiFilter) return undefined;

    return await api.lmfmChargingSessions.export(
      {
        filter: apiFilter,
        sessionFields: args.sessionFields,
        stationFields: args.stationFields,
        cardFields: args.cardFields,
        fileType: args.fileType,
        aggregateByStationId: args.aggregateByStationId,
        aggregateByStationLocation: args.aggregateByStationLocation,
        aggregateByCardId: args.aggregateByCardId,
        aggregateByCardLocation: args.aggregateByCardLocation,
        serviceTypeLabels: args.serviceTypeLabels,
        aggregationLabels: args.aggregationLabels,
        timezone: args.timezone,
      },
      { cancelToken }
    );
  };

  const toSearchApiFilter = (filter: FilterParameters) => {
    if (!filter.clientCode) return undefined;
    let consumedEnergyFrom = filter.energyFrom;
    if (consumedEnergyFrom !== undefined) {
      consumedEnergyFrom = consumedEnergyFrom * 1000;
    }
    let consumedEnergyTo = filter.energyTo;
    if (consumedEnergyTo !== undefined) {
      consumedEnergyTo = consumedEnergyTo * 1000;
    }
    const apiFilter = {
      client: filter.clientCode,
      hierarchyNodeCodes: filter.nodes || [filter.clientCode],
      limit: Number(filter.limit || 20),
      skip: Number(filter.skip || 0),
      from: startOfDay(filter.from),
      to: endOfDay(filter.to),
      entityFilter: filter.entity || [],
      serviceTypes: filter.serviceType as RateServiceType[],
      sortBy: filter.sortBy as any,
      sortDesc: filter.sortDesc === '1',
      energy: { from: consumedEnergyFrom, to: consumedEnergyTo },
      purchaseCost: { from: filter.purchaseCostFrom, to: filter.purchaseCostTo },
      totalCost: { from: filter.totalCostFrom, to: filter.totalCostTo },
      status: filter.status as SessionStatus[],
      stationIsPublic: filter.public === '1' ? true : undefined,
    };
    return apiFilter;
  };

  const energyApiCall = async (clientCode?: string, from?: string, to?: string, nodes?: string[]) => {
    if (!clientCode) return undefined;
    const resp = await api.lmfmChargingSessions.energyConsumption(
      {
        client: clientCode,
        hierarchyNodeCodes: nodes || [clientCode],
        from: startOfDay(from),
        to: endOfDay(to),
        timezone: getTimezone(),
      },
      { cancelToken: uniqueLineIdentifier() }
    );
    return resp;
  };

  const searchEntityTags = async (clientCode: string | undefined, txt: string) => {
    if (!clientCode) return [];
    const entityFilterRes = await api.lmfmChargingSessions.searchFilters({ q: txt, clientCode }, { cancelToken: uniqueLineIdentifier() });
    return entityFilterRes.data;
  };

  const dataTableCols = (sumFetching: boolean, sessionsResp?: LmFmChargingSessionListResultDto, sumsResp?: LmFmChargingSessionSumsResultDto) => {
    const cols: DataTableColumn<LmFmChargingSessionListItemDto>[] = [
      {
        headerCol: () => (
          <div>
            {_t('EVSEID')}
            <br />
            <span className={styles.subLabel}>{_t('Standort')}</span>
          </div>
        ),
        name: 'evseId',
        sortable: true,
        col: (record) => {
          const hierarchy = sessionsResp?.allClientHierarchy || [];
          return (
            <div>
              <div className={styles.evseId} data-cy={`el_evselD`}>
                {record.evseId}
                <ExternalHardwareIcon isExternalHardware={record.stationIsExternalHardware} />
                <PublicStationIcon isPublic={record.stationIsPublic} />
              </div>
              {record.stationClientCode === sessionsResp?.clientContext.code && (
                <>
                  <NodeHierarchyLabelInverted allNodes={hierarchy} code={record.stationClientNodeCode} hideArea={true} hideClientRoot={true} />
                  <NodeLabel allNodes={hierarchy} code={record.stationClientNodeCode} />
                </>
              )}
              {record.stationClientCode !== sessionsResp?.clientContext.code && (
                <>
                  <div className={styles.warning}>{record.stationClientCode}</div>
                  <div>{'-'}</div>
                </>
              )}
            </div>
          );
        },
        width: '25%',
      },
      {
        headerCol: () => (
          <div>
            {_t('Kartennummer')}
            <br />
            <span className={styles.subLabel}>{_t('Kartenbezeichnung')}</span>
            <br />
            <span className={styles.subLabel}>{_t('Kostenstelle')}</span>
          </div>
        ),
        name: 'cardNumber',
        sortable: true,
        col: (record) => {
          const hasAccess = record.cardNumber || record.cardLabel;
          const hierarchy = hasAccess ? [] : sessionsResp?.allClientHierarchy || [];
          return (
            <div>
              {hasAccess && (
                <>
                  <div data-cy={`el_cardNumber`}>{record.cardNumber || '-'}</div>
                  <div data-cy={`el_cardLabel`}>{record.cardLabel || '-'}</div>
                  <div data-cy={`el_cardComment`}>{record.cardComment || '-'}</div>
                </>
              )}

              {!hasAccess && record.cardClientCode === sessionsResp?.clientContext.code && (
                <>
                  <NodeHierarchyLabelInverted allNodes={hierarchy} code={record.cardClientNodeCode} hideLocation={true} hideArea={true} hideClientRoot={true} />
                  <NodeHierarchyLabelInverted allNodes={hierarchy} code={record.cardClientNodeCode} hideRegion={true} hideArea={true} hideClientRoot={true} />
                  <div>{record.cardComment || '-'}</div>
                </>
              )}

              {!hasAccess && record.cardClientCode && record.cardClientCode !== sessionsResp?.clientContext.code && (
                <>
                  <div className={styles.warning}>{record.cardClientCode || '-'}</div>
                  <div>{'-'}</div>
                  <div>{record.cardComment || '-'}</div>
                </>
              )}
            </div>
          );
        },
        width: '20%',
      },
      {
        headerCol: () => <div>{_t('Start und Dauer')}</div>,
        name: 'startTime',
        sortable: true,
        col: (record) => {
          return (
            <div>
              {formatter.formatDateTimeFromIsoString(record.startTime, user?.preferences.languageCode, { dateStyle: 'short', timeStyle: 'short' })}
              <br />
              {formatDuration(record.startTime, record.endTime)}
            </div>
          );
        },
        width: '15%',
      },
      {
        headerCol: () => <div>{_t('Genutzter Service')}</div>,
        name: 'rateServiceType',
        sortable: true,

        col: (record) => <RateTypeTag dataCy='el_service' selected={true} rateType={record.rateServiceType} classNames={styles.serviceTypeCol} />,
        width: '10%',
      },
      {
        headerCol: () => <div>{_t('Geladene Energie (kWh)')}</div>,
        name: 'energy',
        sortable: true,
        sumCol: () => (sumFetching ? <SpinnerIco /> : <div>{formatEnergy(sumsResp?.totalEnergy, user?.preferences.languageCode, 2)}</div>),
        col: (record) => <div data-cy='el_energy'>{formatEnergy(record.energy, user?.preferences.languageCode, 2)}</div>,
        ralign: true,
        width: '10%',
      },

      {
        headerCol: () => <div>{_t('Ladeerstattung (€)')}</div>,
        name: 'purchaseCost',
        sortable: true,
        sumCol: () => (sumFetching ? <SpinnerIco /> : <div>{formatPrice(sumsResp?.purchaseCost, user?.preferences.languageCode, 2)}</div>),
        col: (record) => <div data-cy='el_refund'>{formatPrice(record?.purchaseCost, user?.preferences.languageCode, 2)}</div>,
        ralign: true,
        width: '10%',
      },
      {
        headerCol: () => <div>{_t('Ladekosten (€)')}</div>,
        name: 'totalCost',
        sortable: true,
        colClass: () => classNames(styles.onGoingCol, styles.header),
        sumCol: () => (sumFetching ? <SpinnerIco /> : <div>{formatPrice(sumsResp?.totalCost, user?.preferences.languageCode)}</div>),
        col: (record) => (
          <div className={classNames(styles.onGoingCol, styles.content)}>
            <div data-cy='el_cost' className={classNames(styles.data)}>
              {formatPrice(record?.totalCost, user?.preferences.languageCode, 2)}
            </div>
            <StatusSessionColumn dataCy='el_status' status={record.status} />
          </div>
        ),
        ralign: true,
        width: '10%',
      },
    ];
    return cols;
  };

  return ChargingSessionsListPage<LmFmChargingSessionListItemDto, LmFmChargingSessionListResultDto, LmFmChargingSessionSumsResultDto>({
    pagePrefix: 'lmfm',
    sortValues: SORT_BY_VALUES,
    totalCostFilter: true,
    purchaseCostFilter: true,
    isStationPublicFilter: true,
    serviceTypes: SERVICE_TYPE_ALLOWED_VALUES,
    sessionsApiCall,
    sumsApiCall,
    energyApiCall,
    searchEntityTags,
    exportFieldsConfig: { showStations: true, showCards: true },
    exportApiCall,
    dataTableCols,
    rowAttributes: (record) => {
      return {
        'data-cy': `charging_records_element`,
        'data-cy-id': record.evseId?.toString(),
      };
    },
  });
}
