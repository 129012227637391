import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import * as _ from 'lodash';
import { useUserPreferences } from '../utils/UsePreferenceProvider';

interface ParamObject {
  id: string;
  type: string;
}

export type ValueType = string | number | undefined | null | string[] | ParamObject[];
export interface ParametersList {
  [name: string]: ValueType;
}

const ARRAY_OBJECTS_SUFFIX = '--';
const ARRAY_STRINGS_SUFFIX = '-';
const PARAM_OBJECT_SPLITTER = '-';

export function encodeArrayForQuery(queryName: string, val: string[] | ParamObject[]) {
  const isObjArray = val[0] instanceof Object;
  const fieldName = `${queryName}${isObjArray ? ARRAY_OBJECTS_SUFFIX : ARRAY_STRINGS_SUFFIX}`;
  let arr: string[];
  if (isObjArray) {
    arr = (val as ParamObject[]).map((x) => `${x.type}${PARAM_OBJECT_SPLITTER}${x.id}`);
  } else {
    arr = val as string[];
  }

  const queryString = arr.join(',');
  return { name: fieldName, value: queryString };
}

export function createQueryString(val: any) {
  const params = new URLSearchParams();
  for (let [k, v] of Object.entries(val)) {
    if (v === undefined || v === null || (Array.isArray(v) && v.length === 0)) continue;

    if (Array.isArray(v)) {
      const { name, value } = encodeArrayForQuery(k, v);
      params.set(name, value);
    } else {
      params.set(k, (v as any).toString());
    }
  }

  return params.toString();
}

// the default values should be set from the code not from the query parameters, you can override it in the query parameters but it will be always used if not found in the query parameters.
export function usePageParams<T extends ParametersList>(defaultValues: T, validator: { [K in keyof T]: (val: string) => boolean }): [T, (newVal: T) => void] {
  //init the queryParams
  const [queryParams, setQueryParams] = useSearchParams();
  const { clientCode } = useParams();
  const { affiliateCode } = useParams();
  const localPrefs = useUserPreferences();

  //init the dataObj from the default value & the query parameters
  const [dataObj, setDataObj] = useState<T>(getDataObjFromQueryParams(queryParams, defaultValues, clientCode, affiliateCode, validator as any));

  //setter function that set the changes at the queryParams
  const setter = useCallback(
    (val: T) => {
      for (let [k, v] of Object.entries(val)) {
        if (v === undefined || v === null || (Array.isArray(v) && v.length === 0)) {
          queryParams.delete(k);
          queryParams.delete(`${k}${ARRAY_OBJECTS_SUFFIX}`);
          queryParams.delete(`${k}${ARRAY_STRINGS_SUFFIX}`);
        } else if (Array.isArray(v)) {
          const { name, value } = encodeArrayForQuery(k, v);
          queryParams.set(name, value);
        } else {
          queryParams.set(k, v.toString());
        }
      }

      setQueryParams(queryParams);
    },
    [queryParams, setQueryParams]
  );

  // IMPORTANT:
  // useParams (to get the client code) needs to be inside of a <Route /> elemeng (not <Router />)
  // which means only pages have access to it, so we cannot listen to clientCode param changes on a higher level
  // instead we need to do this on all pages which use this (page params hook)
  useEffect(() => {
    if (!clientCode) return;

    // defer to avoid rendering issues
    localPrefs.setClientCode(clientCode);
  }, [localPrefs, clientCode]);

  useEffect(() => {
    if (!affiliateCode) return;

    // defer to avoid rendering issues
    localPrefs.setAffiliateCode(affiliateCode);
  }, [localPrefs, affiliateCode]);

  //listen to the queryParams changes and update the dataObj
  useEffect(() => {
    const newData = getDataObjFromQueryParams(queryParams, defaultValues, clientCode, affiliateCode, validator as any);
    for (const [key] of Object.entries(dataObj)) {
      if (!newData[key]) {
        newData[key] = undefined;
      }
    }
    if (!_.isEqual(newData, dataObj)) {
      setDataObj(newData);
    }
  }, [queryParams, defaultValues, validator, dataObj, clientCode, affiliateCode]);

  return [dataObj, setter];
}

//function to construct the dataObject form the query parameters
interface Validator {
  [name: string]: (val: string) => boolean;
}

function getDataObjFromQueryParams(urlQuery: URLSearchParams, defaultValues: any, clientCode?: string, affiliateCode?: string, validator?: Validator) {
  const data = { ...defaultValues };
  let name = null;
  let value = null;

  for (let [k, v] of Array.from(urlQuery.entries())) {
    if (k.endsWith(ARRAY_OBJECTS_SUFFIX)) {
      const arr = v.split(',');
      const objs: ParamObject[] = [];
      arr.forEach((x) => {
        const index = x.indexOf(PARAM_OBJECT_SPLITTER);
        if (index > 0) {
          objs.push({ id: x.substring(index + 1), type: x.substring(0, index) });
        }
      });
      name = k.substring(0, k.length - ARRAY_OBJECTS_SUFFIX.length);
      value = objs;
    } else if (k.endsWith(ARRAY_STRINGS_SUFFIX)) {
      const arr = v.split(',');
      name = k.substring(0, k.length - ARRAY_STRINGS_SUFFIX.length);
      value = arr;
    } else {
      name = k;
      value = v;
    }
    if (isValid(name, value, validator)) {
      data[name] = value;
    }
  }
  data['clientCode'] = clientCode;
  data['affiliateCode'] = affiliateCode;

  return data;
}

function isValid(name: string, value: any, validator?: Validator): boolean {
  const validateFn = validator ? validator[name] : undefined;
  if (validateFn) {
    return validateFn(value);
  }
  return true;
}
