import classNames from 'classnames';
import _ from 'lodash';
import { RateServiceType, SessionsCountDto, SessionsEnergyEntryDto, SessionsEnergySumDto, TimeGroupResolution } from '../../services/api-client/csp-api';
import { useAuth } from '../../utils/AuthProvider';
import { formateByResolution } from '../../utils/date';
import { formatKWhEnergy, formatPercentage } from '../../utils/format';
import { formatter } from '../../utils/localized-types';
import useBetterTranslate from '../../utils/translation-utils';
import ChartLegendTable, { ChartLegendTableWithoutPill } from '../charg-legend-table/chart-legend-table';
import { FormCard } from '../form-card';
import Info from '../info';
import { ListingCardPlaceholder } from '../page';
import Pill from '../pill';
import { ProgressBar, ProgressBarStack } from '../progress';
import StackedBarChart from '../stacked-bar-chart/stacked-bar-chart';
import colorStyles from '../style-utils';
import { rateTypeColorStyle, rateTypeTooltip, rateTypeTranslation } from '../tag';
import styles from './sessions-energy-chart.module.scss';

export default function SessionsEnergyChart(props: {
  loading: boolean;
  showLegend: boolean;
  showCardsCount?: boolean;
  className?: string;
  serviceTypes: RateServiceType[];
  onClicked?: (service: RateServiceType) => void;
  allowedServiceTypes?: RateServiceType[];
  resolution?: TimeGroupResolution;
  entries?: SessionsEnergyEntryDto[];
  sum?: SessionsEnergySumDto;
  counts?: SessionsCountDto;
  cardsCount?: number;
}) {
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode || 'en';

  const { _t } = useBetterTranslate('sessions-energy-chart');
  if (props.loading) {
    return <ListingCardPlaceholder cardNumber={1} cardSize={2} fullWidth={true} />;
  }
  const getCount = (service: RateServiceType) => {
    if (service === RateServiceType.WORK) return props.counts?.workCount;
    if (service === RateServiceType.EMPLOYEE) return props.counts?.employeeCount;
    if (service === RateServiceType.HOME) return props.counts?.homeCount;
    if (service === RateServiceType.PUBLIC) return props.counts?.publicCount;
    if (service === RateServiceType.UNDEFINED) return props.counts?.undefinedCount;
  };
  const getEnergy = (service: RateServiceType) => {
    if (service === RateServiceType.WORK) return props.sum?.workEnergy;
    if (service === RateServiceType.EMPLOYEE) return props.sum?.employeeEnergy;
    if (service === RateServiceType.HOME) return props.sum?.homeEnergy;
    if (service === RateServiceType.PUBLIC) return props.sum?.publicEnergy;
    if (service === RateServiceType.UNDEFINED) return props.sum?.undefinedEnergy;
  };

  const getSeriesData = (service: RateServiceType) => {
    if (!props.entries) return [{ xKey: '', y: 0 }];
    if (service === RateServiceType.WORK) return props.entries.map((r) => ({ xKey: r.date, y: r.workEnergy }));
    if (service === RateServiceType.EMPLOYEE) return props.entries.map((r) => ({ xKey: r.date, y: r.employeeEnergy }));
    if (service === RateServiceType.HOME) return props.entries.map((r) => ({ xKey: r.date, y: r.homeEnergy }));
    if (service === RateServiceType.PUBLIC) return props.entries.map((r) => ({ xKey: r.date, y: r.publicEnergy }));
    return props.entries.map((r) => ({ xKey: r.date, y: r.undefinedEnergy }));
  };

  const legendItems = (mode: 'count' | 'energy') => {
    const result = props.serviceTypes.map((service) => {
      const hide = !props.allowedServiceTypes?.includes(service);
      return {
        title: rateTypeTranslation(service.toString()),
        dataCy: service,
        dataCyValue: mode === 'count' ? `pills_total_${service}` : `pills_${service}_kwh`,
        value: `${mode === 'count' ? formatter.formatNumber(getCount(service), languageCode) : formatKWhEnergy(getEnergy(service)?.value, languageCode)}`,
        pillClass: rateTypeColorStyle(service),
        hide,
        onClick: () => props.onClicked?.(service),
        tooltip: hide ? undefined : rateTypeTooltip(service),
      };
    });
    const toInt = (flag?: boolean) => (flag ? 1 : 0);
    result.sort((a, b) => toInt(a.hide) - toInt(b.hide));
    return result;
  };

  const progressBars = (props.allowedServiceTypes || []).map((service) => ({ style: rateTypeColorStyle(service), value: getEnergy(service)?.percentage || 0 }));

  const series = (props.allowedServiceTypes || []).map((service) => ({ barClass: rateTypeColorStyle(service), name: service.toString(), data: getSeriesData(service) }));

  let xLabel;
  if (props.resolution && props.entries && props.entries.length > 1) {
    xLabel = `${formateByResolution(props.entries[0].date, languageCode, props.resolution)} - ${formateByResolution(
      props.entries[props.entries.length - 1].date,
      languageCode,
      props.resolution
    )}`;
  } else if (props.resolution && props.entries && props.entries.length === 1) {
    xLabel = formateByResolution(props.entries[0].date, languageCode, props.resolution);
  } else {
    xLabel = '';
  }

  return (
    <div className={classNames(styles.root, props.className)}>
      {props.showLegend && (
        <FormCard tabletSize='half' desktopSize='one-third'>
          {props.showCardsCount && (
            <div className={styles.legend}>
              <div className={styles.cardsCount}>
                <div className={styles.titleValue}>
                  <h4>{_t('Anzahl Karten')}</h4>
                  <div data-cy={'number_of_cards'}>{formatter.formatNumber(props.cardsCount, languageCode)}</div>
                </div>
              </div>
              <div className={styles.emptyCol}></div>
            </div>
          )}

          <div className={styles.legend}>
            <div className={styles.sessionsCount}>
              <div>
                <h4>{_t('Anzahl Ladevorgänge')}</h4>
                <ChartLegendTableWithoutPill
                  items={[{ dataCy: 'pills_total_sessions', title: _t('Gesamt'), value: `${formatter.formatNumber(props.counts?.totalCount, languageCode)}` }]}
                />
              </div>
              <div>
                <h4>{_t('Genutzte Sevices')}</h4>
                <ChartLegendTable items={legendItems('count')} />
              </div>
            </div>
            <div className={styles.energySum}>
              <div>
                <h4>{_t('Geladene Energie')}</h4>
                <ChartLegendTableWithoutPill
                  items={[{ dataCy: 'pills_total_kwh', title: _t('Gesamt'), value: `${formatKWhEnergy(props.sum?.totalEnergy.value, languageCode)}` }]}
                />
              </div>
              <div>
                <h4>
                  {_t('Genutzte Sevices')}{' '}
                  {props.showCardsCount && (
                    <Info content={_t('Es handelt sich um gerundete Werte. Diese können sich deshalb von der angezeigten Summe in der Tabelle unterscheiden.')}></Info>
                  )}
                </h4>
                <ChartLegendTable items={legendItems('energy')} />
              </div>
            </div>
          </div>
        </FormCard>
      )}

      <FormCard tabletSize={props.showLegend ? 'half' : 'full'} desktopSize={props.showLegend ? 'two-third' : 'full'}>
        <div className={styles.charts}>
          <ProgressBarStack className={styles.totalSumBar}>
            {progressBars.map((item, idx) => {
              return (
                <ProgressBar key={idx} innerBarClassName={item.style} value={item.value} colorStyle='custom'>
                  {formatPercentage(item.value, languageCode, 0, 0)}
                </ProgressBar>
              );
            })}
          </ProgressBarStack>
          <div className={styles.barChart}>
            <StackedBarChart
              items={series}
              formatYLbl={(val) => formatKWhEnergy(Math.round(val / 1000), languageCode)}
              noXlbls={true}
              yLblClass={styles.yAxis}
              onTooltip={(data) => {
                const createRow = (cls: string, lbl: string, value: number) => {
                  return (
                    <div className={styles.detailRow} key={lbl}>
                      <Pill className={classNames(cls, styles.pill)}></Pill>
                      <div>
                        {lbl}: {formatKWhEnergy(Math.round(value), languageCode)}
                      </div>
                    </div>
                  );
                };
                const points = data ? data.points.map((p, idx) => ({ cls: rateTypeColorStyle(p.name), lbl: rateTypeTranslation(p.name), value: Math.round(p.value / 1000) })) : [];
                points.unshift({ cls: colorStyles.components.rateType.all, lbl: _t('Gesamt'), value: _.sumBy(points, (p) => p.value) });

                const details = points.map((p) => createRow(p.cls, p.lbl, p.value));
                return (
                  <div className={styles.popup}>
                    {details}
                    <div>{data && props.resolution ? formateByResolution(data.xKey, languageCode, props.resolution) : ''}</div>
                  </div>
                );
              }}
            />
          </div>
          <div className={styles.barChartXLabel}>{xLabel}</div>
        </div>
      </FormCard>
    </div>
  );
}
