import classNames from 'classnames';
import { useState } from 'react';
import { ReactComponent as ChartIco } from '../../assets/bar-chart.svg';
import { ReactComponent as BoltIco } from '../../assets/bolt.svg';
import { ReactComponent as CollapsedIco } from '../../assets/collapsed.svg';
import { ReactComponent as ExpandedIco } from '../../assets/expanded.svg';
import Accordion from '../../components/accordion/accordion';
import { FormCard, FormCardBody } from '../../components/form-card';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import Page, { ListingCardPlaceholder } from '../../components/page';
import Pill from '../../components/pill';
import StationStatusSummaryPanel from '../../components/station-status-summary-panel/station-status-summary-panel';
import colorStyles from '../../components/style-utils';
import TabPanel, { Tab, TabContent, Tabs } from '../../components/tab-panel';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { formatter } from '../../utils/localized-types';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import useBetterTranslate from '../../utils/translation-utils';

import styles from './charging-stations-overview-page.module.scss';

interface PanelModelItem {
  nodeCode: string;
  expanded: boolean;
  payload?: {
    nodeCode: string;
    assumedEnergy: number;
    can: {
      goToList: boolean;
    };
    stationsSeries: {
      total: number;
      failures: number;
      offline: number;
      online: number;
      notInOperation: number;
    };
    connectorsSeries: {
      total: number;
      available: number;
      charging: number;
      failure: number;
      occupied: number;
      unavailable: number;
      unknown: number;
    };
  };
}

type FilterParameters = {
  clientCode?: string;
  nodes?: string[];
};

export default function ChargingStationsOverviewPage() {
  const { _t } = useBetterTranslate('charging-stations-overview-page');

  const [panelModels, setPanelModels] = useState<PanelModelItem[]>([]);

  const navigate = useBetterNavigate<FilterParameters>();

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, {});

  const setFilterParams = (filter: FilterParameters) => {
    const { ...params } = filter;
    _setInnerFilterParams({ ...params });
  };

  const fetchPanelModels = async (code: string, areaCodes: string[]) => {
    const promises = areaCodes.map((c) => {
      return api.stationsSummary.getSummary({ client: code, hierarchyNodeCodes: [c] });
    });

    const responses = await Promise.all(promises);
    const results = responses.map((r) => r.data);

    const panels = results.map((r): PanelModelItem => {
      return {
        nodeCode: r.clientContext.selectedNodes[0].code,
        expanded: false,
        payload: {
          nodeCode: r.clientContext.selectedNodes[0].code,
          assumedEnergy: r.summary.stationsAssumedEnergy,
          can: {
            goToList: r.summary.can.goToList,
          },
          connectorsSeries: {
            total:
              r.summary.connectorsAvailable +
              r.summary.connectorsCharging +
              r.summary.connectorsFailure +
              r.summary.connectorsUnavailable +
              r.summary.connectorsUnknown +
              r.summary.connectorsOccupied,
            available: r.summary.connectorsAvailable,
            charging: r.summary.connectorsCharging,
            occupied: r.summary.connectorsOccupied,
            failure: r.summary.connectorsFailure,
            unavailable: r.summary.connectorsUnavailable,
            unknown: r.summary.connectorsUnknown,
          },
          stationsSeries: {
            total: r.summary.stationsFailure + r.summary.stationsOffline + r.summary.stationsOnline + r.summary.stationsNotInOperation,
            failures: r.summary.stationsFailure,
            offline: r.summary.stationsOffline,
            online: r.summary.stationsOnline,
            notInOperation: r.summary.stationsNotInOperation,
          },
        },
      };
    });
    setPanelModels(panels);
  };

  const onToggleExpand = (panelModel: PanelModelItem) => {
    panelModel.expanded = !panelModel.expanded;
    setPanelModels([...panelModels]);
  };

  const onToggleFoldAll = () => {
    const expandAll = panelModels.filter((p) => p.expanded === false).length > 0;
    for (let model of panelModels) {
      model.expanded = expandAll;
    }
    setPanelModels([...panelModels]);
  };

  const navigateToEnergyPage = () => {
    navigate(`/locations/energy/${filterParams.clientCode}`, { nodes: filterParams.nodes });
  };

  const [mainModel, fetchingMainModel, errMainModel] = useApi(
    {
      call: async (clientCode?: string, hierarchyNodeCodes?: string[]) => {
        if (!clientCode) return undefined;
        return await api.stationsSummary.getSummary({
          client: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
        });
      },
      map: (data) => {
        if (!data) return data;
        const nodes2Fetch = data.clientContext.selectedHierarchy.filter((c) => c.type === 'area').map((item) => item.code);
        const panelItems = nodes2Fetch.map((item): PanelModelItem => ({ nodeCode: item, expanded: true, payload: undefined }));
        setPanelModels(panelItems);
        fetchPanelModels(data.clientContext.code, nodes2Fetch);
        // fetch sub areas />

        const stationsTotal = data.summary.stationsOnline + data.summary.stationsOffline + data.summary.stationsFailure + data.summary.stationsNotInOperation;
        const connectorsTotal =
          data.summary.connectorsAvailable +
          data.summary.connectorsCharging +
          data.summary.connectorsUnavailable +
          data.summary.connectorsUnknown +
          data.summary.connectorsFailure +
          data.summary.connectorsOccupied;
        const totalEnergyConsumption = data.summary.stationsAssumedEnergy;
        return {
          can: {
            goToList: data.summary.can.goToList,
          },
          clientContext: data.clientContext,
          totalEnergyConsumption: totalEnergyConsumption,
          stationsSeries: {
            online: data.summary.stationsOnline,
            offline: data.summary.stationsOffline,
            failures: data.summary.stationsFailure,
            notInOperation: data.summary.stationsNotInOperation,
            total: stationsTotal,
          },
          connectorsSeries: {
            available: data.summary.connectorsAvailable,
            charging: data.summary.connectorsCharging,
            unavailable: data.summary.connectorsUnavailable,
            unknown: data.summary.connectorsUnknown,
            occupied: data.summary.connectorsOccupied,
            failure: data.summary.connectorsFailure,
            total: connectorsTotal,
          },
        };
      },
    },

    filterParams.clientCode,
    filterParams.nodes
  );

  const navigateToStationListPage = (filter: { online?: number; offline?: number; failure?: number; notInOperation?: number; nodes?: string[] }) => {
    const link = `/infrastructure/stations/${filterParams.clientCode}`;
    navigate(link, filter);
  };
  const navigateToChargepointListPage = (filter: {
    available?: number;
    charging?: number;
    occupied?: number;
    failure?: number;
    unavailable?: number;
    unknown?: number;
    nodes?: string[];
  }) => {
    const link = `/infrastructure/chargepoints/${filterParams.clientCode}`;
    navigate(link, filter);
  };

  return (
    <Page
      breadCrumb={[{ title: _t('Standort Übersicht'), href: '/location/status', active: true }]}
      fetching={fetchingMainModel}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={errMainModel}
      outOfPlaceHolder={
        <>
          <OrgHierarchyPicker
            selectMode='node'
            clientContext={mainModel?.clientContext}
            onNodeSelected={(clientCode, selectedCodes) => {
              if (clientCode !== mainModel?.clientContext?.code) {
                navigate(`/locations/status/${clientCode}`, { nodes: selectedCodes });
              } else {
                setFilterParams({ nodes: selectedCodes });
              }
            }}
            {...createClientSearchProps(mainModel?.clientContext)}
            selectedNodes={filterParams.nodes}
          />
        </>
      }
      // toolbar={
      // <div>hello</div>
      // <div className={classNames(styles.toolbar)}>
      //   {result?.canAddClient &&
      //     <button className={styles.addBtn} type="button" onClick={onAddClick}>
      //       <PlusIco /> Neu
      //     </button>
      //   }
      // </div>

      // }
    >
      {mainModel && (
        <FormCard phoneSize='full'>
          <FormCardBody>
            <TabPanel>
              <Tabs>
                <Tab active={true} txt={_t('Status')} ico={<ChartIco />}></Tab>
                <Tab txt={_t('Energie')} ico={<BoltIco />} onClick={navigateToEnergyPage}></Tab>
              </Tabs>
              <TabContent active={true}>
                <StationStatusSummaryPanel
                  connectors={{
                    overallTotal: mainModel.connectorsSeries.total,
                    total: mainModel.connectorsSeries.total,
                    available: mainModel.connectorsSeries.available,
                    charging: mainModel.connectorsSeries.charging,
                    occupied: mainModel.connectorsSeries.occupied,
                    failure: mainModel.connectorsSeries.failure,
                    unavailable: mainModel.connectorsSeries.unavailable,
                    unknown: mainModel.connectorsSeries.unknown,

                    onAvailableClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ available: 1, nodes: filterParams.nodes }) : undefined,
                    onChargingClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ charging: 1, nodes: filterParams.nodes }) : undefined,
                    onFailureClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ failure: 1, nodes: filterParams.nodes }) : undefined,
                    onOccupiedClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ occupied: 1, nodes: filterParams.nodes }) : undefined,
                    onUnavailableClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ unavailable: 1, nodes: filterParams.nodes }) : undefined,
                    onUnknownClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ unknown: 1, nodes: filterParams.nodes }) : undefined,
                  }}
                  stations={{
                    overallTotal: mainModel.stationsSeries.total,
                    total: mainModel.stationsSeries.total,
                    failures: mainModel.stationsSeries.failures,
                    offline: mainModel.stationsSeries.offline,
                    online: mainModel.stationsSeries.online,
                    notInOperation: mainModel.stationsSeries.notInOperation,

                    onOnlineClicked: mainModel.can.goToList ? () => navigateToStationListPage({ online: 1 }) : undefined,
                    onOfflineClicked: mainModel.can.goToList ? () => navigateToStationListPage({ offline: 1 }) : undefined,
                    onFailureClicked: mainModel.can.goToList ? () => navigateToStationListPage({ failure: 1 }) : undefined,
                    onNotInOperationClicked: mainModel.can.goToList ? () => navigateToStationListPage({ notInOperation: 1 }) : undefined,
                  }}
                />
                <div className={styles.totalEnergyConsumptionPill}>
                  {_t('Aktuelle Ladeleistung')}: {formatter.formatNumber(Math.round(mainModel.totalEnergyConsumption))} kW
                </div>
              </TabContent>

              <TabContent>...</TabContent>
            </TabPanel>

            {/* {mainModel?.summary.connectorsAvailable} */}
          </FormCardBody>
        </FormCard>
      )}

      {mainModel && panelModels && (
        <>
          <FormCard phoneSize='full'>
            <FormCardBody>
              <button type='button' className={classNames(styles.toggleFoldAll)} onClick={onToggleFoldAll}>
                {panelModels.filter((p) => p.expanded === false).length > 0 && (
                  <>
                    <ExpandedIco />
                    {_t('Alle Bereiche öffnen')}
                  </>
                )}
                {panelModels.filter((p) => p.expanded === false).length <= 0 && (
                  <>
                    <CollapsedIco />
                    {_t('Alle Bereiche schließen')}
                  </>
                )}
              </button>
            </FormCardBody>
          </FormCard>

          {panelModels.map((panelModel, idx) => {
            return (
              <FormCard key={idx} phoneSize='full'>
                <FormCardBody>
                  {!panelModel.payload && <ListingCardPlaceholder fullWidth={true} cardSize={2} cardNumber={1} />}
                  {panelModel.payload && (
                    <Accordion
                      expanded={panelModel.expanded}
                      headline={
                        <div className={classNames(styles.accordionHeadline)}>
                          <div className={styles.panelNodeHierarchy}>
                            <NodeHierarchyLabelInverted
                              hideArea={false}
                              hideClientRoot={true}
                              allNodes={[...mainModel.clientContext.accessableNodes, ...mainModel.clientContext.forbiddenParents]}
                              code={panelModel.payload.nodeCode}
                            />
                          </div>
                          <div className={classNames(styles.panelSummary, panelModel.expanded ? styles.noDisplay : undefined)}>
                            <div className={styles.pillAndNumber} title={_t('Online')}>
                              <Pill className={classNames(colorStyles.components.stationStatus.bg.online, styles.pill)}>&nbsp;</Pill>
                              <span>{panelModel.payload.stationsSeries.online}</span>
                            </div>
                            <div className={styles.pillAndNumber} title={_t('Offline')}>
                              <Pill className={classNames(colorStyles.components.stationStatus.bg.offline, styles.pill)}>&nbsp;</Pill>
                              <span>{panelModel.payload.stationsSeries.offline}</span>
                            </div>
                            <div className={styles.pillAndNumber} title={_t('Erroneous')}>
                              <Pill className={classNames(colorStyles.components.stationStatus.bg.failure, styles.pill)}>&nbsp;</Pill>
                              <span>{panelModel.payload.stationsSeries.failures}</span>
                            </div>
                            <div className={styles.pillAndNumber} title={_t('Not in operation')}>
                              <Pill className={classNames(colorStyles.components.stationStatus.bg.notInOperation, styles.pill)}>&nbsp;</Pill>
                              <span>{panelModel.payload.stationsSeries.notInOperation}</span>
                            </div>
                          </div>
                          <div className={classNames(styles.energyLbl)}>
                            <div className={styles.currentCharge}>
                              {_t('Aktuelle Ladeleistung')} {formatter.formatNumber(Math.round(panelModel.payload.assumedEnergy))} kW
                            </div>
                          </div>
                        </div>
                      }
                      onExpand={() => onToggleExpand(panelModel)}
                    >
                      <StationStatusSummaryPanel
                        connectors={{
                          overallTotal: mainModel.connectorsSeries.total,
                          total: panelModel.payload.connectorsSeries.total,
                          available: panelModel.payload.connectorsSeries.available,
                          charging: panelModel.payload.connectorsSeries.charging,
                          occupied: panelModel.payload.connectorsSeries.occupied,
                          failure: panelModel.payload.connectorsSeries.failure,
                          unavailable: panelModel.payload.connectorsSeries.unavailable,
                          unknown: panelModel.payload.connectorsSeries.unknown,

                          onAvailableClicked: panelModel.payload.can.goToList ? () => navigateToChargepointListPage({ available: 1, nodes: [panelModel.nodeCode] }) : undefined,
                          onChargingClicked: panelModel.payload.can.goToList ? () => navigateToChargepointListPage({ charging: 1, nodes: [panelModel.nodeCode] }) : undefined,
                          onFailureClicked: panelModel.payload.can.goToList ? () => navigateToChargepointListPage({ failure: 1, nodes: [panelModel.nodeCode] }) : undefined,
                          onOccupiedClicked: panelModel.payload.can.goToList ? () => navigateToChargepointListPage({ occupied: 1, nodes: [panelModel.nodeCode] }) : undefined,
                          onUnavailableClicked: panelModel.payload.can.goToList ? () => navigateToChargepointListPage({ unavailable: 1, nodes: [panelModel.nodeCode] }) : undefined,
                          onUnknownClicked: panelModel.payload.can.goToList ? () => navigateToChargepointListPage({ unknown: 1, nodes: [panelModel.nodeCode] }) : undefined,
                        }}
                        stations={{
                          overallTotal: mainModel.stationsSeries.total,
                          total: panelModel.payload.stationsSeries.total,
                          failures: panelModel.payload.stationsSeries.failures,
                          offline: panelModel.payload.stationsSeries.offline,
                          online: panelModel.payload.stationsSeries.online,
                          notInOperation: panelModel.payload.stationsSeries.notInOperation,

                          onOnlineClicked: panelModel.payload.can.goToList ? () => navigateToStationListPage({ online: 1, nodes: [panelModel.nodeCode] }) : undefined,
                          onOfflineClicked: panelModel.payload.can.goToList ? () => navigateToStationListPage({ offline: 1, nodes: [panelModel.nodeCode] }) : undefined,
                          onFailureClicked: panelModel.payload.can.goToList ? () => navigateToStationListPage({ failure: 1, nodes: [panelModel.nodeCode] }) : undefined,
                          onNotInOperationClicked: panelModel.payload.can.goToList
                            ? () => navigateToStationListPage({ notInOperation: 1, nodes: [panelModel.nodeCode] })
                            : undefined,
                        }}
                      />
                    </Accordion>
                  )}
                </FormCardBody>
              </FormCard>
            );
          })}
        </>
      )}
    </Page>
  );
}
