import classNames from 'classnames';
import { MouseEvent, useEffect, useState } from 'react';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-use';
import { ReactComponent as DownloadIco } from '../assets/arrow-down-with-bracket.svg';
import { ReactComponent as LogoutIco } from '../assets/arrow-right-with-bracket.svg';
import { ReactComponent as CheckIco } from '../assets/check.svg';
import { ReactComponent as ChevronIco } from '../assets/chevron-left.svg';
import { ReactComponent as CloseIco } from '../assets/close.svg';
import { ReactComponent as GlobeIco } from '../assets/globe.svg';
import { ReactComponent as HamburgerIco } from '../assets/hamburger.svg';
import MainLogoLg from '../assets/main-logo-sm-title.png';
import { ReactComponent as OpenLinkIco } from '../assets/open-link.svg';
import api from '../services/api';
import { IUser, useAuth } from '../utils/AuthProvider';
import ifTrue from '../utils/class-name';
import useBetterTranslate, { getSupportedLanaguages } from '../utils/translation-utils';
import EditPoup from './edit-popup';
import styles from './main-menu-desktop.module.scss';
import { MenuItem, SWITCH_APP_HREF } from './menu-types';
import UserAvatar from './user-avatar';

export default function MainMenuDesktop(props: { className?: string; items: MenuItem[]; profile: IUser }) {
  const { pathname } = useLocation();
  const [selectedItem, setSelectedItem] = useState<MenuItem>();
  const { refreshProfile, logout } = useAuth();
  const { _t } = useBetterTranslate('main-menu-desktop');
  const [mdMenuExpanded, setMdMenuExpanded] = useState(false);
  const [lgMenuExpanded, setLgMenuExpanded] = useState(false);
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
  const [showAppSwitcher, setShowAppSwitcher] = useState(false);
  // const navigate = useNavigate();

  const MD_MAX_MENU_ITEMS = 5;
  const LG_MAX_MENU_ITEMS = 7;
  // let mdMenuItems: MenuItem[] = [];
  // if (props.items.length > MD_MAX_MENU_ITEMS) {
  //   mdMenuItems = props.items.slice(MD_MAX_MENU_ITEMS - 1, props.items.length);
  // }

  const collapseHelper = {
    isOutOfrange: (item: MenuItem, maxItems: number) => {
      if (props.items.length <= maxItems) return false;
      else return props.items.indexOf(item) >= maxItems - 1;
    },

    isActive: (item: MenuItem) => {
      return item === selectedItem;
    },
    hideOn: (item: MenuItem, maxItems: number) => {
      // items which are active should always be shown
      if (collapseHelper.isActive(item)) return false;
      // out of range (not active items should be shown)
      if (collapseHelper.isOutOfrange(item, maxItems)) return true;

      // if there is an outOfRange item 'active' than the last 'in range' item should be hidden
      // -------
      // no selected
      if (!selectedItem) return false;
      // selected exist but is not out of range
      if (!collapseHelper.isOutOfrange(selectedItem, maxItems)) return false;

      const itemIdx = props.items.indexOf(item);
      // there is an active item which is usually collapsed
      // this item looks to be the last "not collapsed one"
      // so we have to collapse
      if (itemIdx === maxItems - 2) return true;
      return false;
    },

    hasAny: (maxItems: number) => {
      const total = props.items.filter((item) => collapseHelper.isOutOfrange(item, maxItems)).length;
      return total > 0;
    },
  };
  // if (mdMenuItems.length > 0) {
  //   // debugger;
  //   const item2Check = props.items[4];
  //   console.log(mdHelpers.isCollapsedItem(item2Check), item2Check, props.items.indexOf(item2Check));
  // }

  const browserLang = props.profile.preferences.languageCode?.toUpperCase() || 'EN';

  const { show: showLangPicker } = useContextMenu({
    id: 'language-picker',
  });
  const { show: showProfilePicker } = useContextMenu({
    id: 'user-profile-context',
  });

  const { show: showMdMoreMenuItems } = useContextMenu({
    id: 'md-more-menu-context',
  });
  const { show: showLgMoreMenuItems } = useContextMenu({
    id: 'lg-more-menu-context',
  });
  const { show: showMobileMenu } = useContextMenu({
    id: 'mobile-menu',
  });

  const onWantChangeLanguage = async (lang: string) => {
    await api.profile.changeLanguage(lang);
    await refreshProfile();
    window.location.reload();
  };

  useEffect(() => {
    const sortedItems = [...props.items].sort((a, b) => (a.href.length < b.href.length ? 1 : -1));
    const potentiallySelected = sortedItems.find((item) => {
      const parents = item.href.split('/').filter((h) => !!h);
      if (parents.length <= 0) return false;
      const parent = `/${parents[0]}`;
      return (pathname || '').startsWith(parent);
    });
    if (potentiallySelected) {
      setSelectedItem(potentiallySelected);
    }
  }, [pathname, props.items]);

  const onLogoutClick = () => {
    logout();
  };

  const onShowManualClick = (languageCode: string) => {
    // If one of the manual file names gets updated, also update the mailjet template in frontend api project!
    if (languageCode === 'de') {
      const fileName = encodeURIComponent('TE-EVC-Cockpit_Benutzerhandbuch_aktuell.pdf');
      window.open(`/${fileName}`, '_blank');
    }
  };

  const environment = props?.profile?.preferences?.environment;
  // const environment = 's';

  const renderLink = (item: MenuItem, cls?: string) => {
    const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
      if (!item.href.endsWith(SWITCH_APP_HREF)) return;

      e.preventDefault();
      setShowAppSwitcher(true);
    };

    return (
      <Link title={item.href} key={item.href} to={item.href} className={classNames(cls)} onClick={onClick} data-cy={item.dataCy}>
        <span>{item.title}</span>
      </Link>
    );
  };

  const goToKiosk = () => {
    if (!props.profile.preferences.views.kiosk.visible) {
      console.error(`kiosk view should not be visible`);
      return;
    }
    if (!props.profile.preferences.views.kiosk.loginUrl) {
      console.error(`kiosk frontend URL not given`);
      return;
    }

    window.open(props.profile.preferences.views.kiosk.loginUrl, '_blank')?.focus();
  };

  return (
    <div className={classNames(styles.root, props.className, environment === 's' ? styles.devDistinctionBorder : '')}>
      <EditPoup className={styles.appSwitchPopup} open={showAppSwitcher} title=' ' onClose={() => setShowAppSwitcher(false)} showCancel={false}>
        <section>
          <h3>{_t('Select the module you want to switch to')}</h3>
          <h6>{_t('The targeted module will open in a new tab')}</h6>
          <div className={styles.tiles}>
            <div className={classNames(styles.tile, styles.changable)} onClick={() => goToKiosk()}>
              <div className={styles.body}>
                <div className={styles.caption}>
                  <span>Switch to</span>
                  <b>Kiosk</b>
                </div>
                <div className={styles.ico}>
                  <OpenLinkIco />
                </div>
              </div>
              {/* <hr /> */}
              {/* <div className={styles.footer}>
                <span>What is Kiosk for?</span>
              </div> */}
            </div>

            <div className={styles.tile}>
              <div className={styles.body}>
                <div className={styles.caption}>
                  <span>Currently on</span>
                  <b>Cockpit</b>
                </div>
                <div className={styles.ico}>
                  <CheckIco />
                </div>
              </div>
              {/* <hr />
              <div className={styles.footer}>
                <span>What is Cockpit for?</span>
              </div> */}
            </div>
          </div>
        </section>
      </EditPoup>
      <div className={classNames(styles.logoCol)}>
        <img alt='Charging Solution Platform' src={MainLogoLg} />
      </div>

      <div className={classNames(styles.nav)}>
        {props.items.map((item, i) => {
          const cls = {
            [styles.link]: true,
            [styles.hideOnMd]: collapseHelper.hideOn(item, MD_MAX_MENU_ITEMS),
            [styles.hideOnLg]: collapseHelper.hideOn(item, LG_MAX_MENU_ITEMS),
            [styles.active]: selectedItem === item,
          };
          return renderLink(item, classNames(cls));
        })}
        {collapseHelper.hasAny(MD_MAX_MENU_ITEMS) && (
          <button
            type='button'
            key={'md-more'}
            onClick={(ev) => {
              const rect = ev.currentTarget.getBoundingClientRect();
              showMdMoreMenuItems(ev, { position: { x: rect.left, y: rect.bottom } });
            }}
            className={classNames(styles.link, styles.mdMoreLink, ifTrue(styles.moreLinkOpen, mdMenuExpanded))}
          >
            <span>
              {_t('Mehr')} <ChevronIco />
            </span>
          </button>
        )}
        {collapseHelper.hasAny(LG_MAX_MENU_ITEMS) && (
          <button
            type='button'
            key={'lg-more'}
            onClick={(ev) => {
              const rect = ev.currentTarget.getBoundingClientRect();
              showLgMoreMenuItems(ev, { position: { x: rect.left, y: rect.bottom } });
            }}
            className={classNames(styles.link, styles.lgMoreLink, ifTrue(styles.moreLinkOpen, lgMenuExpanded))}
          >
            <span>
              {_t('Mehr')} <ChevronIco />
            </span>
          </button>
        )}
      </div>
      <div className={classNames(styles.hamburgerCol)}>
        <button
          type='button'
          className={styles.icoBtn}
          onClick={(ev) => {
            const rect = ev.currentTarget.getBoundingClientRect();
            showMobileMenu(ev, { position: { x: rect.left, y: rect.bottom + 10 } });
          }}
        >
          {!mobileMenuExpanded && <HamburgerIco />}
          {mobileMenuExpanded && <CloseIco />}
        </button>
      </div>
      <div className={classNames(styles.langCol)}>
        <button
          data-cy='mnu_lang'
          type='button'
          className={styles.icoBtn}
          onClick={(ev) => {
            const rect = ev.currentTarget.getBoundingClientRect();
            showLangPicker(ev, { position: { x: rect.left, y: rect.bottom + 10 } });
          }}
        >
          <GlobeIco />
          <span>{browserLang}</span>
        </button>
      </div>
      <div className={classNames(styles.usrCol)}>
        <span
          data-cy='mnu_profile'
          onClick={(ev) => {
            const rect = ev.currentTarget.getBoundingClientRect();
            showProfilePicker(ev, { position: { x: rect.left, y: rect.bottom + 10 } });
          }}
        >
          <UserAvatar className={styles.avatar} round={true} size={'40px'} name={props.profile.name} mail={props.profile.mail} />
        </span>
      </div>

      <Menu className={styles.contextMenu} id={`language-picker`}>
        {getSupportedLanaguages().map((v) => {
          return (
            <Item key={v} onClick={() => onWantChangeLanguage(v)}>
              {v.toUpperCase()}
            </Item>
          );
        })}
      </Menu>
      <Menu id={`user-profile-context`}>
        {props.profile.preferences.languageCode === 'de' && (
          <Item onClick={(ev) => onShowManualClick(props.profile.preferences?.languageCode || 'en')}>
            <span className={styles.userProfileIcon}>
              <DownloadIco />
            </span>
            {_t('Show user manual')}
          </Item>
        )}
        <Item onClick={(ev) => onLogoutClick()} data-cy='mnu_logout'>
          <span className={styles.userProfileIcon}>
            <LogoutIco />
          </span>
          {_t('Abmelden')}
        </Item>
      </Menu>

      <Menu className={styles.contextMenu} data-menu-at='md' id={`md-more-menu-context`} onShown={() => setMdMenuExpanded(true)} onHidden={() => setMdMenuExpanded(false)}>
        {props.items
          .filter((item) => collapseHelper.hideOn(item, MD_MAX_MENU_ITEMS))
          .map((item) => {
            return (
              <Item key={item.href} className={classNames(styles.contextLink)}>
                {renderLink(item)}
              </Item>
            );
          })}
      </Menu>

      <Menu className={styles.contextMenu} data-menu-at='lg' id={`lg-more-menu-context`} onShown={() => setLgMenuExpanded(true)} onHidden={() => setLgMenuExpanded(false)}>
        {props.items
          .filter((item) => collapseHelper.hideOn(item, LG_MAX_MENU_ITEMS))
          .map((item) => {
            return (
              <Item key={item.href} className={classNames(styles.contextLink)}>
                {renderLink(item)}
              </Item>
            );
          })}
      </Menu>

      <Menu className={styles.contextMenu} id={`mobile-menu`} onShown={() => setMobileMenuExpanded(true)} onHidden={() => setMobileMenuExpanded(false)}>
        {props.items.map((item) => {
          // const cls = {
          //   [styles.link]: true,
          //   [styles.linkHideOnMd]: !!mdMenuItems.find((mdItem) => mdItem.href === item.href),
          //   [styles.active]: selectedItem === item,
          // };
          return (
            <Item key={item.href} className={classNames(styles.contextLink)}>
              {renderLink(item)}
            </Item>
          );
        })}
        <Separator />
        <Item
          className={classNames(styles.contextLink, styles.contextLinkMuted)}
          key={'show-manual'}
          onClick={(ev) => onShowManualClick(props.profile.preferences?.languageCode || 'en')}
        >
          {_t('Show user manual')}
        </Item>
        <Item className={classNames(styles.contextLink, styles.contextLinkMuted)} key={'logout'} onClick={(ev) => onLogoutClick()}>
          {_t('Abmelden')}
        </Item>
      </Menu>
    </div>
  );
}
