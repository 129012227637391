import classNames from 'classnames';
import moment from 'moment';
import { useMemo, useState } from 'react';
import Accordion from '../../components/accordion/accordion';
import { ButtonPrimary } from '../../components/button';
import DataTable, { DataTableColumn } from '../../components/data-table/data-table';
import DateRangePicker from '../../components/date-range-picker';
import { FormCard, FormCardBody } from '../../components/form-card';
import IconExplanationSection from '../../components/icon-explanation-section';
import NumberRangeInput from '../../components/number-range/number-range';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import Page, { ListingCardPlaceholder } from '../../components/page';
import Paging from '../../components/paging';
import Pill from '../../components/pill';
import SessionsEnergyChart from '../../components/sessions-energy-chart/sessions-energy-chart';
import colorStyles from '../../components/style-utils';
import { RateTypeTag } from '../../components/tag';
import TagSelector from '../../components/tag-selector';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { usePageParams } from '../../hooks/usePageParams';
import {
  AggregationLabels,
  ApiError,
  ChargingSessionsEntityFilterDto,
  ExportCardColumn,
  ExportFileType,
  ExportSessionColumn,
  ExportStationColumn,
  GetSessionsEnergyConsumptionResultDto,
  HttpResponse,
  RateServiceType,
  RateServiceTypeLabel,
  SessionStatus,
  SharedClientContext,
} from '../../services/api-client/csp-api';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { dateRangeValidator, pagingValidator, validateArrayOfString, validatePositiveFloat, validateString } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import ExportSessionsPopup from './charging-sessions-export-popup';
import { ReactComponent as SpinnerIco } from '../../assets/spinner3.svg';
import styles from './charging-sessions-list-page.module.scss';

export type FilterParameters = {
  from?: string;
  to?: string;
  skip?: number | null;
  limit?: number | null;
  serviceType?: string[];
  energyFrom?: number;
  energyTo?: number;
  purchaseCostFrom?: number;
  purchaseCostTo?: number;
  totalCostFrom?: number;
  totalCostTo?: number;
  status?: string[];
  entity?: ChargingSessionsEntityFilterDto[];
  sortBy?: string;
  sortDesc?: string;
  clientCode?: string;
  nodes?: string[];
  public?: string;
};

export interface ChargingSessionItem {}
export interface NotFmChargingSessionItem {
  stationIsExternalHardware?: boolean;
  stationIsPublic?: boolean;
}

export interface ListChargingSessionsResult<T extends ChargingSessionItem> {
  skip: number;
  limit: number;
  sessions: T[];

  entityFilter: ChargingSessionsEntityFilterDto[];
  clientContext: SharedClientContext;
  allowedServiceTypes: RateServiceType[];
  canAggregate: boolean;
}

export interface ChargingSessionSumsResult {
  total: number;
}

export interface ExportSessionsConfig {
  showStations: boolean;
  showCards: boolean;
}

export interface ExportSessionsArgs {
  sessionFields: ExportSessionColumn[];
  stationFields: ExportStationColumn[];
  cardFields: ExportCardColumn[];
  fileType: ExportFileType;
  aggregateByStationLocation: boolean;
  aggregateByCardLocation: boolean;
  aggregateByStationId: boolean;
  aggregateByCardId: boolean;
  serviceTypeLabels: RateServiceTypeLabel[];
  aggregationLabels: AggregationLabels;
  timezone: string;
}

export function StatusSessionColumn(args: { status: SessionStatus; dataCy?: string }) {
  const { _t } = useBetterTranslate('session-status-column-value');

  return (
    <>
      {args.status && args.status === SessionStatus.Charging && (
        <div data-cy={args.dataCy} className={classNames(styles.marker, styles.active)}>
          <span>{_t('Laden')}</span>
        </div>
      )}
      {args.status && args.status === SessionStatus.Occupied && (
        <div data-cy={args.dataCy} className={classNames(styles.marker, styles.occupied)}>
          <span>{_t('Besetzt')}</span>
        </div>
      )}
      {args.status && args.status === SessionStatus.Closed && (
        <div data-cy={args.dataCy} className={classNames(styles.marker, styles.closed)}>
          <span>{_t('Beendet')}</span>
        </div>
      )}
    </>
  );
}

export function ChargingSessionsListPage<
  Session extends ChargingSessionItem,
  SessionsApiReturn extends ListChargingSessionsResult<Session>,
  SumsApiReturn extends ChargingSessionSumsResult
>(props: {
  pagePrefix: string;
  sortValues: string[];
  totalCostFilter: boolean;
  purchaseCostFilter: boolean;
  isStationPublicFilter: boolean;
  serviceTypes: RateServiceType[];
  sessionsApiCall: (arg: FilterParameters) => Promise<HttpResponse<SessionsApiReturn, ApiError> | undefined>;
  sumsApiCall: (arg: FilterParameters) => Promise<HttpResponse<SumsApiReturn, ApiError> | undefined>;
  energyApiCall: (clientCode?: string, from?: string, to?: string, nodes?: string[]) => Promise<HttpResponse<GetSessionsEnergyConsumptionResultDto, ApiError> | undefined>;
  searchEntityTags: (clientCode: string | undefined, txt: string) => Promise<ChargingSessionsEntityFilterDto[]>;
  exportFieldsConfig: ExportSessionsConfig;
  exportApiCall: (filterParams: FilterParameters, args: ExportSessionsArgs, cancelToken: string) => Promise<HttpResponse<Blob, ApiError> | undefined>;
  dataTableCols: (sumFetching: boolean, sessionsResp?: SessionsApiReturn, sumResp?: SumsApiReturn) => DataTableColumn<Session>[];
  rowAttributes: (r: Session) => object;
}) {
  const { _t } = useBetterTranslate('charging-sessions-list-page');
  const MAX_DATE = useMemo(() => moment().add(1, 'day'), []);
  const MIN_DATE = useMemo(() => moment().subtract(2, 'year'), []);
  const DATE_FORMAT = 'YYYY-MM-DD';
  const SORT_BY_VALUES = ['energy', 'startTime', 'rateServiceType', ...props.sortValues];

  const navigate = useBetterNavigate<FilterParameters>();
  const [expandEnergyPanel, setExpandEnergyPanel] = useState<boolean>(true);

  const sessionsValidators = {
    serviceTypes: validateArrayOfString(props.serviceTypes),
    sortBy: validateString(SORT_BY_VALUES),
    energyFrom: validatePositiveFloat,
    energyTo: validatePositiveFloat,
    status: validateArrayOfString(Object.values(SessionStatus)),
  };
  let costValidators = {};
  if (props.totalCostFilter) {
    costValidators = { ...costValidators, totalCostFrom: validatePositiveFloat, totalCostTo: validatePositiveFloat };
  }
  if (props.purchaseCostFilter) {
    costValidators = { ...costValidators, purchaseCostFrom: validatePositiveFloat, purchaseCostTo: validatePositiveFloat };
  }

  const validators = { ...dateRangeValidator(MIN_DATE, MAX_DATE), ...pagingValidator, ...sessionsValidators, ...costValidators };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>(
    {
      from: moment().subtract(30, 'day').format(DATE_FORMAT),
      to: moment().format(DATE_FORMAT),
    },
    validators
  );

  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };
  const [entityFilterOptions, setEntityFilterOptions] = useState<ChargingSessionsEntityFilterDto[]>([]);

  const [showExportPopup, setShowExportPopup] = useState<boolean>(false);

  const [hasExternalHardware, setHasExternalHardware] = useState(false);
  const [hasPublicStations, setHasPublicStations] = useState(false);

  const [sessionsResp, sessionsFetching, sessionsApiErr] = useApi(
    {
      call: async (filterParams: FilterParameters) => {
        const result = await props.sessionsApiCall(filterParams);
        setEntityFilterOptions(result?.data?.entityFilter || []);
        setHasExternalHardware(
          !!(result?.data?.sessions || []).find((s) => s.hasOwnProperty('stationIsExternalHardware') && (s as NotFmChargingSessionItem).stationIsExternalHardware === true)
        );
        setHasPublicStations(!!(result?.data?.sessions || []).find((s) => s.hasOwnProperty('stationIsPublic') && (s as NotFmChargingSessionItem).stationIsPublic === true));
        return result;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams
  );

  const [sumsResp, sumsFetching] = useApi(
    {
      call: async (filterParams: FilterParameters) => {
        const result = await props.sumsApiCall(filterParams);
        return result;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams
  );

  const [energyResp] = useApi(
    {
      call: async (clientCode?: string, from?: string, to?: string, nodes?: string[]) => {
        const result = await props.energyApiCall(clientCode, from, to, nodes);
        return result;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.from,
    filterParams.to,
    filterParams.nodes
  );

  const exportData = async (args: ExportSessionsArgs, cancelToken: string) => {
    if (!props.exportApiCall) {
      return;
    }

    const resp = await props.exportApiCall(filterParams, args, cancelToken);
    if (!resp) {
      return;
    }

    let filename = 'charging-sessions.xlsx';
    const disposition = resp.headers.get('content-disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(resp.data);
    link.download = filename;
    link.click();
  };

  const entityFilterName = (tagType: string) => {
    if (tagType === 'station') return _t('Ladestation');
    if (tagType === 'chargepoint') return _t('Ladepunkt');
    if (tagType === 'card') return _t('Ladekarte');
    if (tagType === 'cardLbl') return _t('Kartenbezeichnung');
    if (tagType === 'cardCMT') return _t('Kostenstelle');
    return '';
  };

  const sessionStatusFilterName = (tagType: SessionStatus) => {
    if (tagType === SessionStatus.Closed) return _t('Beendet');
    if (tagType === SessionStatus.Occupied) return _t('Besetzt');
    if (tagType === SessionStatus.Charging) return _t('Laden');
    return '';
  };

  return (
    <Page
      breadCrumb={[{ title: _t('Ladevorgänge'), href: `/${props.pagePrefix}-charging-sessions`, active: true }]}
      fetching={sessionsFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={sessionsApiErr}
      outOfPlaceHolder={
        <>
          <OrgHierarchyPicker
            selectMode='node'
            clientContext={sessionsResp?.clientContext}
            onNodeSelected={(clientCode, selectedCodes) => {
              if (clientCode !== sessionsResp?.clientContext?.code) {
                navigate(`/${props.pagePrefix}-charging-sessions/${clientCode}`, { nodes: selectedCodes });
              } else {
                setFilterParams({ nodes: selectedCodes });
              }
            }}
            {...createClientSearchProps(sessionsResp?.clientContext)}
            selectedNodes={filterParams.nodes}
          />

          <FormCard tabletSize='full'>
            <DateRangePicker
              className={styles.datePicker}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              selected={filterParams}
              onChange={(range) => setFilterParams({ ...range })}
              dateFormat={DATE_FORMAT}
            />
          </FormCard>

          <FormCard tabletSize='full'>
            <Accordion
              // className={styles.energyPanel}
              expanded={expandEnergyPanel}
              headline={<div>{_t('Geladene Energie')}</div>}
              onExpand={() => setExpandEnergyPanel(!expandEnergyPanel)}
            >
              <SessionsEnergyChart
                loading={!energyResp}
                showLegend={true}
                onClicked={(clickedServiceType) => {
                  setFilterParams({ serviceType: [clickedServiceType] });
                }}
                serviceTypes={props.serviceTypes}
                allowedServiceTypes={energyResp?.allowedServiceTypes}
                resolution={energyResp?.resolution}
                entries={energyResp?.entries}
                sum={energyResp?.sum}
                counts={energyResp?.count}
              />
            </Accordion>
          </FormCard>

          {(hasExternalHardware || hasPublicStations) && (
            <FormCard phoneSize='full'>
              <FormCardBody>
                <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />
              </FormCardBody>
            </FormCard>
          )}

          <FormCard className={styles.filterCard} tabletSize='full'>
            <div className={styles.tagSearchContainer}>
              <TagSelector<ChargingSessionsEntityFilterDto>
                fetchOptions={async (val): Promise<ChargingSessionsEntityFilterDto[]> => {
                  const findings = await props.searchEntityTags(sessionsResp?.clientContext?.code, val);
                  return findings;
                }}
                dataCy='search_input'
                classNames={styles.tagSearchInput}
                placeholder={_t('Suchen')}
                displayStyle={'search'}
                createOption={(val) => {
                  return (
                    <div className={classNames(styles.entityFilterOpt, styles[val.type])}>
                      <span>{val.title}</span>
                      <span className={styles.type}>{entityFilterName(val.type)}</span>
                    </div>
                  );
                }}
                createTag={(val) => {
                  return <div className={classNames(styles.entityFilterTag, styles[val.type])}>{val.title}</div>;
                }}
                // set always to empty list
                // we will grab the values and show them below and not inside of the input
                selectedValues={[]}
                onChanged={(values) => {
                  //check for duplicated
                  const uniqueValues = values.filter((x) => entityFilterOptions.find((y) => y.type === x.type && y.id === x.id) === undefined);
                  if (uniqueValues.length > 0) {
                    const allValues = [...uniqueValues, ...entityFilterOptions];
                    setFilterParams({ entity: allValues });
                    setEntityFilterOptions(allValues);
                  }
                }}
                removeBtnClass={() => styles.tagRmBtn}
              />
            </div>

            <NumberRangeInput
              placeHolder={_t('Geladene Energie')}
              dataCy='charged_filter'
              unit='kWh'
              onChanged={({ from, to }) => setFilterParams({ energyFrom: from, energyTo: to })}
              from={filterParams.energyFrom}
              to={filterParams.energyTo}
              maxFrom={1000}
              maxTo={1000}
              valueMaxFactionalDigits={0}
              placeHolderMaxFactionalDigits={0}
              commitBtnTxt={_t('Ergebnisse anzeigen')}
            />

            {props.purchaseCostFilter && (
              <NumberRangeInput
                placeHolder={_t('Ladeerstattung')}
                dataCy='refund_filter'
                unit='€'
                onChanged={({ from, to }) => setFilterParams({ purchaseCostFrom: from, purchaseCostTo: to })}
                from={filterParams.purchaseCostFrom}
                to={filterParams.purchaseCostTo}
                maxFrom={1000}
                maxTo={1000}
                valueMaxFactionalDigits={0}
                placeHolderMaxFactionalDigits={0}
                commitBtnTxt={_t('Ergebnisse anzeigen')}
              />
            )}

            {props.totalCostFilter && (
              <NumberRangeInput
                placeHolder={_t('Ladekosten')}
                dataCy='cost_filter'
                unit='€'
                onChanged={({ from, to }) => setFilterParams({ totalCostFrom: from, totalCostTo: to })}
                from={filterParams.totalCostFrom}
                to={filterParams.totalCostTo}
                maxFrom={1000}
                maxTo={1000}
                valueMaxFactionalDigits={0}
                placeHolderMaxFactionalDigits={0}
                commitBtnTxt={_t('Ergebnisse anzeigen')}
              />
            )}

            {props.isStationPublicFilter && (
              <Pill
                dataCy='pills_public_service'
                selectedClass={colorStyles.components.rateType.public}
                unselectedClass={colorStyles.components.rateTypeInvert.public}
                selected={filterParams.public === '1'}
                onClick={() => setFilterParams({ public: filterParams.public === '1' ? undefined : '1' })}
              >
                {_t('Public Service')}
              </Pill>
            )}

            <ButtonPrimary dataCy='export' ralign={true} disabled={!sessionsResp || !sumsResp || sumsResp.total === 0} onClick={() => setShowExportPopup(true)}>
              {_t('Exportieren')}
            </ButtonPrimary>
          </FormCard>

          {entityFilterOptions.length > 0 && (
            <FormCard tabletSize='full'>
              <div className={styles.tagSearchDisplay}>
                {entityFilterOptions.map((item) => {
                  const classMap = {
                    [styles.station]: item.type === 'station',
                    [styles.chargepoint]: item.type === 'chargepoint',
                    [styles.card]: item.type === 'card',
                    [styles.cardLbl]: item.type === 'cardLbl',
                    [styles.cardCMT]: item.type === 'cardCMT',
                  };
                  return (
                    <Pill
                      key={item.id}
                      dataCy={`pills_${item.id}`}
                      className={classNames(styles.appliedTag, classMap)}
                      onClick={() => {
                        const condition = (existing: ChargingSessionsEntityFilterDto) => existing.type !== item.type || existing.id !== item.id;
                        setFilterParams({ entity: filterParams.entity?.filter(condition) });
                        setEntityFilterOptions(entityFilterOptions.filter(condition));
                      }}
                    >
                      {item.title || ''}
                    </Pill>
                  );
                })}
              </div>
            </FormCard>
          )}

          <FormCard tabletSize='full'>
            <div className={styles.quickFilters}>
              <div className={styles.onGoingFilters}>
                {[SessionStatus.Closed, SessionStatus.Charging, SessionStatus.Occupied].map((enumValue) => {
                  const name = enumValue.toString();
                  // const selected = filterParams.serviceType?.includes(name);

                  const selected = filterParams.status?.includes(name);
                  const cls = {
                    [colorStyles.components.sessionStatus.charging]: selected && name === SessionStatus.Charging,
                    [colorStyles.components.sessionStatusInvert.charging]: !selected && name === SessionStatus.Charging,
                    [colorStyles.components.sessionStatus.occupied]: selected && name === SessionStatus.Occupied,
                    [colorStyles.components.sessionStatusInvert.occupied]: !selected && name === SessionStatus.Occupied,
                    [colorStyles.components.sessionStatus.closed]: selected && name === SessionStatus.Closed,
                    [colorStyles.components.sessionStatusInvert.closed]: !selected && name === SessionStatus.Closed,
                  };
                  return (
                    <Pill
                      selected={selected}
                      dataCy={`pills_${enumValue}`}
                      key={name}
                      className={classNames(styles.filter, cls)}
                      onClick={() => {
                        let status;
                        if (selected) {
                          status = filterParams.status?.filter((item) => item !== name);
                          if (status && status?.length === 0) {
                            status = undefined;
                          }
                        } else {
                          status = filterParams.status ? [...filterParams.status, name] : [name];
                        }
                        setFilterParams({ status });
                      }}
                    >
                      {sessionStatusFilterName(enumValue)}
                    </Pill>
                  );
                })}
              </div>

              <div className={styles.rateTypeFilters}>
                {sessionsResp &&
                  sessionsResp.allowedServiceTypes.map((enumValue) => {
                    const name = enumValue.toString();
                    const selected = filterParams.serviceType?.includes(name);

                    return (
                      <RateTypeTag
                        displayStyle='pill'
                        selected={selected}
                        key={name}
                        dataCy={`pills_${enumValue}`}
                        rateType={enumValue}
                        classNames={classNames(styles.filter)}
                        onClick={() => {
                          let serviceType;
                          if (selected) {
                            serviceType = filterParams.serviceType?.filter((item) => item !== name);
                            if (serviceType && serviceType?.length === 0) {
                              serviceType = undefined;
                            }
                          } else {
                            serviceType = filterParams.serviceType ? [...filterParams.serviceType, name] : [name];
                          }
                          setFilterParams({ serviceType });
                        }}
                      ></RateTypeTag>
                    );
                  })}
              </div>
            </div>
          </FormCard>
        </>
      }
    >
      <FormCard phoneSize='full'>
        <FormCardBody className={styles.gridCardBody}>
          <DataTable
            sticky={true}
            records={sessionsResp?.sessions || []}
            sorting={{
              handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
              col: filterParams.sortBy,
              desc: filterParams.sortDesc === '1',
            }}
            renderer={{
              cols: props.dataTableCols(sumsFetching, sessionsResp, sumsResp),
              rowAttributes: (r) => props.rowAttributes(r),
            }}
          />
        </FormCardBody>
      </FormCard>

      {sessionsResp &&
        sumsResp &&
        (sumsFetching ? (
          <SpinnerIco />
        ) : (
          <Paging
            skip={sessionsResp.skip}
            limit={sessionsResp.limit}
            total={sumsFetching ? -1 : sumsResp.total}
            onChange={(arg) => {
              setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
            }}
          />
        ))}

      <ExportSessionsPopup
        showStations={props.exportFieldsConfig.showStations}
        showCards={props.exportFieldsConfig.showCards}
        canAggregate={sessionsResp?.canAggregate || false}
        open={showExportPopup}
        sessionsCount={sumsResp?.total || 0}
        close={() => setShowExportPopup(false)}
        onSubmit={async (args: ExportSessionsArgs, cancelToken: string) => await exportData(args, cancelToken)}
      />
    </Page>
  );
}
