import classNames from 'classnames';
import moment from 'moment';
import { useMemo, useState } from 'react';
import DataTable from '../../components/data-table/data-table';
import DateRangePicker from '../../components/date-range-picker';
import { FormCard, FormCardBody } from '../../components/form-card';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import Page, { ListingCardPlaceholder } from '../../components/page';
import Paging from '../../components/paging';
import Pill from '../../components/pill';
import colorStyles from '../../components/style-utils';
import { RateTypeTag } from '../../components/tag';
import TagSelector from '../../components/tag-selector';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { createQueryString, usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { ChargingCardListItemDto, ChargingCardsEntityFilterDto, RateServiceType } from '../../services/api-client/csp-api';
import { endOfDay, getTimezone, startOfDay } from '../../utils/date';
import { formatEnergy } from '../../utils/format';

import { NavLink } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/info-v2.svg';
import SessionsEnergyChart from '../../components/sessions-energy-chart/sessions-energy-chart';
import { useAuth } from '../../utils/AuthProvider';
import { formatter } from '../../utils/localized-types';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { dateRangeValidator, hierarchyValidator, pagingValidator, validateArrayOfString, validateString } from '../../utils/queryParamValidators';
import { checkIfOneElementIsEqual } from '../../utils/tools';
import useBetterTranslate from '../../utils/translation-utils';
import uniqueLineIdentifier from '../../utils/unique-line-ident';
import SetCustomNamePopup from '../stations/custom-name-popup';
import styles from './charging-cards-list-page.module.scss';

export default function ChargingCardsListPage() {
  const { _t } = useBetterTranslate('charging-cards-list-page');
  const { user } = useAuth();

  const MAX_DATE = useMemo(() => moment().add(1, 'day'), []);
  const MIN_DATE = useMemo(() => moment().subtract(2, 'year'), []);
  const DATE_FORMAT = 'YYYY-MM-DD';
  const SERVICE_TYPE_ALLOWED_VALUES = [RateServiceType.WORK, RateServiceType.EMPLOYEE, RateServiceType.HOME, RateServiceType.UNDEFINED];
  const SORT_BY_VALUES = ['active', 'number', 'totalEnergy', 'ownerName', 'rateServiceType', 'rateSlug', 'comment', 'sessionServiceTypes', 'label'];

  const navigate = useBetterNavigate<FilterParameters>();

  type FilterParameters = {
    from?: string;
    to?: string;
    skip?: number | null;
    limit?: number | null;
    serviceType?: string[];
    active?: string;
    onGoing?: string;
    entity?: ChargingCardsEntityFilterDto[];
    sortBy?: string;
    sortDesc?: string;
    clientCode?: string;
    nodes?: string[];
  };

  const cardsValidators = {
    serviceType: validateArrayOfString(SERVICE_TYPE_ALLOWED_VALUES),
    sortBy: validateString(SORT_BY_VALUES),
  };
  const validators = { ...dateRangeValidator(MIN_DATE, MAX_DATE), ...pagingValidator, ...cardsValidators, ...hierarchyValidator };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>(
    {
      from: moment().subtract(30, 'day').format(DATE_FORMAT),
      to: moment().format(DATE_FORMAT),
    },
    validators
  );

  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };
  const [entityFilterOptions, setEntityFilterOptions] = useState<ChargingCardsEntityFilterDto[]>([]);

  const [cardsResp, cardsFetching, cardsApiErr] = useApi(
    {
      call: async (
        clientCode?: string,
        hierarchyNodeCodes?: string[],
        fromDate?: string,
        toDate?: string,
        skip?: number | null,
        limit?: number | null,
        serviceTypes?: any,
        active?: string,
        onGoing?: string,
        entities?: ChargingCardsEntityFilterDto[],
        sortBy?: any,
        sortDesc?: string
      ) => {
        if (!clientCode) return undefined;
        const resp = await api.chargingCards.list(
          {
            client: clientCode,
            hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
            limit: Number(limit || 20),
            skip: Number(skip || 0),
            from: startOfDay(fromDate),
            to: endOfDay(toDate),
            serviceTypes,
            active: active ? active === 'true' : undefined,
            entityFilter: entities || undefined,
            onGoing: onGoing ? onGoing === '1' : undefined,
            sortBy,
            sortDesc: sortDesc === '1',
          },
          { cancelToken: uniqueLineIdentifier() }
        );
        setEntityFilterOptions(resp?.data?.entityFilter);
        return resp;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.from,
    filterParams.to,
    filterParams.skip,
    filterParams.limit,
    filterParams.serviceType,
    filterParams.active,
    filterParams.onGoing,
    filterParams.entity,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  const [energyResp] = useApi(
    {
      call: async (clientCode?: string, hierarchyNodeCodes?: string[], fromDate?: string, toDate?: string) => {
        if (!clientCode) return undefined;

        const resp = await api.chargingCards.energyConsumption(
          {
            client: clientCode,
            hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
            from: startOfDay(fromDate),
            to: endOfDay(toDate),
            timezone: getTimezone(),
          },
          { cancelToken: uniqueLineIdentifier() }
        );
        return resp;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.from,
    filterParams.to
  );

  const searchTags = async (txt: string) => {
    if (!cardsResp?.clientContext?.code) return [];
    const entityFilterRes = await api.chargingCards.searchFilters({ q: txt, clientCode: cardsResp.clientContext.code }, { cancelToken: uniqueLineIdentifier() });
    return entityFilterRes.data;
  };

  const getSessionsPageLink = (sessionsPagePrefix: string, filterParameters: FilterParameters, number: string) => {
    const baseUrl = `${sessionsPagePrefix}-charging-sessions/${filterParameters.clientCode}`;
    const query = createQueryString({ from: filterParameters.from, to: filterParameters.to, entity: [{ id: number, type: 'card' }], sortBy: 'startTime', sortDesc: 1 });
    return `/${baseUrl}?${query}`;
  };

  const entityFilterName = (tagType: string) => {
    if (tagType === 'card') return _t('Ladekarte');
    if (tagType === 'cardLbl') return _t('Kartenbezeichnung');
    if (tagType === 'cardCMT') return _t('Kostenstelle');
    if (tagType === 'rate') return _t('Tarif');
    return '';
  };

  const [badgeCustomNamePopup, setBadgeCustomNamePopup] = useState<ChargingCardListItemDto | null>(null);

  return (
    <Page
      breadCrumb={[{ title: _t('LADEKARTEN'), href: '/charging-cards', active: true }]}
      fetching={cardsFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={cardsApiErr}
      outOfPlaceHolder={
        <>
          <OrgHierarchyPicker
            selectMode='node'
            clientContext={cardsResp?.clientContext}
            onNodeSelected={(clientCode, selectedCodes) => {
              if (clientCode !== cardsResp?.clientContext?.code) {
                navigate(`/charging-cards/${clientCode}`, { nodes: selectedCodes });
              } else {
                setFilterParams({ nodes: selectedCodes });
              }
            }}
            {...createClientSearchProps(cardsResp?.clientContext)}
            selectedNodes={filterParams.nodes}
          />

          <FormCard tabletSize='full'>
            <DateRangePicker
              className={styles.datePicker}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              selected={filterParams}
              onChange={(range) => setFilterParams({ ...range })}
              dateFormat={'YYYY-MM-DD'}
            />
          </FormCard>

          <SessionsEnergyChart
            loading={!energyResp}
            showLegend={true}
            showCardsCount={true}
            serviceTypes={SERVICE_TYPE_ALLOWED_VALUES}
            allowedServiceTypes={energyResp?.allowedServiceTypes}
            resolution={energyResp?.resolution}
            entries={energyResp?.entries}
            sum={energyResp?.sessionsSum}
            counts={energyResp?.sessionsCount}
            cardsCount={energyResp?.totalCardsCount}
            onClicked={(clickedServiceType) => {
              setFilterParams({ serviceType: [clickedServiceType] });
            }}
          />

          <FormCard className={styles.filterCard} tabletSize='full'>
            <div className={styles.tagSearchContainer}>
              <TagSelector<ChargingCardsEntityFilterDto>
                fetchOptions={async (val): Promise<ChargingCardsEntityFilterDto[]> => {
                  const findings = await searchTags(val);
                  return findings;
                }}
                dataCy='search_input'
                classNames={styles.tagSearchInput}
                placeholder={_t('Suchen')}
                displayStyle={'search'}
                createOption={(val) => {
                  return (
                    <div data-cy={'search_result_element'} className={classNames(styles.entityFilterOpt, styles[val.type])}>
                      <span>{val.title}</span>
                      <span className={styles.type}>{entityFilterName(val.type)}</span>
                    </div>
                  );
                }}
                createTag={(val) => {
                  return <div className={classNames(styles.entityFilterTag, styles[val.type])}>{val.title}</div>;
                }}
                // set always to empty list
                // we will grab the values and show them below and not inside of the input
                selectedValues={[]}
                onChanged={(values) => {
                  //check for duplicated
                  const uniqueValues = values.filter((x) => entityFilterOptions.find((y) => y.type === x.type && y.id === x.id) === undefined);
                  if (uniqueValues.length > 0) {
                    const allValues = [...uniqueValues, ...entityFilterOptions];
                    setFilterParams({ entity: allValues });
                    setEntityFilterOptions(allValues);
                  }
                }}
                removeBtnClass={() => styles.tagRmBtn}
              />
            </div>
            <div className={classNames(styles.filters, styles.activeQuickFilter)}>
              {[true, false].map((value) => {
                const selected = filterParams.active === value.toString();
                const cls = {
                  [colorStyles.components.activeInactive.active]: value && selected,
                  [colorStyles.components.activeInactiveInvert.active]: value && !selected,
                  [colorStyles.components.activeInactive.inactive]: !value && selected,
                  [colorStyles.components.activeInactiveInvert.inactive]: !value && !selected,
                };

                return (
                  <Pill
                    dataCy={`pills_${value ? 'active' : 'inactive'}`}
                    selected={selected}
                    key={value.toString()}
                    className={classNames(styles.filter, cls)}
                    onClick={() => {
                      const active = selected ? undefined : value.toString();
                      setFilterParams({ active });
                    }}
                  >
                    {value ? _t('Aktiv') : _t('Inaktiv')}
                  </Pill>
                );
              })}

              <Pill
                selected={filterParams.onGoing === '1'}
                dataCy={`pills_${'charging'}`}
                className={classNames(
                  styles.filter,
                  filterParams.onGoing === '1' ? colorStyles.components.sessionStatus.charging : colorStyles.components.sessionStatusInvert.charging
                )}
                onClick={() => {
                  setFilterParams({ onGoing: filterParams.onGoing === '1' ? undefined : '1' });
                }}
              >
                {_t('Laden')}
              </Pill>
            </div>

            <div className={classNames(styles.filters, styles.rateTypeFilters)}>
              {cardsResp &&
                cardsResp.allowedServiceTypes.map((enumValue) => {
                  const name = enumValue.toString();
                  const selected = filterParams.serviceType?.includes(name);

                  return (
                    <RateTypeTag
                      displayStyle='pill'
                      selected={selected}
                      dataCy={`pills_${enumValue}`}
                      key={name}
                      rateType={enumValue}
                      classNames={classNames(styles.filter)}
                      onClick={() => {
                        let serviceType;
                        if (selected) {
                          serviceType = filterParams.serviceType?.filter((item) => item !== name);
                          if (serviceType && serviceType?.length === 0) {
                            serviceType = undefined;
                          }
                        } else {
                          serviceType = filterParams.serviceType ? [...filterParams.serviceType, name] : [name];
                        }
                        setFilterParams({ serviceType });
                      }}
                    ></RateTypeTag>
                  );
                })}
            </div>
          </FormCard>
          <FormCard tabletSize='full'>
            <div className={styles.tagSearchDisplay}>
              {entityFilterOptions.map((item) => {
                return (
                  <Pill
                    key={item.id}
                    hasClose={true}
                    className={classNames(styles.appliedTag, styles[item.type])}
                    onClick={() => {
                      const condition = (existing: ChargingCardsEntityFilterDto) => existing.type !== item.type || existing.id !== item.id;
                      setFilterParams({ entity: filterParams.entity?.filter(condition) });
                      setEntityFilterOptions(entityFilterOptions.filter(condition));
                    }}
                  >
                    {item.title || ''}
                  </Pill>
                );
              })}
            </div>
          </FormCard>
        </>
      }
    >
      <Tooltip id='info-badge-label-tooltip' place='top' type='light' effect='solid' />
      <FormCard phoneSize='full'>
        <FormCardBody className={styles.gridCardBody}>
          <DataTable
            sticky={true}
            records={cardsResp?.cards || []}
            sorting={{
              handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
              col: filterParams.sortBy,
              desc: filterParams.sortDesc === '1',
            }}
            renderer={{
              rowAttributes: (record) => {
                return { 'data-cy': 'charging_record_element', 'data-cy-id': record.number };
              },
              cols: [
                {
                  headerCol: () => <div>{_t('Status')}</div>,
                  name: 'active',
                  sortable: true,
                  col: (record) => (
                    <div className={classNames(styles.statusCol, record.active ? styles.activeCol : styles.inactiveCol)}>{record.active ? _t('Aktiv') : _t('Inaktiv')}</div>
                  ),
                  headerClass: styles.statusCol,
                  sumClass: styles.statusCol,
                  colClass: (record) => classNames(styles.statusCol, record.active ? styles.activeRow : styles.inactiveRow),
                  width: '10%',
                  sumCol: () => (
                    <div className={classNames(styles.totalCol)}>
                      {formatter.formatNumber(cardsResp?.total || 0, user?.preferences.languageCode || 'en') || ''} {_t('Badges filtered')}
                    </div>
                  ),
                },
                {
                  headerCol: () => <div>{_t('Kartennummer')}</div>,
                  name: 'number',
                  sortable: true,
                  col: (record) => <div>{record.number === 'not-set' ? '' : record.number}</div>, // EVC-1745
                  width: '15%',
                },
                {
                  headerCol: () => <div>{_t('Karteninhaber')}</div>,
                  name: 'ownerName',
                  sortable: true,
                  col: (record) => <div data-cy='badge-owner'>{record.ownerName}</div>,
                  width: '20%',
                },
                {
                  headerCol: () => <div>{_t('Kostenstelle')}</div>,
                  name: 'comment',
                  sortable: true,
                  col: (record) => <div>{record.comment}</div>,
                  width: '10%',
                },
                {
                  headerCol: () => <div>{_t('Kartenbezeichnung')}</div>,
                  name: 'label',
                  sortable: true,
                  col: (record) => (
                    <>
                      <span data-cy='badge-label'>{record.label}</span>
                      {cardsResp?.can?.clientCardWorkEdit && record.rateServiceType === RateServiceType.WORK && !checkIfOneElementIsEqual(record.rateSlug, 'leasu') ? (
                        <button type='button' onClick={() => setBadgeCustomNamePopup(record)} className={classNames(styles.iconButton, styles.iconBorder)}>
                          <EditIcon className={styles.icon} />
                        </button>
                      ) : (
                        cardsResp?.can?.clientCardWorkEdit && (
                          <div
                            data-cy={'info-badge-label-tooltip'}
                            data-id='info-badge-label-tooltip'
                            data-for='info-badge-label-tooltip'
                            data-tip={_t('Only labels from badges with the service type WORK can be edited. Badges provided by leasing partners cannot be edited.')}
                            className={classNames(styles.iconButton)}
                          >
                            <InfoIcon className={classNames(styles.icon, styles.infoSize)} />
                          </div>
                        )
                      )}
                    </>
                  ),
                  width: '18%',
                },
                {
                  headerCol: () => <div>{_t('Standard Service')}</div>,
                  name: 'rateServiceType',
                  sortable: true,
                  col: (record) => <RateTypeTag dataCy='el_service' selected={true} rateType={record.rateServiceType} classNames={styles.serviceTypeCol} />,
                  width: '10%',
                },
                {
                  headerCol: () => <div>{_t('Genutzter Service + Energie (kWh)')}</div>,
                  name: 'totalEnergy',
                  sortable: true,
                  colClass: (record) => classNames(styles.onGoingCol, styles.header),
                  col: (record) => (
                    <div className={classNames(styles.onGoingCol, styles.content)}>
                      <div className={classNames(styles.serviceTypeEnergyCol, styles.data)}>
                        {record.serviceTypeEnergies.map((e, idx) => (
                          <div key={idx} className={styles.item}>
                            <RateTypeTag dataCy='el_usedService' selected={true} rateType={e.serviceType} classNames={styles.serviceType} />
                            <span className={styles.energy}>{formatEnergy(e.energy, user?.preferences.languageCode, 2)}</span>
                          </div>
                        ))}
                        {record.serviceTypeEnergies.length === 0 && (
                          <div className={styles.item}>
                            <span className={styles.energy}>{formatEnergy(record.totalEnergy, user?.preferences.languageCode, 2)}</span>
                          </div>
                        )}
                      </div>
                      {cardsResp && record.onGoingSessions > 0 && (
                        <NavLink className={classNames(styles.marker, styles.active)} to={getSessionsPageLink(cardsResp.sessionsPagePrefix, filterParams, record.number)}>
                          <span>{_t('Laden')}</span>
                        </NavLink>
                      )}
                      {cardsResp && record.onGoingSessions === 0 && <div className={styles.marker} />}
                    </div>
                  ),
                  sumCol: () => (
                    <div className={classNames(styles.onGoingCol, styles.header)}>
                      <span className={styles.energy}>{formatEnergy(cardsResp?.totalEnergy, user?.preferences.languageCode, 2)}</span>
                      <div className={styles.marker} />
                    </div>
                  ),
                  ralign: true,
                  width: '22%',
                },
              ],
            }}
          />
        </FormCardBody>
      </FormCard>

      <SetCustomNamePopup
        close={() => {
          setBadgeCustomNamePopup(null);
        }}
        onSubmit={async (label) => {
          if (badgeCustomNamePopup) {
            await api.chargingCards.changeLabel({
              badgeNumber: badgeCustomNamePopup.extId,
              label,
              client: filterParams.clientCode!,
              hierarchyNodeCodes: filterParams.nodes || [filterParams.clientCode!],
            });

            badgeCustomNamePopup.label = label;
          }
          setBadgeCustomNamePopup(null);
        }}
        open={!!badgeCustomNamePopup}
        title={_t('Edit badge label')}
        customName={badgeCustomNamePopup?.label}
      />

      {cardsResp && (
        <Paging
          skip={cardsResp.skip}
          limit={cardsResp.limit}
          total={cardsResp.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )}
    </Page>
  );
}
