import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Item, Menu, TriggerEvent, useContextMenu } from 'react-contexify';
import Button from '../../components/button';
import { ConfirmStep, FailedStep, MultistepPopup, ProgressStep, SearchItemStep, Step, SuccessStep, useMultistepPopup } from '../../components/edit-popup';
import RadioButton from '../../components/input-radio-button';
import SingleSelect from '../../components/select/single-select';
import TagSelector, { CardListEntry } from '../../components/tag-selector';
import { translate } from '../../i18n';
import { ChangeAvailabilityTypes } from '../../services/api-client/csp-api';
import { makeSafeCall } from '../../utils/make-safe-call';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './chargepoint-remote-actions.module.scss';

type CardOption = { id: string; label: string; extId: string };

export type ChargePointRemoteAction = 'reserve' | 'unlock' | 'startTransaction' | 'stopTransaction' | 'changeAvailability' | 'cancelReserve';

export type ChargePointRemoteActionProps = {
  refreshRequested: () => void;
  remoteReserve: {
    do: (cp: ChargePoint, card: CardOption, minutes: number) => Promise<boolean>;
    searchCards: (txt: string) => Promise<CardOption[]>;
  };
  remoteCancelReserve: {
    do: (cp: ChargePoint) => Promise<boolean>;
    searchCards: (txt: string) => Promise<CardOption[]>;
  };
  remoteStop: {
    do: (cp: ChargePoint) => Promise<boolean>;
  };
  remoteStart: {
    do: (cp: ChargePoint, card: CardOption) => Promise<boolean>;
    searchCards: (txt: string) => Promise<CardOption[]>;
  };
  remoteUnlock: {
    do: (cp: ChargePoint) => Promise<boolean>;
  };
  remoteChangeAvailability: {
    do: (cp: ChargePoint, newVal: ChangeAvailabilityTypes) => Promise<boolean>;
  };
};

export interface ChargePoint {
  connectorId: string;
  chargeBoxId: string;
  domainStatus: 'available' | 'charging' | 'notAvailable' | 'failure' | 'unknown' | 'occupied';
  domainStatusOfStation: 'online' | 'offline' | 'failure' | 'notInOperation';
  can: { remoteStartSession: boolean; remoteStopSession: boolean; remoteUnlock: boolean; remoteReserve: boolean; remoteChangeAvailability: boolean };
  evseId?: string;
}

type RemoteActionContextItem = {
  action: () => void;
  title: string;
};

export function RemoteChangeAvailabilityChargePoint(props: {
  //
  accept: (availabilityMode: ChangeAvailabilityTypes) => Promise<boolean>;
  onClose: () => void;
  show: boolean;
  isCharging: boolean;
  done: () => void;
}) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');
  const [availabilityMode, setAvailabilityMode] = useState<ChangeAvailabilityTypes>(ChangeAvailabilityTypes.Operative);

  const doRemoteAction = makeSafeCall(props.accept, false);
  const popup = useMultistepPopup({
    steps: ['confirm', 'pending', 'success', 'failed'],
    activeStep: 'confirm',
    onClose: props.onClose,
  });

  useEffect(() => {
    if (props.show && popup.isOpen) return;

    if (!props.show) popup.hide();
    else popup.show('confirm');
  }, [props.show, popup]);

  return (
    <MultistepPopup
      title={_t('Ladepunkt ent-/sperren')}
      {...popup.componentProps}
      steps={{
        confirm: (
          <ConfirmStep
            text={_t('Sind Sie sicher, dass Sie den Ladepunkt ent-/sperren wollen?')}
            decline={() => popup.hide()}
            accept={async () => {
              popup.goto('pending');
              const result = await doRemoteAction(availabilityMode);
              if (result) popup.goto('success');
              else popup.goto('failed');
              props.done();
            }}
            wanText={props.isCharging ? _t('⚠️ Aktive Ladevorgänge, werden durch diese Aktion abgebrochen.') : undefined}
            controls={
              <div className={styles.availabilityMode}>
                <RadioButton
                  disabled={false}
                  label={_t('entsperren')}
                  isSelected={availabilityMode === 'Operative'}
                  onChange={() => setAvailabilityMode(ChangeAvailabilityTypes.Operative)}
                />
                <RadioButton
                  disabled={false}
                  label={_t('sperren')}
                  isSelected={availabilityMode === 'Inoperative'}
                  onChange={() => setAvailabilityMode(ChangeAvailabilityTypes.Inoperative)}
                />
              </div>
            }
          />
        ),
        pending: <ProgressStep onClose={() => popup.hide()} />,
        success: (
          <SuccessStep
            text={availabilityMode === ChangeAvailabilityTypes.Inoperative ? _t('Ladepunkt wurde gesperrt') : _t('Ladepunkt wurde entsperrt')}
            onClose={() => popup.hide()}
          />
        ),
        failed: <FailedStep onClose={() => popup.hide()} />,
      }}
    />
  );
}

export function RemoteReservePopup(props: {
  accept: (card: CardOption, timeInMin: number) => Promise<boolean>;
  onClose: () => void;
  done: () => void;
  show: boolean;
  getCards: (input: string) => Promise<CardOption[]>;
}) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');
  const popup = useMultistepPopup({
    steps: ['confirm', 'pending', 'success', 'failed'],
    activeStep: 'confirm',
    onClose: props.onClose,
  });
  const doRemoteAction = makeSafeCall(props.accept, false);
  const [selectedCard, setSelectedCard] = useState<CardOption | null>(null);

  const reservationItmeValues = [
    { id: '15 min', value: 15 },
    { id: '30 min', value: 30 },
    { id: '45 min', value: 45 },
    { id: '1 std', value: 60 },
    { id: '2 std', value: 120 },
  ];
  const [selectedTime, setSelectedTime] = useState<{ id: string; value: number }>(reservationItmeValues[0]);

  useEffect(() => {
    if (props.show && popup.isOpen) return;

    if (!props.show) {
      popup.hide();
      setSelectedCard(null);
    } else {
      popup.show('confirm');
    }
  }, [props.show, popup]);

  const onUserWantsAccept = async () => {
    if (!selectedCard) {
      setUserInputErrorMessage(_t('Bitte wählen Sie ein Authentifizierungsmedium'));
      return;
    }

    popup.goto('pending');
    const success = await doRemoteAction(selectedCard, selectedTime.value);
    if (success) popup.goto('success');
    else popup.goto('failed');
    props.done();
  };
  const [userInputErrorMessage, setUserInputErrorMessage] = useState('');

  return (
    <MultistepPopup
      preventScroll={true}
      className={styles.reserveRoot}
      title={_t('Ladepunkt reservieren')}
      {...popup.componentProps}
      steps={{
        confirm: (
          <Step
            bodyClassName={classNames(styles.search)}
            actions={
              <>
                <Button onClick={props.onClose} kind='accent'>
                  {_t('Abbrechen')}
                </Button>
                <Button autoFocus={true} onClick={() => onUserWantsAccept()} kind='primary'>
                  {_t('Bestätigen')}
                </Button>
              </>
            }
          >
            <label className={styles.control}>
              <span>{_t('Kartennummer eingeben')}</span>
              <TagSelector
                placeholder={_t('Kartennummer o. Kartenbezeichnung')}
                classNames={classNames(styles.dropdown)}
                fetchOptions={props.getCards}
                selectedValues={[]}
                createOption={(card) => <CardListEntry card={card} />}
                createTag={() => <></>}
                onChanged={(items) => {
                  if (items.length > 0) {
                    setSelectedCard(items[0]);
                    setUserInputErrorMessage('');
                  } else {
                    setSelectedCard(null);
                  }
                }}
                displayStyle={'search'}
              />
            </label>

            {selectedCard && (
              <section className={classNames(styles.selection)}>
                <CardListEntry onRemove={() => setSelectedCard(null)} card={selectedCard} />
              </section>
            )}

            <label className={styles.control}>
              <span>{_t('Reservierungszeit wählen')}</span>
              <SingleSelect<{ id: string; value: number }>
                // placeholder={_t('Steckertyp')}
                options={reservationItmeValues}
                createOption={(opt) => <span>{opt.id}</span>}
                selectedValue={selectedTime}
                className={styles.dropdown}
                fetchOptions={async (txt) => {
                  if (!txt) return reservationItmeValues || [];
                  const results = reservationItmeValues.filter((item) => item.id.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                  return results;
                }}
                onChanged={(selected) => {
                  if (selected) {
                    setSelectedTime(selected);
                  }
                }}
                isClearable={false}
              />
            </label>

            {userInputErrorMessage && <label className={styles.error}>{userInputErrorMessage}</label>}
            {!userInputErrorMessage && <label>{_t('Ladepunkt ab jetzt für den gewählten Zeitraum reservieren?')}</label>}
          </Step>
        ),
        pending: <ProgressStep onClose={() => popup.hide()} />,
        success: <SuccessStep text={_t('Ladepunkt wurde reserviert')} onClose={() => popup.hide()} />,
        failed: <FailedStep text={_t('Prozess fehlgeschlagen')} onClose={() => popup.hide()} />,
      }}
    />
  );
}

export function RemoteStopChargingSession(props: {
  //
  accept: () => Promise<boolean>;
  onClose: () => void;
  show: boolean;
  done: () => void;
}) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');

  const doRemoteAction = makeSafeCall(props.accept, false);
  const popup = useMultistepPopup({
    steps: ['confirm', 'pending', 'success', 'failed'],
    activeStep: 'confirm',
    onClose: props.onClose,
  });

  useEffect(() => {
    if (props.show && popup.isOpen) return;

    if (!props.show) popup.hide();
    else popup.show('confirm');
  }, [props.show, popup]);

  return (
    <MultistepPopup
      preventScroll={true}
      title={_t('Ladevorgang stoppen')}
      {...popup.componentProps}
      steps={{
        confirm: (
          <ConfirmStep
            text={_t('Sind Sie sicher, dass Sie den Ladevorgang stoppen wollen?')}
            decline={() => popup.hide()}
            accept={async () => {
              popup.goto('pending');
              const result = await doRemoteAction();
              if (result) popup.goto('success');
              else popup.goto('failed');
              props.done();
            }}
          />
        ),
        pending: <ProgressStep onClose={() => popup.hide()} />,
        success: <SuccessStep text={_t('Ladevorgang wurde gestoppt')} onClose={() => popup.hide()} />,
        failed: <FailedStep text={_t('Prozess fehlgeschlagen')} onClose={() => popup.hide()} />,
      }}
    />
  );
}

export function RemoteCancelReserve(props: {
  //
  accept: () => Promise<boolean>;
  onClose: () => void;
  show: boolean;
  done: () => void;
}) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');

  const doRemoteAction = makeSafeCall(props.accept, false);
  const popup = useMultistepPopup({
    steps: ['confirm', 'pending', 'success', 'failed'],
    activeStep: 'confirm',
    onClose: props.onClose,
  });

  useEffect(() => {
    if (props.show && popup.isOpen) return;

    if (!props.show) popup.hide();
    else popup.show('confirm');
  }, [props.show, popup]);

  return (
    <MultistepPopup
      preventScroll={true}
      title={_t('Reservierung stornieren')}
      {...popup.componentProps}
      steps={{
        confirm: (
          <ConfirmStep
            text={_t('Wollen Sie die Reservierung dieses Ladepunkts stornieren?')}
            decline={() => popup.hide()}
            accept={async () => {
              popup.goto('pending');
              const result = await doRemoteAction();
              if (result) popup.goto('success');
              else popup.goto('failed');
              props.done();
            }}
          />
        ),
        pending: <ProgressStep onClose={() => popup.hide()} />,
        success: <SuccessStep text={_t('Reservierung wurde storniert')} onClose={() => popup.hide()} />,
        failed: <FailedStep text={_t('Prozess fehlgeschlagen')} onClose={() => popup.hide()} />,
      }}
    />
  );
}

export function RemoteStartChargingSession(props: {
  //
  accept: (card: CardOption) => Promise<boolean>;
  onClose: () => void;
  getCards: (input: string) => Promise<CardOption[]>;
  done: () => void;
  show: boolean;
}) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');
  const [selectedCard, setSelectedCard] = useState<CardOption | null>(null);

  const doRemoteAction = makeSafeCall(props.accept, false);
  const popup = useMultistepPopup({
    steps: ['confirm', 'pending', 'success', 'failed'],
    activeStep: 'confirm',
    onClose: props.onClose,
  });

  useEffect(() => {
    if (props.show && popup.isOpen) return;

    if (!props.show) {
      popup.hide();
      setSelectedCard(null);
    } else {
      popup.show('confirm');
    }
  }, [props.show, popup]);

  return (
    <MultistepPopup
      preventScroll={true}
      title={_t('Ladevorgang Starten')}
      {...popup.componentProps}
      steps={{
        confirm: (
          <SearchItemStep<CardOption>
            text={_t('Ladevorgang mit diesem Authentifikationsmedium starten?')}
            createOption={(card) => <CardListEntry card={card} />}
            fetchOptions={async (s) => await props.getCards(s)}
            onChanged={(items) => {
              setSelectedCard(items.length > 0 ? items[0] : null);
            }}
            renderSelection={() => {
              if (!selectedCard) return;
              return <CardListEntry onRemove={() => setSelectedCard(null)} card={selectedCard} />;
            }}
            decline={() => {
              setSelectedCard(null);
              popup.hide();
            }}
            accept={async () => {
              if (!selectedCard) return;

              popup.goto('pending');
              const result = await doRemoteAction(selectedCard);
              if (result) popup.goto('success');
              else popup.goto('failed');
              props.done();
            }}
          />
        ),
        pending: <ProgressStep onClose={() => popup.hide()} />,
        success: <SuccessStep text={_t('Ladevorgang wurde gestartet')} onClose={() => popup.hide()} />,
        failed: <FailedStep text={_t('Prozess fehlgeschlagen')} onClose={() => popup.hide()} />,
      }}
    />
  );
}

export function RemoteUnlock(props: { isCharging: boolean; accept: () => Promise<boolean>; onClose: () => void; show: boolean }) {
  const { _t } = useBetterTranslate('chargepoint-remote-actions');

  const doRemoteAction = makeSafeCall(props.accept, false);
  const popup = useMultistepPopup({
    steps: ['confirm', 'pending', 'success', 'failed'],
    activeStep: 'confirm',
    onClose: props.onClose,
  });

  useEffect(() => {
    if (props.show && popup.isOpen) return;

    if (!props.show) popup.hide();
    else popup.show('confirm');
  }, [props.show, popup]);

  return (
    <MultistepPopup
      preventScroll={true}
      title={_t('Konnektor freigeben')}
      {...popup.componentProps}
      steps={{
        confirm: (
          <ConfirmStep
            text={_t('Sind Sie sicher, dass Sie den Konnektor freigeben wollen?')}
            wanText={props.isCharging ? _t('⚠️ Aktive Ladevorgänge, werden durch diese Aktion abgebrochen.') : undefined}
            decline={() => popup.hide()}
            accept={async () => {
              popup.goto('pending');
              const result = await doRemoteAction();
              if (result) popup.goto('success');
              else popup.goto('failed');
            }}
          />
        ),
        pending: <ProgressStep onClose={() => popup.hide()} />,
        success: <SuccessStep text={_t('Der Befehl wurde erfolgreich an die Station gesendet.')} onClose={() => popup.hide()} />,
        failed: <FailedStep text={_t('Konnektor freigeben fehlgeschlagen')} onClose={() => popup.hide()} />,
      }}
    />
  );
}

function createChargePointActionList(cp: ChargePoint, trigger: (act: ChargePointRemoteAction) => void): RemoteActionContextItem[] {
  const actions: RemoteActionContextItem[] = [];
  const _t = translate('chargepoint-remote-actions');

  const stationIsConnected = cp.domainStatusOfStation === 'online' || cp.domainStatusOfStation === 'failure';

  if (cp.domainStatus !== 'notAvailable' && stationIsConnected && cp.can.remoteStartSession) {
    actions.push({
      action: () => trigger('startTransaction'),
      title: _t('Ladevorgang starten'),
    });
  }

  if (cp.domainStatus !== 'notAvailable' && stationIsConnected && cp.can.remoteStopSession) {
    actions.push({
      action: () => trigger('stopTransaction'),
      title: _t('Ladevorgang stoppen'),
    });
  }
  if (cp.domainStatus !== 'notAvailable' && stationIsConnected && cp.can.remoteUnlock) {
    actions.push({
      action: () => trigger('unlock'),
      title: _t('Konnektor freigeben'),
    });
  }
  if (cp.domainStatus !== 'notAvailable' && stationIsConnected && cp.can.remoteReserve) {
    actions.push({
      action: () => trigger('reserve'),
      title: _t('Ladepunkt reservieren'),
    });
  }
  if (stationIsConnected && cp.can.remoteReserve) {
    actions.push({
      action: () => trigger('cancelReserve'),
      title: _t('Reservierung stornieren'),
    });
  }

  // cp.domainStatus !== 'notAvailable' &&
  // should probabley not be checked here because otherwise we cannot make a chargePoint available if it is not available
  if (stationIsConnected && cp.can.remoteChangeAvailability) {
    actions.push({
      action: () => trigger('changeAvailability'),
      title: _t('Ladepunkt ent-/sperren'),
    });
  }

  return actions;
}
export function useChargePointRemoteActions(props: ChargePointRemoteActionProps) {
  const [chargePoint, setChargepoint] = useState<ChargePoint | undefined>();
  const [activeAction, setActiveAction] = useState<ChargePointRemoteAction | undefined>();

  // prevent rerenders, drawback: props cannot change from caller
  // should be fine, if not need to consider it
  // is a bit complicated because props use a lot references instead of value types
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const frozenProps = useMemo(() => props, []);

  const done = useCallback(() => {
    setChargepoint(undefined);
    setActiveAction(undefined);
  }, []);

  const { show: showContextMenu } = useContextMenu({
    id: 'remote-action-cp',
  });

  const contextMenuItems = useMemo((): RemoteActionContextItem[] => {
    if (!chargePoint) return [];
    const actions = createChargePointActionList(chargePoint, setActiveAction);
    return actions;
  }, [chargePoint, setActiveAction]);

  const chargePointRemoteActionsProps = useMemo(() => {
    return { ...frozenProps, activeAction, onClose: done, contextMenuItems, chargePoint, actionExecuted: props.refreshRequested };
  }, [frozenProps, activeAction, done, contextMenuItems, chargePoint, props.refreshRequested]);

  const hasRemoteActions = useCallback(
    (cp: ChargePoint) => {
      const actions = createChargePointActionList(cp, setActiveAction);
      return actions.length > 0;
    },
    [setActiveAction]
  );

  const showChargePointRemoteActionContextMenu = useCallback(
    (ev: TriggerEvent, cp: ChargePoint) => {
      setChargepoint(cp);
      showContextMenu(ev);
    },
    [setChargepoint, showContextMenu]
  );

  return { chargePointRemoteActionsProps, showChargePointRemoteActionContextMenu, hasChargePointRemoteActions: hasRemoteActions };
}

export default function ChargePointRemoteActions(
  props: ChargePointRemoteActionProps & {
    //
    onClose: () => void;
    actionExecuted: () => void;
    activeAction: ChargePointRemoteAction | undefined;
    contextMenuItems: RemoteActionContextItem[];
    chargePoint?: ChargePoint;
  }
) {
  return (
    <>
      <RemoteReservePopup
        accept={(card, minutes) => props.remoteReserve.do(props.chargePoint!, card, minutes)}
        getCards={props.remoteReserve.searchCards}
        onClose={props.onClose}
        done={props.actionExecuted}
        show={props.activeAction === 'reserve'}
      />

      <RemoteChangeAvailabilityChargePoint
        accept={(t) => props.remoteChangeAvailability.do(props.chargePoint!, t)}
        isCharging={props.chargePoint?.domainStatus === 'charging'}
        done={props.actionExecuted}
        onClose={props.onClose}
        show={props.activeAction === 'changeAvailability'}
      />

      <RemoteStopChargingSession
        accept={() => props.remoteStop.do(props.chargePoint!)}
        done={props.actionExecuted}
        onClose={props.onClose}
        show={props.activeAction === 'stopTransaction'}
      />

      <RemoteCancelReserve
        accept={() => props.remoteCancelReserve.do(props.chargePoint!)}
        done={props.actionExecuted}
        onClose={props.onClose}
        show={props.activeAction === 'cancelReserve'}
      />

      <RemoteStartChargingSession
        accept={(card) => props.remoteStart.do(props.chargePoint!, card)}
        getCards={props.remoteStart.searchCards}
        done={props.actionExecuted}
        onClose={props.onClose}
        show={props.activeAction === 'startTransaction'}
      />

      <RemoteUnlock
        isCharging={props.chargePoint?.domainStatus === 'charging'}
        accept={() => props.remoteUnlock.do(props.chargePoint!)}
        onClose={props.onClose}
        show={props.activeAction === 'unlock'}
      />

      <Menu id={`remote-action-cp`}>
        {props.chargePoint &&
          props.contextMenuItems.length > 0 &&
          props.contextMenuItems.map((item, idx) => {
            return (
              <Item key={idx} onClick={() => item.action()}>
                {item.title}
              </Item>
            );
          })}
      </Menu>
    </>
  );
}
