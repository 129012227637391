import classNames from 'classnames';
import { useState } from 'react';
import { ReactComponent as ChevronRight } from '../../../assets/chevron-right-double.svg';
import { FormGroupTitle, FormRow, FormSeparator, PageBody, PageDesc, PageLayout, PageTitle, Spacer, formGrid } from '../../../components/page-layout';
import useBetterTranslate from '../../../utils/translation-utils';

import { BreadCrumb } from '../../../components-v2/breadcrumb';
import ButtonV2, { ButtonAppereance } from '../../../components-v2/button';
import {
  ToggleButton,
  ToggleButtonAvailable,
  ToggleButtonCharging,
  ToggleButtonDefective,
  ToggleButtonNotAvailable,
  ToggleButtonOccupied,
  ToggleButtonUnknown,
} from '../../../components-v2/filters';
import InputV2, { FormContainerV2 } from '../../../components-v2/input';
import useFormState, { formValidator } from '../../../hooks/useFormState';
import styles from './component-gallery-page.module.scss';

function ExamplePageLayout() {
  const { _t } = useBetterTranslate('component-gallery');
  return (
    <PageLayout className={classNames()}>
      <BreadCrumb
        items={[
          { href: '#', title: 'foo', active: false },
          { href: '#', title: 'My active page', active: true },
        ]}
      />
      <PageTitle>{_t(`This is a page title`)}</PageTitle>
      <PageDesc>{_t(`This is the page description`)}</PageDesc>
      <PageBody>
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      </PageBody>
    </PageLayout>
  );
}

function ExampleFilters() {
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);

  return (
    <PageLayout className={classNames()}>
      <PageTitle>Toggle Buttons</PageTitle>
      <PageBody>
        <h3>Default Filter Toggle</h3>
        <FormRow>
          <FormContainerV2>
            <ToggleButton>foo</ToggleButton>
          </FormContainerV2>
          <FormContainerV2>
            <ToggleButtonAvailable />
          </FormContainerV2>
        </FormRow>
        <h3>Filter Toggles Styles</h3>
        <FormRow>
          <FormContainerV2>
            <ToggleButtonAvailable checked={checked1} onChange={(v) => setChecked1(v)} />
          </FormContainerV2>
          <FormContainerV2>
            <ToggleButtonCharging checked={checked2} onChange={(v) => setChecked2(v)} />
          </FormContainerV2>
          <FormContainerV2>
            <ToggleButtonOccupied checked={checked3} onChange={(v) => setChecked3(v)} />
          </FormContainerV2>
          <FormContainerV2>
            <ToggleButtonDefective checked={checked4} onChange={(v) => setChecked4(v)} />
          </FormContainerV2>
          <FormContainerV2>
            <ToggleButtonNotAvailable checked={checked4} onChange={(v) => setChecked4(v)} />
          </FormContainerV2>
          <FormContainerV2>
            <ToggleButtonUnknown checked={checked4} onChange={(v) => setChecked4(v)} />
          </FormContainerV2>
        </FormRow>

        {/*
        <h3>Accent Buttons</h3>
        <h3>Danger Buttons</h3>
        <h3>Warn Buttons</h3>
        */}
      </PageBody>
    </PageLayout>
  );
}

function ExampleButton() {
  const renderButtonExamples = (apperance: ButtonAppereance) => {
    return (
      <>
        <FormRow>
          <FormContainerV2 label='default' className={classNames(formGrid.w1)}>
            <ButtonV2 apperance={apperance}>Click Me</ButtonV2>
          </FormContainerV2>
        </FormRow>

        <FormRow>
          <FormContainerV2 label='full width (block)' className={classNames(formGrid.w1)}>
            <ButtonV2 apperance={apperance} block>
              Block size
            </ButtonV2>
          </FormContainerV2>
        </FormRow>
        <FormRow>
          <FormContainerV2 label='size s' w='1'>
            <ButtonV2 apperance={apperance} size='s'>
              click me
            </ButtonV2>
          </FormContainerV2>
          <FormContainerV2 label='size m' w='1'>
            <ButtonV2 apperance={apperance} size='m'>
              click me
            </ButtonV2>
          </FormContainerV2>
          <FormContainerV2 label='size l' w='1'>
            <ButtonV2 apperance={apperance} size='l'>
              click me
            </ButtonV2>
          </FormContainerV2>
        </FormRow>
      </>
    );
  };
  return (
    <PageLayout className={classNames()}>
      <PageTitle>Button Examples</PageTitle>
      <PageBody>
        <h3>Primary Buttons</h3>
        {renderButtonExamples('primary')}
        <h3>Accent Buttons</h3>
        {renderButtonExamples('accent')}
        <h3>Danger Buttons</h3>
        {renderButtonExamples('danger')}
        <h3>Warn Buttons</h3>
        {renderButtonExamples('warn')}
      </PageBody>
    </PageLayout>
  );
}

function ExampleForm() {
  const form = useFormState({
    submitHandler: async () => {
      alert('submitted');
    },
  });

  const [txt1] = form.useInputState({
    initialValue: '',
    label: 'example input (`test` is invalid)',
    maxLength: 100,
    validate: async (val) => {
      let err: string | undefined = '';
      if ((err = formValidator.maxLen(10, val, 'length exceeded'))) return err;
      if (val === `test`) return 'should not be `test`';
    },
  });

  return (
    <PageLayout className={classNames()}>
      <PageTitle>Form Examples</PageTitle>
      <PageBody>
        <FormGroupTitle>Simple Input</FormGroupTitle>
        <FormRow>
          <FormContainerV2 {...txt1}>
            <InputV2 {...txt1} />
          </FormContainerV2>
        </FormRow>

        <FormGroupTitle>Example with spacer (1/1/2)</FormGroupTitle>
        <FormRow>
          <FormContainerV2 w='1' label='width 1/4'>
            <InputV2 type='text' value='' />
          </FormContainerV2>
          <FormContainerV2 w='1' label='width 1/4'>
            <InputV2 type='text' value='' />
          </FormContainerV2>
          <Spacer w='2' />
        </FormRow>

        <FormGroupTitle>Example with spacer (2/1/1)</FormGroupTitle>
        <FormRow>
          <Spacer w='2' />
          <FormContainerV2 w='1' label='width 1/4'>
            <InputV2 type='text' value='' />
          </FormContainerV2>
          <FormContainerV2 w='1' label='width 1/4'>
            <InputV2 type='text' value='' />
          </FormContainerV2>
        </FormRow>

        <FormGroupTitle>One Label multiple inputs</FormGroupTitle>
        <FormRow>
          <FormContainerV2 label='this label spans over multiple fields' w='1'>
            <FormRow>
              <InputV2 type='text' value='' />
              <InputV2 type='text' value='' />
            </FormRow>
          </FormContainerV2>
          <Spacer w='2' />
        </FormRow>

        <FormSeparator />
        <FormGroupTitle>Prevent wrap on smaller screens</FormGroupTitle>
        <FormRow>
          <FormContainerV2 w='1' label='phone'>
            <FormRow noWrap>
              <FormContainerV2 w='1'>
                <InputV2 type='text' placeholder='+49' value='' />
              </FormContainerV2>
              <FormContainerV2 w='4'>
                <InputV2 type='text' placeholder='12345677' value='' />
              </FormContainerV2>
            </FormRow>
          </FormContainerV2>
          <Spacer w='2' />
        </FormRow>

        <FormSeparator />
        <FormRow>
          <Spacer w='1' />
          <ButtonV2 size='m' onClick={() => form.submit()}>
            Save
          </ButtonV2>
          <Spacer w='1' />
        </FormRow>
        {/*  */}
      </PageBody>
    </PageLayout>
  );
}

enum Examples {
  PageLayout = `Page Layout`,
  Buttons = 'Buttons',
  FormElements = 'Form Elements',
  Filters = 'Filters',
}
export default function ComponentGalleryPage() {
  //const { _t } = useBetterTranslate('component-gallery');
  const [example, setExample] = useState<Examples>(Examples.PageLayout);
  const [toolbarShow, setToolbarShow] = useState(false);

  const exampleValues = Object.values(Examples);

  let content = <></>;
  if (example === Examples.PageLayout) content = <ExamplePageLayout />;
  else if (example === Examples.Buttons) content = <ExampleButton />;
  else if (example === Examples.FormElements) content = <ExampleForm />;
  else if (example === Examples.Filters) content = <ExampleFilters />;
  else content = <div className={styles.pickExample}>no examples for this content</div>;

  return (
    <>
      <div onClick={() => setToolbarShow((current) => !current)} className={classNames(styles.toolbar, !toolbarShow ? styles.collapsed : '')}>
        <ChevronRight />
        <ul className={classNames(styles.list)}>
          {exampleValues.map((v) => {
            return (
              <li key={v} onClick={() => setExample(v)}>
                {v}
              </li>
            );
          })}
        </ul>
      </div>
      {content}
    </>
  );
}
