import classNames from 'classnames';
import moment from 'moment';
import { PropsWithChildren, useMemo, useState } from 'react';
import DataTable from '../../components/data-table/data-table';
import DateRangePicker from '../../components/date-range-picker';
import DropdownWithCheckboxes from '../../components/dropdown-with-checkboxes/dropdown-with-checkboxes';
import { FormCard, FormCardBody } from '../../components/form-card';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import Page, { ListingCardPlaceholder } from '../../components/page';
import Paging from '../../components/paging';
import Pill from '../../components/pill';
import SingleSelect from '../../components/select/single-select';
import colorStyles from '../../components/style-utils';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { usePageParams } from '../../hooks/usePageParams';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { dateRangeValidator, hierarchyValidator, pagingValidator, validateString } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './status-log-page.module.scss';
import { ReactComponent as StationIco } from '../../assets/charging-station-with-dashes.svg';
import { ReactComponent as PlugIco } from '../../assets/plug-blue.svg';
import { ChargeBoxOrConnectorStatus, InfrastructureLevel, MergedChargePointType, StatusLogSortableColumnFrontEnd } from '../../services/api-client/csp-api';
import { useApi } from '../../hooks/useApi';
import api from '../../services/api';
import NodeHierarchyLabel from '../../components/org-hierarchy/node-hierarchy-label';
import TagSelector from '../../components/tag-selector';
import IconExplanationSection from '../../components/icon-explanation-section';
import { ExternalHardwareIcon, PublicStationIcon } from '../../components/evse-linfo-icons';
import { formatter } from '../../utils/localized-types';
import { useAuth } from '../../utils/AuthProvider';

export interface StatusLogEntityFilterDto {
  title?: string;
  type: string;
  id: string;
}

export default function StatusLogPage() {
  const { _t } = useBetterTranslate('status-log-page');

  const { user } = useAuth();

  const navigate = useBetterNavigate<FilterParameters>();

  const MAX_DATE = useMemo(() => moment().add(1, 'day'), []);
  const MIN_DATE = useMemo(() => moment().subtract(2, 'year'), []);
  const DATE_FORMAT = 'YYYY-MM-DD';
  const SORT_BY_VALUES = ['entityId', 'manufacturer', 'model', 'statusStart'];

  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    sortBy?: string;
    sortDesc?: string;
    clientCode?: string;
    nodes?: string[];
    search?: string;
    typeOfCurrent?: MergedChargePointType[];
    statuses?: ChargeBoxOrConnectorStatus[];
    entityFilter?: StatusLogEntityFilterDto[];
    level?: InfrastructureLevel[];
    from?: string;
    to?: string;
    public?: string;
  };

  const sortingValidator = { sortBy: validateString(SORT_BY_VALUES) };
  const validators = { ...dateRangeValidator(MIN_DATE, MAX_DATE), ...pagingValidator, ...hierarchyValidator, ...sortingValidator };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>(
    {
      from: moment().subtract(30, 'day').format(DATE_FORMAT),
      to: moment().format(DATE_FORMAT),
      statuses: [],
    },
    validators
  );

  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [entityFilterOptions, setEntityFilterOptions] = useState<StatusLogEntityFilterDto[]>([]);
  const [hasExternalHardware, setHasExternalHardware] = useState(false);
  const [hasPublicStations, setHasPublicStations] = useState(false);

  const [logsResp, logsFetching, logsErr] = useApi(
    {
      call: async (
        clientCode?: string,
        hierarchyNodeCodes?: string[],
        skip?: number | null,
        limit?: number | null,
        search?: string,
        infrastructureLevel?: InfrastructureLevel[],
        statuses?: ChargeBoxOrConnectorStatus[],
        typeOfCurrent?: MergedChargePointType[],
        entityFilter?: StatusLogEntityFilterDto[],
        from?: string,
        to?: string,
        isPublic?: string,
        sortBy?: string,
        sortDesc?: string
      ) => {
        if (!clientCode) return undefined;

        const result = await api.statusLog.getStatusLogList(
          {
            limit: Number(limit || 20),
            skip: Number(skip || 0),
            clientCode,
            hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
            search,
            infrastructureLevel,
            statuses,
            typeOfCurrent,
            entityFilter,
            from,
            to,
            isPublic: isPublic === '1' ? true : undefined,
            sortBy: sortBy as StatusLogSortableColumnFrontEnd,
            sortDesc: sortDesc === '1',
          },
          {}
        );
        setEntityFilterOptions(result?.data?.entityFilter || []);
        setHasExternalHardware(!!result?.data?.entries.find((s) => s.isExternalHardware === true));
        setHasPublicStations(!!result?.data?.entries.find((s) => s.isPublic === true));
        return result;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.level,
    filterParams.statuses,
    filterParams.typeOfCurrent,
    filterParams.entityFilter,
    filterParams.from,
    filterParams.to,
    filterParams.public,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  const hierarchy = [...(logsResp?.clientContext.accessableNodes || []), ...(logsResp?.clientContext?.forbiddenParents || [])];
  const getNodeCode = (nodePath: string) => {
    const path = !nodePath.endsWith('/') ? nodePath : nodePath.substring(0, nodePath.length - 1);
    return path.substring(path.lastIndexOf('/') + 1);
  };

  const stationStatusOptions = {
    groupTitle: _t('Ladestationen'),
    values: [
      { id: ChargeBoxOrConnectorStatus.ChargeBoxOnline, title: _t('Online'), pillColor: colorStyles.components.stationStatus.bg.online, dataCy: 'cs_online' },
      { id: ChargeBoxOrConnectorStatus.ChargeBoxOffline, title: _t('Offline'), pillColor: colorStyles.components.stationStatus.bg.offline, dataCy: 'cs_offline' },
      { id: ChargeBoxOrConnectorStatus.ChargeBoxFailure, title: _t('Fehlerhaft'), pillColor: colorStyles.components.stationStatus.bg.failure, dataCy: 'cs_defective' },
      {
        id: ChargeBoxOrConnectorStatus.ChargeBoxNotInOperation,
        title: _t('Nicht in betrieb'),
        pillColor: colorStyles.components.stationStatus.bg.notInOperation,
        dataCy: 'cs_notInOp',
      },
    ],
  };

  const connectorStatusOptions = {
    groupTitle: _t('Ladepunkte'),
    values: [
      { id: ChargeBoxOrConnectorStatus.ConnectorAvailable, title: _t('Verfügbar'), pillColor: colorStyles.components.connectorsStatus.bg.available, dataCy: 'cp_available' },
      { id: ChargeBoxOrConnectorStatus.ConnectorCharging, title: _t('Laden'), pillColor: colorStyles.components.connectorsStatus.bg.charging, dataCy: 'cp_charging' },
      { id: ChargeBoxOrConnectorStatus.ConnectorOccupied, title: _t('Besetzt'), pillColor: colorStyles.components.connectorsStatus.bg.occupied, dataCy: 'cp_occupied' },
      { id: ChargeBoxOrConnectorStatus.ConnectorFailure, title: _t('Fehlerhaft'), pillColor: colorStyles.components.connectorsStatus.bg.failure, dataCy: 'cp_defective' },
      {
        id: ChargeBoxOrConnectorStatus.ConnectorNotAvailableReserved,
        title: _t('Nicht verfügbar'),
        subtitle: `(${_t('Reserviert')})`,
        pillColor: colorStyles.components.connectorsStatus.bg.unavailable,
        dataCy: 'cp_reserved',
      },
      {
        id: ChargeBoxOrConnectorStatus.ConnectorNotAvailableLocked,
        title: _t('Nicht verfügbar'),
        subtitle: `(${_t('Gesperrt')})`,
        pillColor: colorStyles.components.connectorsStatus.bg.unavailable,
        dataCy: 'cp_unavailable',
      },
      {
        id: ChargeBoxOrConnectorStatus.ConnectorNotAvailableNone,
        title: _t('Nicht verfügbar'),
        subtitle: `(${_t('Sonstige')})`,
        pillColor: colorStyles.components.connectorsStatus.bg.unavailable,
        dataCy: 'cp_other',
      },
      { id: ChargeBoxOrConnectorStatus.ConnectorUnknown, title: _t('Unbekannt'), pillColor: colorStyles.components.connectorsStatus.bg.unknown, dataCy: 'cp_unknown' },
    ],
  };

  const StatusIndicator = (props: PropsWithChildren<{ entryStatus: ChargeBoxOrConnectorStatus }>) => {
    const mapStatusToPillColor = (combinedStatus: ChargeBoxOrConnectorStatus) => {
      const mappedPillColor = stationStatusOptions.values.map((value) => value.id).includes(combinedStatus)
        ? stationStatusOptions.values.find((value) => value.id === combinedStatus)?.pillColor
        : connectorStatusOptions.values.find((value) => value.id === combinedStatus)?.pillColor;
      return mappedPillColor || '';
    };
    let statusTitle = '';
    let statusSubtitle = '';

    if (stationStatusOptions.values.map((value) => value.id).includes(props.entryStatus)) {
      statusTitle = stationStatusOptions.values.find((value) => value.id === props.entryStatus)?.title || '';
    } else {
      statusTitle = connectorStatusOptions.values.find((value) => value.id === props.entryStatus)?.title || '';
    }
    if (props.entryStatus === ChargeBoxOrConnectorStatus.ConnectorNotAvailableReserved || props.entryStatus === ChargeBoxOrConnectorStatus.ConnectorNotAvailableLocked) {
      statusSubtitle = connectorStatusOptions.values.find((value) => value.id === props.entryStatus)?.subtitle || '';
    }

    return (
      <>
        <div>
          {' '}
          <Pill className={classNames(styles.statusPill, mapStatusToPillColor(props.entryStatus))}></Pill>
        </div>
        <div>{statusTitle}</div>
        <div className={styles.subLabel}>{statusSubtitle}</div>
      </>
    );
  };

  const typeOfCurrentOptions = [
    { name: 'AC', id: 'ac', pillDataCy: 'current_ac_pill' },
    { name: 'DC', id: 'dc', pillDataCy: 'current_dc_pill' },
    { name: 'AC/DC', id: 'ac/dc', pillDataCy: 'current_ac_dc_pill' },
  ];

  const entityFilterName = (tagType: string) => {
    if (tagType === 'station') return _t('Ladestation');
    if (tagType === 'connector') return _t('Ladepunkt');
    if (tagType === 'manufacturer') return _t('Hersteller');
    if (tagType === 'model') return _t('Modell');
    return '';
  };

  const searchEntityTags = async (clientCode: string | undefined, nodes: string[] | undefined, txt: string) => {
    if (!clientCode) return [];
    const hierarchyNodeCodes = nodes ? nodes : [clientCode];
    const entityFilterRes = await api.statusLog.searchFilters({ q: txt, clientCode, hierarchyNodeCodes });
    return entityFilterRes.data;
  };

  return (
    <Page
      breadCrumb={[{ title: 'STATUSLOG', href: '/status-log', active: true }]}
      fetching={logsFetching}
      placeHolder={<ListingCardPlaceholder />}
      error={logsErr}
      className={styles.root}
      outOfPlaceHolder={
        <>
          <OrgHierarchyPicker
            selectMode='node'
            clientContext={logsResp?.clientContext}
            onNodeSelected={(clientCode, selectedCodes) => {
              if (clientCode !== logsResp?.clientContext?.code) {
                navigate(`/status-log/${clientCode}`, { nodes: selectedCodes });
              } else {
                setFilterParams({ nodes: selectedCodes });
              }
            }}
            {...createClientSearchProps(logsResp?.clientContext)}
            selectedNodes={filterParams.nodes}
          />

          {(hasExternalHardware || hasPublicStations) && (
            <FormCard phoneSize='full'>
              <FormCardBody>
                <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />
              </FormCardBody>
            </FormCard>
          )}

          <FormCard phoneSize='full' className={classNames(styles.filterArea)}>
            <div className={styles.tagSearchContainer}>
              <TagSelector<StatusLogEntityFilterDto>
                fetchOptions={async (val): Promise<StatusLogEntityFilterDto[]> => {
                  const findings = await searchEntityTags(logsResp?.clientContext?.code, filterParams.nodes, val);
                  return findings;
                }}
                classNames={styles.tagSearchInput}
                placeholder={_t('Suchen')}
                displayStyle={'search'}
                createOption={(val) => {
                  return (
                    <div className={classNames(styles.entityFilterOpt, styles[val.type])} data-cy='el_result_pick' data-cy-id={val.title}>
                      <span>{val.title}</span>
                      <span className={styles.type}>{entityFilterName(val.type)}</span>
                    </div>
                  );
                }}
                createTag={(val) => {
                  return <div className={classNames(styles.entityFilterTag, styles[val.type])}>{val.title}</div>;
                }}
                // set always to empty list
                // we will grab the values and show them below and not inside of the input
                selectedValues={[]}
                onChanged={(values) => {
                  //check for duplicated
                  const uniqueValues = values.filter((x) => entityFilterOptions.find((y) => y.type === x.type && y.id === x.id) === undefined);
                  if (uniqueValues.length > 0) {
                    const allValues = [...uniqueValues, ...entityFilterOptions];
                    setFilterParams({ entityFilter: allValues });
                    setEntityFilterOptions(allValues);
                  }
                }}
                removeBtnClass={() => styles.tagRmBtn}
              />
            </div>
            <div className={styles.currentTypeSelect}>
              <SingleSelect<{ name: string; id: string }>
                placeholder={_t('AC/DC')}
                options={typeOfCurrentOptions}
                createOption={(opt) => <div className={styles.outletNumber}>{opt.name}</div>}
                renderDisplayValue={(opt) => opt.name}
                selectedValue={null}
                fetchOptions={async (txt) => {
                  if (!txt) return typeOfCurrentOptions || [];
                  const results = (typeOfCurrentOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                  return results;
                }}
                onChanged={(selected) => {
                  if (selected) {
                    if (!filterParams.typeOfCurrent?.map((type) => type.toString()).includes(selected?.name)) {
                      const allValues = filterParams.typeOfCurrent
                        ? [...filterParams.typeOfCurrent, selected.name as MergedChargePointType]
                        : [selected.name as MergedChargePointType];
                      setFilterParams({ typeOfCurrent: allValues });
                    }
                  }
                }}
                isClearable={true}
                dataCy='current_type'
              />
            </div>

            <div className={styles.statusSelect}>
              <DropdownWithCheckboxes
                data={[stationStatusOptions, connectorStatusOptions]}
                selectedData={filterParams.statuses?.map((status) => status as string) || []}
                placeholder={_t('Status')}
                commitBtnTxt={_t('Anwenden')}
                showSubtitles={true}
                onChanged={(selected) => {
                  setFilterParams({ statuses: selected.map((status) => status as ChargeBoxOrConnectorStatus) });
                }}
                dataCy={{ root: 'status_dropdown', submitButton: 'apply_status' }}
              />
            </div>

            <div className={styles.levelFilters}>
              <Pill
                selectedClass={colorStyles.components.rateType.public}
                unselectedClass={colorStyles.components.rateTypeInvert.public}
                selected={filterParams.public === '1'}
                onClick={() => setFilterParams({ public: filterParams.public === '1' ? undefined : '1' })}
                dataCy='public_pill'
              >
                {_t('Public Service')}
              </Pill>

              <Pill
                className={styles.levelPillFilter}
                selectedClass={colorStyles.components.infrastructureLevelPill.active}
                unselectedClass={colorStyles.components.infrastructureLevelPill.inactive}
                selected={filterParams.level && filterParams.level.includes(InfrastructureLevel.Station)}
                onClick={() => {
                  const currentFilters = filterParams.level || [];
                  const currentFiltersWithoutChargebox = [...currentFilters].filter((value) => value !== InfrastructureLevel.Station);
                  currentFilters.includes(InfrastructureLevel.Station)
                    ? setFilterParams({ level: currentFiltersWithoutChargebox })
                    : setFilterParams({ level: [...currentFilters, InfrastructureLevel.Station] });
                }}
                dataCy='charging_station_pill'
              >
                <StationIco />
                {_t('Station')}
              </Pill>
              <Pill
                className={styles.levelPillFilter}
                selectedClass={colorStyles.components.infrastructureLevelPill.active}
                unselectedClass={colorStyles.components.infrastructureLevelPill.inactive}
                selected={filterParams.level && filterParams.level.includes(InfrastructureLevel.Connector)}
                onClick={() => {
                  const currentFilters = filterParams.level || [];
                  const currentFiltersWithoutConnector = [...currentFilters].filter((value) => value !== InfrastructureLevel.Connector);
                  currentFilters.includes(InfrastructureLevel.Connector)
                    ? setFilterParams({ level: currentFiltersWithoutConnector })
                    : setFilterParams({ level: [...currentFilters, InfrastructureLevel.Connector] });
                }}
                dataCy='charging_point_pill'
              >
                <PlugIco />
                {_t('Ladepunkt')}
              </Pill>
            </div>
            <DateRangePicker
              className={styles.datePicker}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              selected={filterParams}
              onChange={(range) => setFilterParams({ ...range })}
              dateFormat={DATE_FORMAT}
            />
          </FormCard>
        </>
      }
    >
      {entityFilterOptions.length > 0 && (
        <FormCard tabletSize='full'>
          <div className={styles.tagSearchDisplay}>
            {entityFilterOptions.map((item) => {
              const classMap = {
                [styles.station]: item.type === 'station',
                [styles.connector]: item.type === 'connector',
                [styles.manufacturer]: item.type === 'manufacturer',
                [styles.model]: item.type === 'model',
              };
              return (
                <Pill
                  key={item.id}
                  className={classNames(styles.appliedTag, classMap)}
                  onClick={() => {
                    const condition = (existing: StatusLogEntityFilterDto) => existing.type !== item.type || existing.id !== item.id;
                    setFilterParams({ entityFilter: filterParams.entityFilter?.filter(condition) });
                    setEntityFilterOptions(entityFilterOptions.filter(condition));
                  }}
                >
                  {item.title || ''}
                </Pill>
              );
            })}
          </div>
        </FormCard>
      )}

      <FormCard phoneSize='full' className={classNames(styles.selectedTags)}>
        {filterParams.typeOfCurrent &&
          filterParams.typeOfCurrent.length > 0 &&
          filterParams.typeOfCurrent.map((item) => {
            return (
              <div>
                <Pill
                  key={item}
                  hasClose={true}
                  className={classNames(styles.appliedTag)}
                  onClick={() => {
                    const remainingValues = filterParams.typeOfCurrent?.filter((val) => val !== item);
                    setFilterParams({
                      typeOfCurrent: remainingValues,
                    });
                  }}
                  dataCy={typeOfCurrentOptions.find((opt) => opt.name === item)?.pillDataCy}
                >
                  <div className={classNames(styles.tagText, styles.tagTitle)}>{item}</div>
                </Pill>
              </div>
            );
          })}
      </FormCard>

      <FormCard phoneSize='full'>
        <FormCardBody className={styles.gridCardBody}>
          <DataTable
            sticky={true}
            records={logsResp?.entries || []}
            sorting={{
              handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
              col: filterParams.sortBy,
              desc: filterParams.sortDesc === '1',
            }}
            renderer={{
              cols: [
                {
                  headerCol: () => (
                    <div>
                      {_t('EVSEID')}
                      <br />
                      <span className={styles.subLabel}>{_t('Standort')}</span>
                    </div>
                  ),
                  name: 'entityId',
                  sortable: true,
                  col: (record) => (
                    <div>
                      <div className={styles.evseId}>
                        {record.entityId}
                        <ExternalHardwareIcon isExternalHardware={record.isExternalHardware} />
                        <PublicStationIcon isPublic={record.isPublic} />
                      </div>
                      <NodeHierarchyLabel
                        className={styles.wrapLabel}
                        allNodes={hierarchy}
                        code={getNodeCode(record.clientHierarchy)}
                        hideClientRoot={true}
                        hideRegion={true}
                        hideArea={true}
                        characterForNoValue={'-'}
                      />
                    </div>
                  ),
                  headerClass: styles.vcenter,
                  width: '20%',
                },
                {
                  headerCol: () => <div>{_t('Level')}</div>,
                  name: 'level',
                  sortable: false,
                  col: (record) => <div>{record.isConnector ? <PlugIco /> : <StationIco />}</div>,
                  colClass: (record) => styles.infrastructureLevelIcon,
                  headerClass: styles.vcenter,
                  width: '9%',
                },
                {
                  headerCol: () => <div>{_t('Hersteller')}</div>,
                  name: 'manufacturer',
                  sortable: true,
                  col: (record) => <div>{record.manufacturer || '-'}</div>,
                  width: '16%',
                },
                {
                  headerCol: () => <div>{_t('Modell')}</div>,
                  name: 'model',
                  sortable: true,
                  col: (record) => <div>{record.model || '-'}</div>,
                  width: '25%',
                },
                {
                  headerCol: () => <div>{_t('Stromart')}</div>,
                  name: 'current-type',
                  sortable: false,
                  col: (record) => <div>{record.typeOfCurrent || '-'}</div>,
                  headerClass: styles.vcenter,
                  width: '9%',
                },
                {
                  headerCol: () => <div>{_t('Start')}</div>,
                  name: 'statusStart',
                  sortable: true,
                  col: (record) => (
                    <div>
                      {formatter.formatDateFromIsoString(record.statusStartAt, user?.preferences.languageCode, { day: 'numeric', month: 'numeric', year: '2-digit' })}
                      <br />
                      {formatter.formatDateTimeFromIsoString(record.statusStartAt, user?.preferences.languageCode, { timeStyle: 'short' })}
                    </div>
                  ),
                  width: '10%',
                },
                {
                  headerCol: () => <div>{_t('Status')}</div>,
                  name: 'status',
                  sortable: false,
                  col: (record) => (
                    <div className={styles.vcenter}>
                      <StatusIndicator entryStatus={record.status as ChargeBoxOrConnectorStatus} />
                    </div>
                  ),
                  headerClass: styles.vcenter,
                  width: '14%',
                },
              ],
            }}
          />
        </FormCardBody>
      </FormCard>

      {logsResp && (
        <Paging
          skip={logsResp.skip}
          limit={logsResp.limit}
          total={logsResp.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )}
    </Page>
  );
}
