import { useCallback, useEffect, useMemo, useState } from 'react';
import { Item, Menu, TriggerEvent, useContextMenu } from 'react-contexify';
import { ConfirmStep, FailedStep, MultistepPopup, PartialSuccessStep, ProgressStep, SuccessStep, useMultistepPopup } from '../../components/edit-popup';
import RadioButton from '../../components/input-radio-button';
import { translate } from '../../i18n';
import { ChangeAvailabilityResult, ChangeAvailabilityTypes, ChangeStationAvailabilityResponse } from '../../services/api-client/csp-api';
import { makeSafeCall } from '../../utils/make-safe-call';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './station-remote-actions.module.scss';

export interface Station {
  chargeBoxId: string;
  domainStatus: 'online' | 'offline' | 'failure' | 'notInOperation';
  // hasChargingConnectors: boolean;
  can: { remoteReset: boolean; remoteChangeAvailability: boolean; goToSessions: boolean };
}

export type StationRemoteAction = 'reset' | 'changeAvailability';

export type StationRemoteActionProps = {
  refreshRequested: () => Promise<void>;

  reset: {
    do: (station: Station) => Promise<boolean>;
  };
  changeAvailability: {
    do: (
      station: Station,
      newVal: ChangeAvailabilityTypes
    ) => Promise<{
      changeAvailabilityStatus: ChangeAvailabilityResult;
      connectors: number[];
    }>;
  };
};

type RemoteActionContextItem = {
  action: () => void;
  title: string;
};

export function RemoteResetStation(props: {
  //
  accept: () => Promise<boolean>;
  onClose: () => void;
  done: () => Promise<void>;
  show: boolean;
  // hasChargingConn: boolean;
}) {
  const { _t } = useBetterTranslate('station-remote-actions');

  const doRemoteAction = makeSafeCall(props.accept, false);

  const exec = async () => {
    popup.goto('pending');
    const result = await doRemoteAction();
    if (result) popup.goto('success');
    else popup.goto('failed');
    await props.done();
  };

  const popup = useMultistepPopup({
    steps: ['confirm', 'pending', 'success', 'failed'],
    activeStep: 'confirm',
    // defaultActionHandler: {
    //   confirm: () => {
    //     console.log('confirm ...');
    //   },
    //   success: () => {
    //     console.log('success ...');
    //   },
    // },
    onClose: props.onClose,
  });

  useEffect(() => {
    if (props.show && popup.isOpen) return;

    if (!props.show) popup.hide();
    else popup.show('confirm');
  }, [props.show, popup]);

  return (
    <MultistepPopup
      title={_t('Station neu starten')}
      {...popup.componentProps}
      steps={{
        confirm: (
          <ConfirmStep
            text={_t('Sind Sie sicher, dass Sie die Station neu starten wollen?')}
            decline={() => popup.hide()}
            accept={exec}
            wanText={_t('⚠️ An dieser Station ist ein Ladevorgang aktiv, der durch den Neustart abgebrochen wird.')}
          />
        ),
        pending: <ProgressStep onClose={() => popup.hide()} />,
        success: <SuccessStep text={_t('Der Befehl wurde erfolgreich an die Station gesendet')} onClose={() => popup.hide()} />,
        failed: <FailedStep text={_t('Neustart konnte nicht durchgeführt werden')} onClose={() => popup.hide()} />,
      }}
    />
  );
}

export function RemoteChangeAvailabilityStation(props: {
  //
  accept: (availabilityMode: ChangeAvailabilityTypes) => Promise<ChangeStationAvailabilityResponse>;
  onClose: () => void;
  show: boolean;
  done: () => Promise<void>;
  // hasChargingConn: boolean;
}) {
  const { _t } = useBetterTranslate('station-remote-actions');
  const [availabilityMode, setAvailabilityMode] = useState<ChangeAvailabilityTypes>(ChangeAvailabilityTypes.Operative);
  const [failedConnectorMsg, setFailedConnectorMsg] = useState('');

  const doRemoteAction = makeSafeCall(props.accept, { changeAvailabilityStatus: ChangeAvailabilityResult.Failed, connectors: [] });
  const popup = useMultistepPopup({
    steps: ['confirm', 'pending', 'success', 'failed', 'partial'],
    activeStep: 'confirm',
    onClose: props.onClose,
  });

  useEffect(() => {
    if (props.show && popup.isOpen) return;

    if (!props.show) popup.hide();
    else popup.show('confirm');
  }, [props.show, popup]);

  return (
    <MultistepPopup
      title={_t('Ladepunkt(e) ent-/sperren')}
      {...popup.componentProps}
      steps={{
        confirm: (
          <ConfirmStep
            text={_t('Sind Sie sicher, dass Sie den/die Ladepunkt(e) ent-/sperren wollen?')}
            decline={() => popup.hide()}
            accept={async () => {
              popup.goto('pending');
              const result = await doRemoteAction(availabilityMode);
              if (result.changeAvailabilityStatus === ChangeAvailabilityResult.Success) popup.goto('success');
              else if (result.changeAvailabilityStatus === ChangeAvailabilityResult.Partial) {
                const msg = _t(`Ein Fehler wurde von Konektor(en) {{connectors}} zurückgegeben`, { connectors: (result.connectors || []).join(', ') });
                setFailedConnectorMsg(msg);
                popup.goto('partial');
              } else popup.goto('failed');
              await props.done();
            }}
            wanText={_t('⚠️ Aktive Ladevorgänge, werden durch diese Aktion abgebrochen.')}
            controls={
              <div className={styles.availabilityMode}>
                <RadioButton
                  disabled={false}
                  label={_t('entsperren')}
                  isSelected={availabilityMode === 'Operative'}
                  onChange={() => setAvailabilityMode(ChangeAvailabilityTypes.Operative)}
                />
                <RadioButton
                  disabled={false}
                  label={_t('sperren')}
                  isSelected={availabilityMode === 'Inoperative'}
                  onChange={() => setAvailabilityMode(ChangeAvailabilityTypes.Inoperative)}
                />
              </div>
            }
          />
        ),
        partial: (
          <PartialSuccessStep
            onClose={() => popup.hide()}
            text={() => {
              return (
                <label className={styles.partialMessage}>
                  <b>
                    {availabilityMode === ChangeAvailabilityTypes.Inoperative ? _t('Ladepunkt(e) wurde(n) teilweise gesperrt') : _t('Ladepunkt(e) wurde(n) teilweise entsperrt')}
                  </b>
                  {failedConnectorMsg && <span>{failedConnectorMsg}</span>}
                </label>
              );
            }}
          />
        ),
        pending: <ProgressStep onClose={() => popup.hide()} />,
        success: (
          <SuccessStep
            text={availabilityMode === ChangeAvailabilityTypes.Inoperative ? _t('Ladepunkt(e) wurde(n) gesperrt') : _t('Ladepunkt(e) wurde(n) entsperrt')}
            onClose={() => popup.hide()}
          />
        ),
        failed: <FailedStep onClose={() => popup.hide()} />,
      }}
    />
  );
}

function createStationRemoteActionList(station: Station, trigger: (act: StationRemoteAction) => void): RemoteActionContextItem[] {
  const actions: RemoteActionContextItem[] = [];
  const _t = translate('station-remote-actions');

  if ((station.domainStatus === 'online' || station.domainStatus === 'failure') && station.can.remoteReset) {
    actions.push({
      action: () => trigger('reset'),
      title: _t('Station neu starten'),
    });
  }

  if ((station.domainStatus === 'online' || station.domainStatus === 'failure') && station.can.remoteChangeAvailability) {
    actions.push({
      action: () => trigger('changeAvailability'),
      title: _t('Ladepunkte ent-/sperren'),
    });
  }

  return actions;
}
export function useStationRemoteActions(props: StationRemoteActionProps) {
  const [station, setStation] = useState<Station | undefined>();
  const [activeAction, setActiveAction] = useState<StationRemoteAction | undefined>();

  // prevent rerenders, drawback: props cannot change from caller
  // should be fine, if not need to consider it
  // is a bit complicated because props use a lot references instead of value types
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const frozenProps = useMemo(() => props, []);

  const done = useCallback(() => {
    setStation(undefined);
    setActiveAction(undefined);
  }, []);

  const { show: showContextMenu } = useContextMenu({
    id: 'remote-action-station',
  });

  const contextMenuItems = useMemo((): RemoteActionContextItem[] => {
    if (!station) return [];
    const actions = createStationRemoteActionList(station, setActiveAction);
    return actions;
  }, [station, setActiveAction]);

  const stationRemoteActionsProps = useMemo(() => {
    return { ...frozenProps, activeAction, done, contextMenuItems, station, actionExecuted: props.refreshRequested };
  }, [frozenProps, activeAction, done, contextMenuItems, station, props.refreshRequested]);

  const hasRemoteActions = useCallback(
    (station: Station) => {
      const actions = createStationRemoteActionList(station, setActiveAction);
      return actions.length > 0;
    },
    [setActiveAction]
  );

  const showStationRemoteActionContextMenu = useCallback(
    (ev: TriggerEvent, station: Station) => {
      setStation(station);
      showContextMenu(ev);
    },
    [setStation, showContextMenu]
  );

  return { stationRemoteActionsProps, showStationRemoteActionContextMenu, hasStationRemoteActions: hasRemoteActions };
}

export default function StationRemoteActions(
  props: StationRemoteActionProps & {
    //
    actionExecuted: () => Promise<void>;
    done: () => void;
    activeAction: StationRemoteAction | undefined;
    contextMenuItems: RemoteActionContextItem[];
    station?: Station;
  }
) {
  return (
    <>
      <RemoteResetStation
        accept={() => props.reset.do(props.station!)}
        onClose={props.done}
        done={props.actionExecuted}
        show={props.activeAction === 'reset'}
        // hasChargingConn={props.station?.hasChargingConnectors || false}
      />
      <RemoteChangeAvailabilityStation
        accept={(mode) => props.changeAvailability.do(props.station!, mode)}
        onClose={props.done}
        done={props.actionExecuted}
        show={props.activeAction === 'changeAvailability'}
        // hasChargingConn={props.station?.hasChargingConnectors || false}
      />

      <Menu id={`remote-action-station`}>
        {props.station &&
          props.contextMenuItems.length > 0 &&
          props.contextMenuItems.map((item, idx) => {
            return (
              <Item key={idx} onClick={() => item.action()}>
                {item.title}
              </Item>
            );
          })}
      </Menu>
    </>
  );
}
