import classNames from 'classnames';
import styles from './dropdown-with-checkboxes.module.scss';

import { MouseEvent, useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { ReactComponent as ChevronLeftIco } from '../../assets/chevron-left.svg';
import { ReactComponent as CloseIco } from '../../assets/close.svg';
import { ButtonPrimary } from '../button';
import CheckBox from '../input-check-box';

export interface CheckboxOption {
  id: string;
  name?: string;
  title?: string;
  subtitle?: string;
  dataCy?: string;
}
export interface CheckboxGroupData {
  groupTitle: string;
  values: CheckboxOption[];
}

export default function DropdownWithCheckboxes(props: {
  className?: string;
  placeholder?: string;
  popoverClass?: string;
  data: CheckboxGroupData[];
  selectedData: string[];
  commitBtnTxt?: string;
  delimiterClass?: string;
  showSubtitles?: boolean;
  dataCy?: { root?: string; submitButton?: string };

  onChanged?: (options: string[]) => void;
}) {
  const onClear = (e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    props.onChanged?.([]);
  };

  const allOptions = props.data.flatMap((group) => group.values);
  const [options, setOptions] = useState<string[]>(props.selectedData);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState(props.commitBtnTxt || '');
  const [numOfSelected, setNumOfSelected] = useState('');

  useEffect(() => {
    if (popoverOpen) {
      setOptions(props.selectedData);
    } else {
      setOptions([]);
    }

    let placeholder = '';
    if (props.placeholder) placeholder = props.placeholder;
    setPlaceholder(placeholder);
    setNumOfSelected(`${props.selectedData.length}/${allOptions.length}`);
  }, [popoverOpen, props.selectedData, props.placeholder, allOptions.length]);

  const onSubmitClicked = () => {
    props.onChanged?.(options);
    setPopoverOpen(false);
  };

  const toggleOption = (changedOption: string) => {
    if (options.includes(changedOption)) {
      const updatedOptions = options.filter((option) => option !== changedOption);
      setOptions(updatedOptions);
    } else {
      setOptions([...options, changedOption]);
    }
  };

  const icoCls = {
    [styles.chevron]: !(options.length > 0),
    [styles.reset]: options.length > 0,
    [styles.open]: popoverOpen,
    [styles.closed]: !popoverOpen,
  };

  return (
    <div className={classNames(styles.root)} data-cy={props.dataCy?.root}>
      <Popover
        isOpen={popoverOpen}
        onClickOutside={() => setPopoverOpen(false)}
        containerStyle={{ zIndex: '1000' }}
        align={'center'}
        positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
        reposition={true}
        content={() => {
          return (
            <div className={classNames(styles.inputsWrapper, props.popoverClass)}>
              <div className={classNames(styles.inputs)}>
                {props.data.map((group, index) => {
                  const delimiter = index < props.data.length - 1 ? classNames(styles.delimiter, props.delimiterClass) : '';
                  return (
                    <div className={styles.optionsContainer} key={group.groupTitle}>
                      <div className={styles.optionsGroupTitle}>{group.groupTitle}</div>
                      <div className={classNames(styles.options, delimiter)}>
                        {group.values.map((option) => {
                          const subLabel = <div>{props.showSubtitles ? option.subtitle || ' ' : undefined}</div>;
                          const isSelectedOption = !!options.find((selectedOpt) => selectedOpt === option.id) || false;
                          return (
                            <CheckBox
                              key={option.id}
                              isSelected={isSelectedOption}
                              label={option.title || ''}
                              subLbl={subLabel}
                              classNameSubLbl={styles.sublabel}
                              onChange={() => toggleOption(option.id)}
                              dataCy={option.dataCy}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <ButtonPrimary className={styles.button} onClick={onSubmitClicked} dataCy={props.dataCy?.submitButton}>
                {props.commitBtnTxt || 'OK'}
              </ButtonPrimary>
            </div>
          );
        }}
      >
        <div className={styles.displayWrapper}>
          <div
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.code === 'Space' || e.code === 'Enter') {
                setPopoverOpen(!popoverOpen);
              }
            }}
            key={'trigger-bt'}
            onClick={(e) => setPopoverOpen(!popoverOpen)}
            className={styles.display}
          >
            <span>{placeholder}</span>
            <span>
              <span>{numOfSelected}</span>
              <span
                onClick={(e) => {
                  if (props.selectedData.length > 0) {
                    onClear(e);
                  } else {
                    e.stopPropagation();
                    e.preventDefault();
                    setPopoverOpen(!popoverOpen);
                  }
                }}
                className={classNames(icoCls)}
              >
                {props.selectedData.length > 0 ? (
                  <span className={styles.close}>
                    <CloseIco />
                  </span>
                ) : (
                  <ChevronLeftIco />
                )}
              </span>
            </span>
          </div>
        </div>
      </Popover>
    </div>
  );
}
