import classNames from 'classnames';
import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { ControlProps, GroupBase, MenuProps, OptionProps, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ReactComponent as CloseIco } from '../assets/close.svg';
import { ReactComponent as MagnifyingIco } from '../assets/magnifying-glass.svg';
//import useBetterTranslate from '../utils/translation-utils';
import _ from 'lodash';
import styles from './tag-selector-v2.module.scss';

export interface TagSelectorProps<TagType> {
  fetchOptions: (input: string) => Promise<TagType[]>;
  createOption: (val: TagType) => ReactNode;
  // createTag: (val: TagType) => ReactNode;
  // createRemove?: (val: TagType) => ReactNode;
  // removeBtnClass?: (val: TagType) => string;
  // displayStyle?: 'search';
  optionParentClass?: (val: TagType, focused: boolean) => string | undefined;
  placeholder?: string;
  onSelected: (values: TagType) => void;
  // selectedValues: TagType[];
  classNames?: string;
}

type InnerSelectType<TagType> = { value: TagType };
export function TagSelectorV2<TagType>(props: TagSelectorProps<TagType>) {
  //const { _t } = useBetterTranslate('tag-selector');
  const { fetchOptions } = props;
  const loadOpts = useMemo(() => {
    return (inputValue: string, callback: (options: InnerSelectType<TagType>[]) => void) => {
      fetchOptions(inputValue)
        .then((t) => {
          const mapped = t.map((t) => ({ value: t }));
          callback(mapped);
        })
        .catch((e) => {
          if (e instanceof DOMException && e.name === 'AbortError') callback([]);
          else throw e;
        });
    };
  }, [fetchOptions]);

  const controlComponent = useMemo(() => {
    const comp = function ControlComponent(optProps: ControlProps<InnerSelectType<TagType>, true, GroupBase<InnerSelectType<TagType>>>) {
      return (
        <components.Control {...optProps} className={classNames(optProps?.className, styles.control)}>
          <div className={styles.searchControlWrapper}>
            <>{optProps.children}</>
            <div className={styles.searchControlIco}>
              <MagnifyingIco />
            </div>
          </div>
        </components.Control>
      );
    };

    return comp;
  }, []);

  const menuComponent = useMemo(() => {
    const comp = function MenuComponent(menuOpts: MenuProps<InnerSelectType<TagType>, true, GroupBase<InnerSelectType<TagType>>>) {
      if (menuOpts.options.length <= 0) return <></>;
      return <components.Menu {...menuOpts}>{menuOpts.children}</components.Menu>;
    };

    return comp;
  }, []);

  const tagOptComponent = useMemo(() => {
    const comp = function TagOption(optProps: OptionProps<InnerSelectType<TagType>, true, GroupBase<InnerSelectType<TagType>>>) {
      const option = props.createOption(optProps.data.value);
      let cls = props.optionParentClass?.(optProps.data.value, optProps.isFocused);

      return (
        <components.Option {...optProps} className={classNames(optProps.className, styles.tagSelectOption, cls, optProps.isFocused ? styles.focused : '')}>
          {option}
        </components.Option>
      );
    };

    return comp;
  }, [props]);

  // const IndicatorSeparator = props.displayStyle === 'search' ? () => null : components.IndicatorSeparator;
  // const DropdownIndicator = props.displayStyle === 'search' ? () => null : components.DropdownIndicator;
  return (
    <div className={classNames(styles.root, props.classNames)}>
      <AsyncSelect
        // this component has a bug within it's caching
        // it use an JS object as a cache, therefor if a user enters the text 'hasOwnProperty' (as an example)
        // it try to override the property (function) 'hasOwnProperty' on the object and later on try to use this as a result
        // this cause an exceptio
        // if needed to cache we should investigate this further for now, just disable the cache.
        cacheOptions={false}
        // isMulti={false}
        // menuIsOpen={true}
        isSearchable={true}
        // blurInputOnSelect={true}
        className={classNames(styles.tagSel)}
        classNamePrefix={'tagSelectPref'}
        // noOptionsMessage={(val) => {
        //   if (!val.inputValue) return <span>{_t(`please enter a value to search`)}</span>;
        //   return (
        //     <span>
        //       {_t(`no records found`)}
        //       Keine Einträge für <b>{val.inputValue}</b> gefunden
        //     </span>
        //   );
        // }}
        placeholder={props.placeholder}
        loadOptions={loadOpts}
        // options={props.selectedValues}
        // value={props.selectedValues}
        onChange={(newValues) => {
          const unwrapped = newValues as any as InnerSelectType<TagType>;
          if (!unwrapped?.value) return;
          props.onSelected(unwrapped.value);
        }}
        // menuPosition={'absolute'}
        // menuIsOpen={true}
        // defaultOptions={[{ id: 'fooo' }, { id: 'fooo0' }, { id: 'fooo1' }, { id: 'fooo2' }, { id: 'fooo3' }] as any}
        // getOptionValue={(o) => o.id}
        // defaultOptions={true}

        components={{
          Option: tagOptComponent,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          Control: controlComponent,
          Menu: menuComponent,
        }}
      />
    </div>
  );
}

// fetchOptions: (input: string) => Promise<TagType[]>;
// createOption: (val: TagType) => ReactNode;
export function useTagSelector<TagType>(props: {
  fetchOptions: (input: string) => Promise<TagType[]>;
  // used to filter duplicates
  filterDuplicates?: boolean;
  provideIdentifier?: (item: TagType) => string;
  createOption: (val: TagType) => ReactNode;
  optionParentClass?: (val: TagType, focused: boolean) => string | undefined;
  renderTag?: (val: TagType, onRm: () => void) => ReactNode;
  options?: TagType[];
}) {
  const [selectedItems, setSelectedItems] = useState<TagType[]>(props.options || []);
  const { fetchOptions, createOption, provideIdentifier, filterDuplicates, optionParentClass } = props;
  const onSelected = useCallback(
    (value: TagType) => {
      setSelectedItems((current) => {
        let result = [...current, value];
        if (provideIdentifier) {
          result = _.uniqBy(result, (item) => provideIdentifier(item));
        }
        return result;
      });
    },
    [provideIdentifier]
  );

  const fetchOptionsWrapped = useCallback(
    async (str: string) => {
      let result = await fetchOptions(str);
      if (provideIdentifier && filterDuplicates) {
        const selectedIds = selectedItems.map((item) => provideIdentifier(item));
        result = result.filter((item) => !selectedIds.includes(provideIdentifier(item)));
      }
      // let existing = selectedItems.
      return result;
    },
    [fetchOptions, provideIdentifier, selectedItems]
  );

  const tagSelectorProps = useMemo(() => {
    return { fetchOptions: fetchOptionsWrapped, createOption, onSelected, optionParentClass };
  }, [fetchOptionsWrapped, createOption, onSelected, optionParentClass]);

  const rmItem = useCallback((item: TagType) => {
    setSelectedItems((current) => current.filter((sel) => sel !== item));
  }, []);

  const { renderTag } = props;
  const renderItem = useCallback(
    (val: TagType) => {
      if (renderTag) return renderTag(val, () => rmItem(val));
      else return <SelectedTag label={'not implemented'} onRemove={() => rmItem(val)} />;
    },
    [rmItem, renderTag]
  );

  const selectedTagAreaProps = useMemo(() => {
    return { renderItem: renderItem, items: selectedItems };
  }, [selectedItems, renderItem]);

  return { tagSelectorProps, selectedItems, selectedTagAreaProps, setSelectedItems };
}

export function DefaultTagSelectorOption(props: { className?: string; label?: ReactNode; subLabel?: ReactNode }) {
  return (
    <div className={classNames(styles.defaultOpt, props.className)}>
      {props.label && <b>{props.label}</b>}
      {props.subLabel && <i>{props.subLabel}</i>}
    </div>
  );
}

export interface SelectedTagProps {
  className?: string;
  ico?: ReactNode;
  label?: ReactNode;
  onRemove?: () => void;
}
export function SelectedTag(props: SelectedTagProps) {
  return (
    <div className={classNames(props.className, styles.selectedTag)}>
      {props.ico && <div className={classNames(styles.ico)}>{props.ico}</div>}
      {props.label && <div className={classNames(styles.label)}>{props.label}</div>}
      {props.onRemove && (
        <div onClick={() => props.onRemove?.()} className={classNames(styles.close)}>
          <CloseIco />
        </div>
      )}
    </div>
  );
}

export interface SelectedTagAreaProps<TagType> {
  className?: string;
  items: TagType[];
  renderItem: (item: TagType) => ReactNode;
  totalLabel?: (items: TagType[]) => ReactNode;
  // ico?: ReactNode;
  // label?: ReactNode;
  // onRemove?: (item: TagType) => void;
}
export function SelectedTagArea<TagType>(props: SelectedTagAreaProps<TagType>) {
  if (props.items.length <= 0 && !props.totalLabel) return <></>;
  return (
    <section className={classNames(props.className, styles.selectedTagArea)}>
      <div className={classNames(styles.items)}>
        {props.items.map((item, i) => {
          return <Fragment key={i}>{props.renderItem(item)}</Fragment>;
        })}
      </div>

      {props.totalLabel && <div className={classNames(styles.total)}>{props.totalLabel(props.items)}</div>}
    </section>
  );
}
