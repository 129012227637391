import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as ShowMoreIco } from '../../../../assets/show-more.svg';
import { ButtonAccent, ButtonPrimary } from '../../../../components/button';
import DataTable, { DataTableRow } from '../../../../components/data-table/data-table';
import { FormCard, FormCardBody } from '../../../../components/form-card';
import Page, { ListingCardPlaceholder } from '../../../../components/page';
import Paging from '../../../../components/paging';
import Pill from '../../../../components/pill';
import SearchInput from '../../../../components/search-input';
import SingleSelect from '../../../../components/select/single-select';
import colorStyles from '../../../../components/style-utils';
import { Tag } from '../../../../components/tag';
import { useApi } from '../../../../hooks/useApi';
import { createQueryString, usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { pagingValidator, validateString } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import AdminTabPanel from '../../shared/admin-tab-panel';
import UserCreatePopup from '../user-create-popup/user-create-popup';
import UserDeletePopup from '../user-delete-popup/user-delete-popup';
import styles from './user-list-page.module.scss';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../../roles/role-create-popup/permissions-groups';
import { RoleType, UserListRoleDto } from '../../../../services/api-client/csp-api';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';

const SHOW_CLIENTS_LIMIT = 3;
const SHOW_ROLES_LIMIT = 3;

export default function UserListPage() {
  const { _t } = useBetterTranslate('user-list-popup');

  const SORT_ALLOWED_VALUES = ['firstName', 'mail'];
  const sortingValidator = {
    sortBy: validateString(SORT_ALLOWED_VALUES),
  };

  type FilterParameters = {
    affiliateCode?: string;
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
    sortBy?: string;
    sortDesc?: string;
    client?: string;
    root?: string;
    roles?: string[];
    clients?: string[];
  };

  const [expandedClients, setExpandedClients] = useState<String[]>([]);
  const [expandedRoles, setExpandedRoles] = useState<String[]>([]);

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator, ...sortingValidator });
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [usersRes, usersFetching, usersErr, refreshResult] = useApi(
    {
      call: async (
        affiliateCode?: string,
        skip?: number | null,
        limit?: number | null,
        query?: string | null,
        client?: string,
        root?: string,
        roleIds?: string[],
        clientCodes?: string[],
        sortBy?: any,
        sortDesc?: string
      ) => {
        if (!affiliateCode) return;
        const res = await api.users.list({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          search: query || undefined,
          roleIds: roleIds,
          clientCodes: clientCodes,
          client: client === '1',
          root: root === '1',
          sortBy,
          sortDesc: sortDesc === '1',
          affiliateCode,
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;
        setExpandedClients([]);
        setExpandedRoles([]);
        return data;
      },
    },
    filterParams.affiliateCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.client,
    filterParams.root,
    filterParams.roles,
    filterParams.clients,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const getClientUserPageLink = (clientCode: string, userMail: string) => {
    const baseUrl = `organisation/users/${clientCode}`;
    const query = createQueryString({ search: userMail });
    return `/${baseUrl}?${query}`;
  };

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [editedMail, setEditedMail] = useState<string>();
  const onAddClick = () => {
    setEditedMail(undefined);
    setShowAddPopup(true);
  };
  const onEditClick = (mail: string) => {
    setEditedMail(mail);
    setShowAddPopup(true);
  };
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [mailToDelete, setMailToDelete] = useState('');
  const onDeleteClick = (mail: string) => {
    setMailToDelete(mail);
    setShowDeletePopup(true);
  };

  const getPermissionsOfRole = async (roleId: string) => {
    const res = await api.users.getPermissionsOfRole(filterParams.affiliateCode || '', roleId);
    return res.data.permissions;
  };
  const [currentPermissionGroups, setCurrentPermissionGroups] = useState<PermissionsGroupsOfRole>();
  const onRolesPermissionsClick = async (role: UserListRoleDto) => {
    const permissions = await getPermissionsOfRole(role.id || '');
    const groups = getPermissionGroupStructure(permissions);
    setCurrentPermissionGroups({ roleId: role.id || '', roleName: role.name || '', roleType: role.type as RoleType, permissionsGroups: groups });
  };

  return (
    <Page
      breadCrumb={[{ title: _t('Administration'), href: '/administration/users', active: true }]}
      fetching={usersFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={usersErr}
      outOfPlaceHolder={
        <>
          {usersRes && (
            <AdminTabPanel
              selectedTab='users'
              affiliate={usersRes.affiliateContext}
              can={{ viewClientList: usersRes.can.viewClientList, viewRoleList: usersRes.can.viewRoleList, viewUserList: true }}
            />
          )}

          <FormCard tabletSize='full'>
            <FormCardBody className={classNames(styles.filters)}>
              <div className={styles.filterArea}>
                <SearchInput placeholder={_t('Benutzer suchen')} maxLength={120} onChange={setSearchTerm} value={searchTerm} dataCy='srcUsers' />
              </div>

              <SingleSelect<{ name: string; id: string }>
                className={styles.roleSelect}
                placeholder={_t('Rollen')}
                fetchOptions={async (txt) => {
                  if (!txt) return usersRes?.roleOptions || [];
                  const results = (usersRes?.roleOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                  const mappedRoles = results.map((r) => ({ id: r.id!, name: r.name }));
                  return mappedRoles;
                }}
                options={usersRes?.roleOptions || []}
                createOption={(opt) => <span>{opt.name}</span>}
                selectedValue={null}
                renderDisplayValue={(val) => val.name}
                onChanged={(selected) => {
                  if (!selected) return;
                  let updated = [selected.id, ...(filterParams.roles || [])];
                  updated = _.uniq(updated);
                  setFilterParams({ roles: updated });
                }}
                isClearable={true}
                dataCy='lstRoles'
              />

              <SingleSelect<{ id: string; title: string }>
                className={styles.roleSelect}
                placeholder={_t('Kunde')}
                fetchOptions={async (txt) => {
                  if (!txt) return usersRes?.clientOptions || [];
                  const results = (usersRes?.clientOptions || []).filter((item) => item.title.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                  const mappedClients = results.map((r) => ({ id: r.id!, title: r.title }));
                  return mappedClients;
                }}
                options={usersRes?.clientOptions || []}
                createOption={(opt) => <span>{opt.title}</span>}
                selectedValue={null}
                renderDisplayValue={(val) => val.title}
                onChanged={(selected) => {
                  if (!selected) return;
                  let updated = [selected.id, ...(filterParams.clients || [])];
                  updated = _.uniq(updated);
                  setFilterParams({ clients: updated });
                }}
                isClearable={true}
                dataCy='lstClients'
              />

              <div className={styles.roleKinds}>
                <Pill
                  selectedClass={colorStyles.components.roleKind.client.base}
                  unselectedClass={colorStyles.components.roleKind.client.light}
                  selected={filterParams.client === '1'}
                  onClick={() => (filterParams.client ? setFilterParams({ client: undefined }) : setFilterParams({ client: '1' }))}
                  dataCy='btnClient'
                >
                  Client
                </Pill>

                <Pill
                  selectedClass={colorStyles.components.roleKind.root.base}
                  unselectedClass={colorStyles.components.roleKind.root.light}
                  selected={filterParams.root === '1'}
                  onClick={() => (filterParams.root ? setFilterParams({ root: undefined }) : setFilterParams({ root: '1' }))}
                  dataCy='btnRoot'
                >
                  Root
                </Pill>
              </div>

              {usersRes?.can.manageUser && (
                <ButtonPrimary ralign={true} onClick={onAddClick} dataCy='btnCreateUser'>
                  {_t('Benutzer erstellen')}
                </ButtonPrimary>
              )}
            </FormCardBody>
          </FormCard>
          <FormCard tabletSize='full'>
            {usersRes && ((filterParams.roles && filterParams.roles.length > 0) || (filterParams.clients && filterParams.clients.length > 0)) && (
              <div className={styles.appliedFilterArea}>
                {filterParams.roles &&
                  filterParams.roles.length > 0 &&
                  filterParams.roles.map((rId) => {
                    const model = usersRes.roleOptions.find((r) => r.id === rId);
                    if (!model) return <></>;
                    return (
                      <Pill
                        hasClose={true}
                        className={styles.filteredRole}
                        onClick={() => {
                          const excluded = (filterParams.roles || []).filter((r) => r !== model.id);
                          setFilterParams({ roles: [...excluded] });
                        }}
                      >
                        {model.name}
                      </Pill>
                    );
                  })}
                {filterParams.clients &&
                  filterParams.clients.length > 0 &&
                  filterParams.clients.map((rId) => {
                    const model = usersRes.clientOptions.find((c) => c.id === rId);
                    if (!model) return <></>;
                    return (
                      <Pill
                        hasClose={true}
                        className={colorStyles.components.roleKind.client.base}
                        onClick={() => {
                          const excluded = (filterParams.clients || []).filter((c) => c !== model.id);
                          setFilterParams({ clients: [...excluded] });
                        }}
                      >
                        {model.title}
                      </Pill>
                    );
                  })}
              </div>
            )}
          </FormCard>
        </>
      }
    >
      <FormCard tabletSize='full' className=''>
        <FormCardBody>
          <div className={styles.tableArea}>
            <DataTable
              sticky={true}
              sorting={{
                handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
                col: filterParams.sortBy,
                desc: filterParams.sortDesc === '1',
              }}
              records={usersRes?.entries || []}
              dataCy='tblUsers'
              renderer={{
                row: (record, cols) => (
                  <DataTableRow classNames={styles.row} key={record.mail}>
                    {cols}
                  </DataTableRow>
                ),
                cols: [
                  {
                    headerCol: () => <div>{_t('Name')}</div>,
                    name: 'firstName',
                    sortable: true,
                    col: (record) => <span>{record.fullName}</span>,
                    width: '20%',
                    dataCy: 'hdrNameSort',
                  },
                  {
                    headerCol: () => <div>{_t('E-Mail')}</div>,
                    name: 'mail',
                    sortable: true,
                    col: (record) => <span>{record.mail}</span>,
                    width: '20%',
                    dataCy: 'hdrEmailSort',
                  },
                  {
                    headerCol: () => <div>{_t('Rollen')}</div>,
                    name: 'role',
                    sortable: false,
                    col: (record) => {
                      const isExpanded = !!expandedRoles.find((item) => item === record.mail);
                      const displayRoles = isExpanded ? record.roles : record.roles?.slice(0, SHOW_ROLES_LIMIT);
                      const label = isExpanded
                        ? _t('Weniger anzeigen')
                        : _t(`Mehr anzeigen ({{hiddenCount}} weitere)`, { hiddenCount: (record?.roles?.length || 0) - SHOW_ROLES_LIMIT });
                      const icon = isExpanded ? <ShowMoreIco className={styles.showLess} /> : <ShowMoreIco />;
                      const onClickExpand = () => setExpandedRoles(isExpanded ? expandedRoles.filter((item) => item !== record.mail) : [...expandedRoles, record.mail]);

                      return (
                        <div>
                          {displayRoles.map((r) => (
                            <div className={styles.role}>
                              <div className={styles.roleName} key={r.name}>
                                {r.name}
                              </div>
                              <div className={styles.infoIco}>
                                <InfoIco onClick={async () => await onRolesPermissionsClick(r)}></InfoIco>
                              </div>
                            </div>
                          ))}
                          {(record?.roles?.length || 0) > SHOW_ROLES_LIMIT && (
                            <div className={styles.expandItem} onClick={onClickExpand}>
                              {' '}
                              {icon} {label}
                            </div>
                          )}
                        </div>
                      );
                    },
                    width: '15%',
                    dataCy: 'hdrRolesSort',
                  },
                  {
                    headerCol: () => <div>{_t('Zugang')}</div>,
                    name: 'roleKind',
                    sortable: false,
                    col: (record) => {
                      const displayItems = record.roleAccessTypes?.slice(0, 2) || [];
                      const tags = displayItems.map((item, idx) => {
                        const cls = item === 'root' ? colorStyles.components.roleKind.root.base : colorStyles.components.roleKind.client.base;
                        return (
                          <Tag key={idx} colorStyle={'custom'} className={cls}>
                            {item}
                          </Tag>
                        );
                      });
                      return <div className={classNames(styles.roleKinds)}>{tags}</div>;
                    },
                    width: '8%',
                    dataCy: 'hdrAccessSort',
                  },
                  {
                    headerCol: () => <div>{_t('Kunde')}</div>,
                    name: 'clientTitle',
                    sortable: false,
                    col: (record) => {
                      const isExpanded = !!expandedClients.find((item) => item === record.mail);
                      const displayItems = isExpanded ? record.clients : record.clients?.slice(0, SHOW_CLIENTS_LIMIT);
                      const label = isExpanded
                        ? _t('Weniger anzeigen')
                        : _t(`Mehr anzeigen ({{hiddenCount}} weitere)`, { hiddenCount: (record?.clients?.length || 0) - SHOW_CLIENTS_LIMIT });
                      const icon = isExpanded ? <ShowMoreIco className={styles.showLess} /> : <ShowMoreIco />;
                      const onClickExpand = () => setExpandedClients(isExpanded ? expandedClients.filter((item) => item !== record.mail) : [...expandedClients, record.mail]);

                      if (displayItems) {
                        return (
                          <div>
                            {displayItems.map((client) => {
                              if (usersRes?.can.manageClientUsers && client && client.title) {
                                return (
                                  <div className={classNames(styles.clientItem)}>
                                    <NavLink to={getClientUserPageLink(client.id, record.mail)}>
                                      <span className={classNames(styles.client)}>{client?.title}</span>
                                    </NavLink>
                                  </div>
                                );
                              } else if (!usersRes?.can.manageClientUsers) {
                                return <div>{client.title}</div>;
                              } else {
                                return null;
                              }
                            })}
                            {(record?.clients?.length || 0) > SHOW_CLIENTS_LIMIT && (
                              <div className={styles.expandItem} onClick={onClickExpand}>
                                {' '}
                                {icon} {label}
                              </div>
                            )}
                          </div>
                        );
                      }
                    },
                    width: '12%',
                  },
                  {
                    headerCol: () => <div></div>,
                    name: 'actions',
                    sortable: false,
                    ralign: true,
                    col: (record) => (
                      <div className={styles.actions}>
                        {usersRes?.can.manageUser && (
                          <>
                            <ButtonAccent onClick={() => onDeleteClick(record.mail)} className={styles.editBtn} dataCy='btnDelete'>
                              {_t('Löschen')}
                            </ButtonAccent>
                            <ButtonPrimary onClick={() => onEditClick(record.mail)} className={styles.editBtn} dataCy='btnEdit'>
                              {_t('Editieren')}
                            </ButtonPrimary>
                          </>
                        )}
                      </div>
                    ),
                    width: '30%',
                  },
                ],
              }}
            />
          </div>
        </FormCardBody>
      </FormCard>

      {usersRes && (
        <Paging
          skip={usersRes.skip}
          limit={usersRes.limit}
          total={usersRes.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )}

      <UserCreatePopup
        affiliateCode={filterParams.affiliateCode || ''}
        defaultLang={usersRes?.defaultLanguage || 'en'}
        open={showAddPopup}
        close={() => setShowAddPopup(false)}
        onSubmit={() => refreshResult()}
        mail={editedMail}
        dataCy={{ root: editedMail ? 'popEditUser' : 'popCreateUser', buttonSave: 'btnSave' }}
        getPermissionsOfRole={getPermissionsOfRole}
      />

      <UserDeletePopup
        affiliateCode={filterParams.affiliateCode || ''}
        open={showDeletePopup}
        close={() => setShowDeletePopup(false)}
        onSubmit={() => refreshResult()}
        mail={mailToDelete}
      />
      <RolePermissionsPopup
        open={!!currentPermissionGroups?.roleId}
        permissionsGroups={currentPermissionGroups?.permissionsGroups || []}
        onClose={() => {
          setCurrentPermissionGroups(undefined);
        }}
        roleType={currentPermissionGroups?.roleType as RoleType}
        roleName={currentPermissionGroups?.roleName}
      />
    </Page>
  );
}
