import classNames from 'classnames';
import { padStart } from 'lodash';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CreateTicketIcon } from '../../assets/file-plus.svg';
import { BreadCrumb, BreadCrumbEntry } from '../../components-v2/breadcrumb';
import Button from '../../components/button';
import { DateChooser } from '../../components/date-range-picker';
import EditPoup, { EditPopupRow, useEditPopup } from '../../components/edit-popup';
import Input, { FormContainer } from '../../components/input';
import RadioButton from '../../components/input-radio-button';
import { FormGroupTitle, FormRow, FormSeparator, LoadingSpinner, PageBody, PageDesc, PageFooter, PageLayout, PageTitle, Spacer, formGrid } from '../../components/page-layout';
import SingleSelect from '../../components/select/single-select';
import { DefaultTagSelectorOption, SelectedTag, SelectedTagArea, TagSelectorV2, useTagSelector } from '../../components/tag-selector-v2';
import { useLocationState } from '../../hooks/useBetterNavigate';
import useFormState, { formValidator } from '../../hooks/useFormState';
import { usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { TicketCategories } from '../../services/api-client/csp-api';
import { getDomainStatusClassActive, getDomainStatusTitle, stationDomainStatusClassActive, stationDomainStatusTitle } from '../../utils/domain-status';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './create-ticket.page.module.scss';

export interface CreateTicketPageProps {
  className?: string;
}

function generateTimeValues() {
  const entries: string[] = [];
  for (let h = 0; h <= 23; h++) {
    for (let m = 0; m < 60; m += 15) {
      const val = `${padStart(h.toFixed(0), 2, '0')}:${padStart(m.toFixed(0), 2, '0')}`;
      entries.push(val);
    }
  }
  return entries;
}

const possibleTimeEntries = generateTimeValues().map((e) => ({ id: e }));

export interface TicketCallerState {
  callerPath?: string;
  callerTitle?: string;
}

export default function CreateTicketPage(props: CreateTicketPageProps) {
  const { _t } = useBetterTranslate(`create-ticket-page`);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [createdTicketId, setCreatedTicketId] = useState('');
  const { pathname } = useLocation();

  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  const { clientCode } = useParams();

  const [showSureCancelPopup, sureCancelPopupProps] = useEditPopup();

  const form = useFormState();

  const createTicketCaller = useLocationState<TicketCallerState>('createTicketCaller', { default: undefined });

  const {
    tagSelectorProps: cardTagSelectorProps,
    selectedTagAreaProps: cardsTagAreaProps,
    selectedItems: cardIds,
    // setSelectedItems: setCardIds,
  } = useTagSelector({
    fetchOptions: async (str) => (await api.support.searchCards({ clientCode: clientCode!, q: str })).data,
    provideIdentifier: (item) => item.id,
    optionParentClass: (item, focused) => {
      if (focused) return '';
      if (cardIds.find((s) => s.id === item.id)) return styles.alreadySelectedItem;
    },
    createOption: (v) => (
      <DefaultTagSelectorOption
        label={
          <div className={styles.cardOptionItem}>
            <div>
              <b>{v.extId}</b>
              <span>{_t('Card number')}</span>
            </div>
            <div>
              <b>{v.title}</b>
              <span>{_t('Card label')}</span>
            </div>
          </div>
        }
      />
    ),
    renderTag: (v, rm) => <SelectedTag label={v.title} onRemove={rm} />,
  });

  const {
    tagSelectorProps: stationTagSelectorProps,
    selectedTagAreaProps: stationTagAreaProps,
    selectedItems: stationIds,
    // setSelectedItems: setStationIds,
  } = useTagSelector({
    fetchOptions: async (str) => (await api.support.searchChargePoints({ clientCode: clientCode!, q: str })).data,
    provideIdentifier: (item) => item.evseId,
    optionParentClass: (item, focused) => {
      if (focused) return '';
      if (stationIds.find((s) => s.evseId === item.evseId)) return styles.alreadySelectedItem;
    },
    createOption: (v) => (
      <DefaultTagSelectorOption
        className={classNames(styles.stationSelectorOptionContainer)}
        label={
          <>
            <div className={classNames(styles.stationSelectorOption)}>
              <div className={styles.nameCol}>
                <b>{v.evseId}</b>
                <span>{v.kind?.toString() === 'station' ? _t('Charging Station') : _t('Charging Point')}</span>
              </div>
              <div className={styles.statusCol}>
                <div
                  className={classNames(
                    styles.statusIco,
                    v.connectorDomainStatus ? getDomainStatusClassActive(v.connectorDomainStatus) : stationDomainStatusClassActive(v.chargeBoxDomainStatus || 'notInOperation')
                  )}
                ></div>
                <span>{v.connectorDomainStatus ? getDomainStatusTitle(v.connectorDomainStatus) : stationDomainStatusTitle(v.chargeBoxDomainStatus || 'notInOperation')}</span>
                {/* <span className={classNames(colorStyles.components.connectorsStatus.colors.__define, st)} style={{ backgroundColor: getDomainStatusColorStyle(v.connectorDomainStatus || v.chargeBoxDomainStatus || 'unknown') }}></span> */}
              </div>
            </div>
          </>
        }
      />
    ),
    renderTag: (v, rm) => (
      <SelectedTag
        ico={<div className={classNames(styles.statusIco, v.chargeBoxDomainStatus ? styles[v.chargeBoxDomainStatus] : styles[v.chargeBoxDomainStatus || ''])}></div>}
        label={
          <div className={styles.stationTag}>
            <b>{v.evseId}</b>
            <span>{getDomainStatusTitle(v.connectorDomainStatus || v.chargeBoxDomainStatus || 'unknown')}</span>
          </div>
        }
        onRemove={rm}
      />
    ),
  });

  const defaultCatValue = useMemo(() => ({ id: TicketCategories.Authentication, lbl: _t(`Authentication`) }), [_t]);
  const [categoryField, setCategoryField] = form.useFieldState({
    label: _t(`Category of issue`),
    required: true,
    initialValue: defaultCatValue,
  });
  const [dateField, setDateField] = form.useFieldState({
    label: _t(`Date of authentication attempt`),
    initialValue: new Date().toISOString(),
    validate: async (val) => {
      if (categoryField.value.id !== TicketCategories.Authentication) return;
      let err: string | undefined = '';
      if ((err = formValidator.notEmpty(val, _t('This field is required')))) return err;
    },
  });
  const [timeField, setTimeField] = form.useFieldState({
    label: _t(`Time of authentication attempt`),
    initialValue: possibleTimeEntries[0],
  });

  // temporary, till we implement here the tag selector
  // const [badgeListTxtField, setBadgeListTxtField] = form.useInputState({
  //   initialValue: '',
  //   label: 'Number or label of the used cards',
  //   maxLength: 500,
  // });
  // const [badgeSearchField, setBadgeSearchField] = form.useInputState({
  //   initialValue: '',
  //   label: 'Number or label of the used cards',
  //   maxLength: 100
  // });

  const [stationDescField, setStationDescField] = form.useInputState({
    initialValue: '',
    label: _t(`Describe how the station behaved`),
    maxLength: 500,
  });
  // // temporary, till we implement here the tag selector
  // const [stationListTxtField, setStationListTxtField] = form.useInputState({
  //   initialValue: '',
  //   label: 'ID of the charging stations or points',
  //   maxLength: 500,
  // });
  // const [stationSearchField, setStationSearchField] = form.useInputState({
  //   initialValue: '',
  //   label: 'ID of the charging stations or points',
  //   maxLength: 100
  // });

  const [issueDescField, setIssueDescField] = form.useInputState({
    initialValue: '',
    label: _t(`Describe the issue`),
    required: true,
    maxLength: 500,
    validate: async (val) => {
      let err: string | undefined = '';
      if ((err = formValidator.notEmpty(val, _t('This field is required')))) return err;
    },
  });

  const [isInvoiceReceiverField, setIsInvoiceReceiverField] = form.useFieldState({
    initialValue: true,
    label: _t(`Would you be the recipient for offers regarding fault clearance or repair?`),
  });

  const [mailInvoiceReceiverField] = form.useInputState({
    initialValue: '',
    maxLength: 100,
    label: _t(`e-mail address of recipient`),
    required: true,
    validate: async (val) => {
      if (isInvoiceReceiverField.value) return;
      let err: string | undefined = '';
      if ((err = formValidator.notEmpty(val, _t('This field is required')))) return err;
      if ((err = formValidator.email(val, _t('Invalid email adress')))) return err;
    },
  });
  const [nameInvoiceReceiverField] = form.useInputState({
    initialValue: '',
    maxLength: 100,
    label: _t(`Name of recipient`),
    validate: async (val) => {
      // not mandatory
      // https://confluence.totalenergies.com/display/CSP/Fields+to+fill+in+by+the+user
      return undefined;
      // if (isInvoiceReceiverField.value) return;
      // let err: string | undefined = '';
      // if ((err = formValidator.notEmpty(val, _t('This field is required')))) return err;
    },
  });
  const [phoneInvoiceReceiverField] = form.useInputState({
    initialValue: '',
    maxLength: 100,
    label: _t(`Phone number of recipient`),
    validate: async (val) => {
      // not mandatory
      // https://confluence.totalenergies.com/display/CSP/Fields+to+fill+in+by+the+user
      return undefined;
      // if (isInvoiceReceiverField.value) return;
      // let err: string | undefined = '';
      // if ((err = formValidator.notEmpty(val, _t('This field is required')))) return err;
    },
  });

  const [stationIdsField] = form.useFieldState({
    initialValue: [],
    label: _t(`EVSE-ID of the charging stations or points`),
    required: true,
    validate: async () => {
      if (stationIds.length <= 0) return _t('This field is required');
    },
  });

  const [cardIdsField] = form.useFieldState({
    initialValue: [],
    label: _t(`Number or label of the used cards`),
    required: true,
    validate: async () => {
      if (categoryField.value.id === TicketCategories.Authentication) {
        if (cardIds.length <= 0) return _t('This field is required');
      }
    },
  });

  const onWantCreateTicket = async () => {
    const validationResult = await form.submit();
    if (!validationResult) {
      console.error(`invalid form`);
      return;
    }

    setShowConfirmPopup(true);
  };

  const onAcceptedCreateTicket = async () => {
    setShowConfirmPopup(false);
    setShowSpinner(true);
    const resp = await api.support.createServiceTicket({
      category: categoryField.value.id,
      categoryText: categoryField.value.lbl,
      clientCode: clientCode!,
      isInvoiceReceiver: isInvoiceReceiverField.value,
      authorizeDate: dateField.value,
      authorizeTime: timeField.value.id,
      cardExtIds: cardIds.map((c) => c.extId),
      cardNumbers: cardIds.map((c) => c.id),
      invoiceReceiverMail: mailInvoiceReceiverField.value,
      invoiceReceiverName: nameInvoiceReceiverField.value,
      invoiceReceiverPhone: phoneInvoiceReceiverField.value,
      issueDesc: issueDescField.value,
      stationBehaviorDesc: stationDescField.value,
      stationOrChargePoints: [...stationIds],
    });
    setShowSpinner(false);
    form.reset();
    setCreatedTicketId(resp.data.issueId);
  };

  const ticketCategoryOptions = useMemo(() => {
    return [
      { id: TicketCategories.Authentication, lbl: _t(`Authentication`) },
      { id: TicketCategories.CableIsLockedByStation, lbl: _t(`Cable is locked by station`) },
      { id: TicketCategories.ChargingIsNotPossibleAndStationHasStatusOffline, lbl: _t(`Charging is not possible and station has status "offline"`) },
      { id: TicketCategories.ChargingIsNotPossibleAndStationHasStatusOnline, lbl: _t(`Charging is not possible and station has status "online"`) },
      { id: TicketCategories.ChargingIsPossibleAndStationHasStatusOffline, lbl: _t(`Charging is possible and station has status "offline"`) },
      { id: TicketCategories.ChargingPowerIsReduced, lbl: _t(`Charging power is reduced`) },
      { id: TicketCategories.HardwareIsDamagedEGDisplayIsBroken, lbl: _t(`Hardware is damaged (e.g. display is broken)`) },
      { id: TicketCategories.OtherIssue, lbl: _t(`Other issue`) },
    ];
  }, [_t]);

  const breadCrumbItems: BreadCrumbEntry[] = [];
  if (createTicketCaller?.callerPath && createTicketCaller.callerTitle) {
    breadCrumbItems.push({
      onClick: () => {
        new Promise(async () => {
          const accept = await showSureCancelPopup();
          if (!accept) return;
          else navigate(-1);
        });

        return true; // prevent streight nav
      },
      href: createTicketCaller.callerPath,
      title: createTicketCaller.callerTitle,
      active: false,
    });
  }
  breadCrumbItems.push({ href: pathname, title: _t('Create Service-Ticket'), active: true });

  return (
    <PageLayout className={classNames(styles.root, props.className)}>
      <BreadCrumb
        items={breadCrumbItems}
        onBack={async () => {
          const accept = await showSureCancelPopup();
          if (!accept) return;
          else navigate(-1);
        }}
      />

      <PageTitle>{_t(`Create Service-Ticket`)}</PageTitle>
      <PageDesc>
        {_t(`Please enter all necessary information to create the service ticket.`)}
        <br />
        {_t(`Mandatory fields are indicated with an asterisk *`)}
      </PageDesc>
      {form.hasValidationErrors && <PageDesc kind={'alert'}>{_t(`To create a service ticket, plesase enter all missing values`)}</PageDesc>}

      <EditPoup
        open={showConfirmPopup}
        saveText={_t(`Create ticket`)}
        cancelText={_t(`Go back`)}
        onSave={() => onAcceptedCreateTicket()}
        onClose={() => setShowConfirmPopup(false)}
        headerClassName={styles.confirmHeader}
      >
        <div className={classNames(styles.confirmBody)}>
          <h2>{_t(`Are you sure that all input is correct?`)}</h2>
          <span>{_t(`If you are not sure you can go back and check again. For efficient support it is necessary that all input is accurate.`)}</span>
        </div>
      </EditPoup>

      <EditPoup open={!!createdTicketId} cancelText={_t(`Close`)} onClose={() => navigate(-1)} headerClassName={styles.confirmHeader}>
        <div className={classNames(styles.confirmBody)}>
          <h2>{_t(`Ticket created?`)}</h2>
          <span>
            {_t(`Your ticket was created.`)}
            <br />
            {_t(`The Ticket Number is:`)}
          </span>
          <b>{createdTicketId}</b>
        </div>
      </EditPoup>

      {showSpinner && (
        <LoadingSpinner>
          <span>{_t(`Creating ticket`)}</span>
        </LoadingSpinner>
      )}
      {!showSpinner && (
        <>
          <PageBody>
            <FormGroupTitle>{_t(`Ticketdetails`)}</FormGroupTitle>
            <FormSeparator />
            <FormRow>
              <FormContainer label={categoryField.label}>
                <SingleSelect
                  placeholder={``}
                  selectedValue={categoryField.value}
                  options={ticketCategoryOptions}
                  createOption={(opt) => <div>{opt.lbl}</div>}
                  renderDisplayValue={(val) => val.lbl}
                  fetchOptions={() => Promise.resolve(ticketCategoryOptions)}
                  onChanged={(v) => setCategoryField(v || defaultCatValue)}
                />
              </FormContainer>
              <Spacer w='2' />
            </FormRow>

            {categoryField.value.id === TicketCategories.Authentication && (
              <>
                <div>
                  <FormRow>
                    <FormContainer className={formGrid.w1} label={cardIdsField.label} validationErr={cardIdsField.validationErr}>
                      <TagSelectorV2 {...cardTagSelectorProps} />
                      {/* <textarea style={{ width: '100%' }} value={badgeListTxtField.value} onChange={(f) => setBadgeListTxtField(f.target.value)}></textarea> */}
                    </FormContainer>
                    <Spacer w={'2'} />
                  </FormRow>
                  <FormRow>
                    <div className={formGrid.w2}>
                      <SelectedTagArea
                        {...cardsTagAreaProps}
                        totalLabel={(items) => (
                          <>
                            {items.length} {_t('badges selected')}
                          </>
                        )}
                      />
                    </div>
                    {/* <textarea className={formGrid.w2} value={chargeBoxIds.map(cb => cb).join(', ')} /> */}
                    <Spacer w='1' />
                  </FormRow>
                </div>

                <FormRow>
                  {/* should be span, cause datepicker has issues w. label */}
                  <FormContainer label={dateField.label} renderMode='span'>
                    <DateChooser selected={dateField.value} onChange={setDateField} />
                  </FormContainer>
                  <FormContainer label={timeField.label}>
                    <SingleSelect
                      placeholder={``}
                      selectedValue={timeField.value}
                      options={possibleTimeEntries}
                      createOption={(opt) => <div>{opt.id}</div>}
                      renderDisplayValue={(val) => val.id}
                      fetchOptions={async (str) => {
                        let modifiedStr = str.replace('.', ':');
                        const result = possibleTimeEntries.filter((v) => v.id.includes(modifiedStr));
                        return result;
                      }}
                      onChanged={(v) => setTimeField(v || possibleTimeEntries[0]!)}
                    />
                  </FormContainer>
                  <Spacer w='1' />
                </FormRow>
                <FormSeparator />
              </>
            )}

            <div>
              <FormRow>
                <FormContainer className={formGrid.w1} label={stationIdsField.label} renderMode='span' validationErr={stationIdsField.validationErr}>
                  <TagSelectorV2 {...stationTagSelectorProps} />
                  {/* <textarea style={{ width: '100%' }} value={badgeListTxtField.value} onChange={(f) => setBadgeListTxtField(f.target.value)}></textarea> */}
                </FormContainer>
                <Spacer w={'2'} />
              </FormRow>
              <FormRow>
                <div className={formGrid.w2}>
                  <FormContainer renderMode='span' className={styles.tagArea}>
                    <SelectedTagArea
                      {...stationTagAreaProps}
                      totalLabel={(items) => (
                        <>
                          {items.length} {_t('stations selected')}
                        </>
                      )}
                    />
                  </FormContainer>
                </div>
                <Spacer w='1' />
              </FormRow>
            </div>

            {categoryField.value.id === TicketCategories.Authentication && (
              <>
                <FormRow>
                  <FormContainer className={formGrid.w2} label={stationDescField.label} validationErr={stationDescField.validationErr}>
                    <textarea style={{ width: '100%' }} value={stationDescField.value} onChange={(f) => setStationDescField(f.target.value)}></textarea>
                  </FormContainer>
                  <Spacer w='1' />
                </FormRow>
                <FormSeparator />
              </>
            )}

            <FormRow>
              <FormContainer className={formGrid.w2} label={issueDescField.label} validationErr={issueDescField.validationErr}>
                <textarea style={{ width: '100%' }} value={issueDescField.value} onChange={(f) => setIssueDescField(f.target.value)}></textarea>
              </FormContainer>
              <Spacer w='1' />
            </FormRow>

            <FormSeparator />

            <FormRow>
              <FormContainer label={isInvoiceReceiverField.label}>
                <div>
                  <RadioButton label={_t(`Yes`)} isSelected={isInvoiceReceiverField.value} onChange={() => setIsInvoiceReceiverField((v) => !v)} />
                  <RadioButton label={_t(`No`)} isSelected={!isInvoiceReceiverField.value} onChange={() => setIsInvoiceReceiverField((v) => !v)} />
                </div>
              </FormContainer>
            </FormRow>

            {!isInvoiceReceiverField.value && (
              <FormRow>
                <FormRow className={formGrid.w2}>
                  <Input v2={true} {...mailInvoiceReceiverField} />
                  <Input v2={true} {...nameInvoiceReceiverField} />
                  <Input v2={true} {...phoneInvoiceReceiverField} />
                </FormRow>
                <Spacer w='1' />
              </FormRow>
            )}
          </PageBody>
          <PageFooter>
            <Button onClick={onWantCreateTicket}>{_t(`Create service ticket`)}</Button>
          </PageFooter>
        </>
      )}

      <EditPoup
        {...sureCancelPopupProps}
        className={styles.sureCancelPopup}
        btnClasses={styles.sureCancelBtn}
        cancelText={_t('No, remain on support ticket creation')}
        saveText={_t('Yes, quit support ticket creation')}
        closeIcon={<></>}
      >
        <EditPopupRow>
          <h3>{_t('Are you sure you want to leave this page?')}</h3>
        </EditPopupRow>
        <EditPopupRow className={styles.cancelPopupWarn}>{_t('All data will be lost and cannot be retrieved')}</EditPopupRow>
      </EditPoup>
    </PageLayout>
  );
}

export function CreateServiceTicketAction(props: { className?: string; caller: TicketCallerState }) {
  const { _t } = useBetterTranslate(`create-ticket-action`);
  const [{ clientCode }] = usePageParams<{ clientCode?: string }>({}, {});
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(`/support/service-ticket/${clientCode}`, { state: { createTicketCaller: props.caller } });
      }}
      dataCy='createServiceTicket'
      className={classNames(styles.createTicketBtn)}
    >
      <CreateTicketIcon className={classNames(styles.ico)} />
      <span>{_t(`Create Service Ticket`)}</span>
    </Button>
  );
}
